import { Component, ElementRef, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CommonApiService } from 'src/app/services/common-api.service';
import { GrowthMonitorService } from 'src/app/services/growth-monitor.service';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-growth-monitoring-capture',
  templateUrl: './growth-monitoring-capture.component.html',
  styleUrls: ['./growth-monitoring-capture.component.scss']
})
export class GrowthMonitoringCaptureComponent implements OnInit {
 @ViewChild('video') videoElement: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  stream: MediaStream;
  capturedImg: File
  ImageName = "Captured-Image"
  openCameraforUpload: boolean = false
  IndexforImage = 0
  public flag: boolean = true;
  breadcrumb = [
    {
      title: 'Baby Growth Monitoring Image Capture',
      subtitle: 'Dashboard',
    },
  ];
  baby_details_id: any;
  getToken: any;
  getRole: any;
  getname: any;
  selectedFile: File | null = null;
  babyDetails: any = [];
  babyImgArray: any = []
  babyGender: any;
  years: any = '';
  month: any = '';
  days: any = '';
  babyAge: any;
  today = new Date();
  name: any;
  weightConverted = false;
  heightConverted = false;
  selectedDate: any = null; // Initialize with today's date;
  isedit: boolean = false;
  growthMonitoringCapture: FormGroup;
  growthData: any;
  babyImgJSon: any;
  ImageUrl: string = environment.imgUrl;
  capturedByCamera: any = []
  imagePreview: boolean = false
  imgSrcForPreview: any;
  babyImg:any = [];
  constructor(
        private fb: FormBuilder,
        private common_service: CommonApiService,
        private growth_service: GrowthMonitorService,
        private messageService: MessageService,
        private router: Router,
        private spinner: NgxSpinnerService
  ) { 
    this.growthMonitoringCapture = this.fb.group({
          baby_details_id: new FormControl(null, Validators.required),
          growth_id: new FormControl(null),
          height :new FormControl(null),
          weight: new FormControl(null),
          date_of_assessment:new FormControl(null),
           baby_images: this.fb.array([]),
        });
  }
  

  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }

  ngOnDestroy(): void {
    this.shutDownCamera();
    // Perform cleanup actions here, such as closing the camera
    // Close the camera code here...
  }

  ngOnInit(): void {
    this.spinner.show()
    this.common_service.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      this.setBasicDetails();

    }, (error: any) => {
      this.spinner.hide()

      // Handle any errors that occurred during the API call

    });
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')

    this.getHWI();
  }

  getHWI() {
    const payload = {
      baby_details_id: localStorage.getItem('babyId') // Correctly set the key-value pair
    };
  
    this.common_service.getHWI(payload).then((res: any) => {
      this.babyImg = res.data.map((item: any) => {
        // Ensure the image property is an array
        if (!Array.isArray(item.image)) {
          try {
            item.image = JSON.parse(item.image);
          } catch (e) {
            console.error('Error parsing image JSON:', e);
            item.image = [];
          }
        }
  
        // Log each image URL
        item.image.forEach((img: any) => {
          console.log('Image URL:', this.ImageUrl + img.name);
        });
  
        return item;
      });
  
      console.log("babyImg", this.babyImg);
  
      this.spinner.hide();
    }, (error: any) => {
      console.error('Error fetching HWI data:', error);
      this.spinner.hide();
    });
  }
  setBasicDetails() {
    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.isedit = true;
    }
    else if (localStorage.getItem('babyId')) {
      const getEdit = localStorage.getItem('babyId');
      this.getBabyDetails({ 'value': getEdit })
      this.growthMonitoringCapture.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );
      this.addVitaminObject('add', 0);
      this.addBabyImg('add', 0);
      this.isedit = false;
    }
  }
  openCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.stream = stream;
          this.videoElement.nativeElement.srcObject = stream;
          this.openCameraforUpload = true
        })
        .catch(error => {
        });
    }
  }
  shutDownCamera(){
    this.openCameraforUpload=false;
    const video = this.videoElement.nativeElement;
  if (video.srcObject) {
    const stream = video.srcObject as MediaStream;
    const tracks = stream.getTracks();
    tracks.forEach(track => track.stop()); // Stop tracks to close the camera
    video.srcObject = null; // Reset the srcObject to release the camera resource
    this.openCameraforUpload = false
  }
  }

  getImageArray() {
    return this.growthMonitoringCapture.get(
      'baby_images'
    ) as FormArray;
  }
    addBabyImg(string: any, index: any) {
      let imgArray = this.getImageArray();
      if (string == 'add') {
        let newbabyImg = new FormGroup({
  
        });
  
        // let formatedTime =  moment(newTime).format('HH:mm:ss');
        imgArray.insert(index + 1, newbabyImg);
      } else {
        imgArray.removeAt(index);
      }
    }
   getEditFormData() {
      this.spinner.show()
      const getEdit = localStorage.getItem('editedOrderId')
      this.common_service.getEditGrowthMonitoring(getEdit).then((res: any) => {
        this.spinner.hide()
        const detailsInGrowthMonitoring = res.data[0];
        const developmentMilestones = res.data[0].development_milestones;
        const imagedata = developmentMilestones[0].growth_images_json;
        
        console.log("RESSSSSSSSS",developmentMilestones)
        const milestoneData = JSON.parse(developmentMilestones[0].development_milestone);
        this.growthData = milestoneData
  
        this.babyGender = detailsInGrowthMonitoring.gender;
        this.selectedDate = detailsInGrowthMonitoring.date_of_birth;
        this.days = detailsInGrowthMonitoring.days;
        this.month = detailsInGrowthMonitoring.months;
        this.years = detailsInGrowthMonitoring.years;
        this.growthMonitoringCapture.controls.baby_details_id.setValue(detailsInGrowthMonitoring.baby_id)
        const babyImg = milestoneData.baby_images;
        this.babyImgJSon = developmentMilestones[0].growth_images_json;
        this.addBabyImg('add', 0);
        const physical_assessment_details_json = developmentMilestones[0].physical_assessment_details_json
  
  
        const VitaminsMedication =
          physical_assessment_details_json;
        var check =true
        for (let i = 0; i < VitaminsMedication.length; i++) {
          if((VitaminsMedication[i].height != null) || (VitaminsMedication[i].time!=null) || (VitaminsMedication[i].weight != null)){
            check = false
            let vitaminArray = this.gethealthFormArray();
            let VitaminsMedicationFromApi = VitaminsMedication[i] || {};
    
            let newVitaminArray = new FormGroup({
              height: new FormControl(VitaminsMedicationFromApi.height || null),
              time: new FormControl(
                VitaminsMedicationFromApi.time
                  ? new Date(VitaminsMedicationFromApi.time)
                  : null
              ),
              weight: new FormControl(VitaminsMedicationFromApi.weight || null),
            });
    
            vitaminArray.insert(i, newVitaminArray);
          }
         
        }
        
       
      }, (error: any) => {
        this.spinner.hide()
  
        // Handle any errors that occurred during the API call
  
      })
    }
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let firstLetter = data[0]?.gender.toLowerCase();
    let capitalize =
      firstLetter.slice(0, 1).toUpperCase() +
      firstLetter.slice(1, firstLetter.length);
    this.babyGender = capitalize;
    this.selectedDate = data[0].date_of_birth;
    // Manually parse the date string in DD/MM/YYYY format
    const dateParts = data[0].date_of_birth.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed in JavaScript
    const year = parseInt(dateParts[2], 10);

    const selectedDate = moment([year, month, day]);
    const today = moment();

    // Calculate the difference in years, months, and days
    const years = today.diff(selectedDate, 'years');
    selectedDate.add(years, 'years');

    const months = today.diff(selectedDate, 'months');
    selectedDate.add(months, 'months');

    const days = today.diff(selectedDate, 'days');

    this.years = years;
    this.month = months;
    this.days = days;
    // if ((this.years == 1 && this.month == 0 && this.days == 0) || (this.years == 0 && this.days <= 31 && this.month >= 4)) {
    //   this.flag = true;
    // } else {
    //   this.flag = false;
    //   this.messageService.add({
    //     severity: 'info',
    //     summary: 'Info',
    //     detail:
    //       'Your child does not meet the eligibility criteria for this plan.',
    //   });
    // }
  }
    growthMonitoringForm(formSubmitted: any) {
      
      this.growthMonitoringCapture.enable()
      window.scrollTo(0, 0);

  
      if (formSubmitted.valid) {
        let jsonData = JSON.stringify(this.growthMonitoringCapture.value);
        let dataObject = {
          growth_id: 2,
          baby_details_id: this.growthMonitoringCapture.value.baby_details_id,
          height:this.growthMonitoringCapture.value.height,
          weight:this.growthMonitoringCapture.value.weight,
          date_of_assessment:this.growthMonitoringCapture.value.date_of_assessment
        };
        let formData = new FormData;
        formData.append('baby_details_id', this.growthMonitoringCapture.value.baby_details_id)
        // formData.append('growth_monitoring_period_id', '2'),
        const date = new Date(this.growthMonitoringCapture.value.date_of_assessment);
         const formattedDate = date.toISOString().split('T')[0]; 
        formData.append('date_of_assessment',formattedDate),
        formData.append('height',this.growthMonitoringCapture.value.height)
        formData.append('weight',this.growthMonitoringCapture.value.weight)
        // formData.append('development_milestones',JSON.stringify(dataObject))
        // let images = JSON.stringify(this.growthMonitoringCapture.value.health_json);
        // formData.append('image', images)
        this.babyImgArray.forEach((file) => {
          formData.append('media', file);
        });
  
         {
          this.spinner.show();
          console.log(formData);
          
          this.growth_service.uploadheightweight(formData).then(
            (res: any) => {
              this.spinner.hide()
  
              this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: res?.message,
              });
              setTimeout(() => {
                this.router.navigateByUrl('/portal/first-screen')
              }, 400);
              // this.spinner.hide();
            },
            (error: any) => {
              this.spinner.hide()
  
              // Handle any errors that occurred during the API call
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: error?.message,
              });
            }
          );
        }
      } else {
        this.spinner.hide()
  
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Please Fill All the Details!',
        });
      }
      this.growthMonitoringCapture.reset();
    }
    
    capture() {
      ++this.IndexforImage
      const context = this.canvas.nativeElement.getContext('2d');
      const canvasWidth = this.canvas.nativeElement.width;
      const canvasHeight = this.canvas.nativeElement.height;
      context.drawImage(this.videoElement.nativeElement, 0, 0, canvasWidth, canvasHeight);
      const imageData = this.canvas.nativeElement.toDataURL('image/png');
      this.capturedByCamera.push(imageData)
      const byteString = atob(imageData.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: 'image/png' });
      this.capturedImg = new File([blob], `captured-image${this.IndexforImage}.png`, { type: 'image/png' });
      this.uploadPhoto(null, this.capturedImg)
      this.stopCamera();
    }
    uploadPhoto(event: any, captureImg?: any) {

      if (captureImg) {
        this.selectedFile = captureImg;
      }
      else {
        this.selectedFile = event.target.files[0];
      }
      if (event &&
        event.target.files[0].type == 'image/jpeg' ||
        'image/jpg' ||
        'image/png' ||
        'application/pdf'
      ) {
        if (event) {
          this.babyImgArray.push(event.target.files[0]);
        }
        else {
          this.babyImgArray.push(captureImg);
  
        }
      }
  
    }
    stopCamera() {
      const video = this.videoElement.nativeElement;
      if (video.srcObject) {
        const stream = video.srcObject as MediaStream;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop()); // Stop tracks to close the camera
        video.srcObject = null; // Reset the srcObject to release the camera resource
        this.openCameraforUpload = false
      }
    }

      addVitaminObject(string: any, index: any) {
        let vitaminArray = this.gethealthFormArray();
        if (string == 'add') {
          let newVitaminArray = new FormGroup({
            height: new FormControl(null),
            time: new FormControl(null),
            weight: new FormControl(null),
          });
    
          // let formatedTime =  moment(newTime).format('HH:mm:ss');
          vitaminArray.insert(index + 1, newVitaminArray);
        } else {
          vitaminArray.removeAt(index);
        }
      }

        gethealthFormArray() {
          return this.growthMonitoringCapture.get(
            'health_json'
          ) as FormArray;
        }
}
