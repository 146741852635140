import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-growth-monitoring-summary',
  templateUrl: './growth-monitoring-summary.component.html',
  styleUrls: ['./growth-monitoring-summary.component.scss']
})
export class GrowthMonitoringSummaryComponent implements OnInit {
  breadcrumb = [

    {
      title: 'Growth Monitoring Summary',
      subTitle: 'Dashboard',
      subTitle1: 'Nurse Task'

    },
  ];
  getToken: any;
  getRole: any;
  getname: any;
  allData: any = [];
  accordionOpenStates: boolean[] = [];
  permissionData: any = [];
  permissions: any = [];
  babyData: any = {};
  details: any = [];
  detail: any = [];
  weightConverted =false;
  heightConverted =false;
  babyImages: any
  health_json: any;
  motor_coordination: any;
  graph_data:any;
  sensory: any;
  communication: any;
  feeding: any;
  self_exp: any;
  play: any;
  alwaysdisplayMaximizable: boolean = true;
  displayMaximizable: boolean = false;
  displayfour_months_to_one_year: boolean = false;
  displayOneToThreeYear: boolean = false;
  displayThree_year_above: boolean = false;
  chartOptions: any
  isExpanded: boolean = false;
  ImageUrl: string = environment.imgUrl;
  babyImg:any = [];
  data: { labels: string[]; datasets: { label: string; data: number[]; fill: boolean; borderColor: string; tension: number; }[]; };
  options: { maintainAspectRatio: boolean; aspectRatio: number; plugins: { legend: { labels: { color: string; }; }; }; scales: { x: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; y: { ticks: { color: string; }; grid: { color: string; drawBorder: boolean; }; }; }; };

  constructor(
    private masterService: MasterService,
    private maikaListServices: MaikaListService,
    private spinner: NgxSpinnerService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getAllData();
    this.getHWI();
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  // user permission 
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }
  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  // get data by id
  // getData(id: any) {
  //   this.allData?.map((res: any) => {
  //     if (res.baby_id == id) {
  //       this.babyData = res;
  //       // this.babyData?.development_milestones.map((res: any) => {
  //       //   let data = JSON.parse(JSON.parse(res?.development_milestone))
  //       //   this.parsed.push(data);
  //       // });
  //     }
  //   });
  // }
  // add growthmonitoring new form
  addGrowthMonitoring() {
    let dob, growthMonitoringLink;

    // get DOB of baby from local storage to create care plan link according to their age 

    dob = localStorage.getItem('dob').split('/');
    let today = new Date();



    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);


    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();

    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));

    if (daysDiff <= 119) {
      growthMonitoringLink = '/portal/zero-to-three-month-growth-monitoring';
    }
    // up to 4 months  to 1 year
    else if (daysDiff <= 365 && daysDiff > 119) {
      growthMonitoringLink = '/portal/four-to-one-year-growth-monitoring';
    }
    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      growthMonitoringLink = '/portal/one-year-to-three-year-growth-monitoring';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      growthMonitoringLink = '/portal/three-year-and-above-year-growth-monitoring';
    }


    // route on form  
    this.router.navigateByUrl(growthMonitoringLink);
  }
  // excess all data
  getAllData() {
    // ;
    this.spinner.show();
    let id = localStorage.getItem('babyId');
    this.maikaListServices.getMonitorList(id)
      .then((res: any) => {
        this.spinner.hide();

        this.allData = res?.data;
        
        this.allData?.map((res: any) => {
          if (res.baby_id == id) {
            this.babyData = res;
            // this.babyData?.development_milestones.map((res: any) => {
            //   let data = JSON.parse(JSON.parse(res?.development_milestone))
            //   this.parsed.push(data);
            // });
          }
        });
        // Initialize accordionOpenStates with false values
        this.accordionOpenStates = new Array(this.allData.length).fill(false);
        
        this.showData();
      },
        (error: any) => {
          this.spinner.hide()
          // Handle any errors that occurred during the API call

        });

  }
  // on click get relevent data in accordion 
  showData() {
    if (this.babyData?.growth_monitoring_period_title == "0 - 3 Months") {
      this.displayMaximizable = true;
      this.viewZero_to_three_months();
    }
    else if (this.babyData?.growth_monitoring_period_title == "3 Years Old and Above") {
      this.displayThree_year_above = true;
      this.ViewThree_year_above();
    }
    else if (this.babyData?.growth_monitoring_period_title == "4 Months - 1 Year Old") {
      this.displayfour_months_to_one_year = true;
      this.viewFour_months_to_one_year();
    }
    else if (this.babyData?.growth_monitoring_period_title == "1 - 3 Years Old") {
      this.displayOneToThreeYear = true;
      this.viewOneYearToThreeYear();
    }
  }
  viewZero_to_three_months() {
    this.detail = [];
    const firstDevelopmentMilestone = this.babyData?.development_milestones[0];
    
    if (firstDevelopmentMilestone) {
      this.detail = JSON.parse(firstDevelopmentMilestone.development_milestone);
      
      this.health_json = firstDevelopmentMilestone.physical_assessment_details_json;
      this.babyImages = firstDevelopmentMilestone.growth_images_json;
      this.createGraph();
    }

  }

  viewFour_months_to_one_year() {
    this.detail = [];
    // ;
    
    const firstDevelopmentMilestone = this.babyData?.development_milestones[0];
    
    if (firstDevelopmentMilestone) {
      this.detail = (JSON.parse(firstDevelopmentMilestone?.development_milestone));
      this.motor_coordination = firstDevelopmentMilestone?.motor_coordination;
      this.health_json = firstDevelopmentMilestone?.physical_assessment_details_json
      this.babyImages = firstDevelopmentMilestone?.growth_images_json
      this.sensory = this.detail?.sensory;
      this.communication = this.detail?.communication;
      this.feeding = this.detail?.feeding;
      this.self_exp = this.detail?.self_expression;
      this.play = this.detail?.play_and_social_skills;
      this.createGraph();
    }

    // 

  }

  viewOneYearToThreeYear() {
    this.detail = [];
    // ;


    const firstDevelopmentMilestone = this.babyData?.development_milestones[0];
    if (firstDevelopmentMilestone) {
      this.detail = (JSON.parse(firstDevelopmentMilestone?.development_milestone));
      this.motor_coordination = this.detail.motor_coordination;
      this.sensory = this.detail.sensory;
      this.health_json = firstDevelopmentMilestone?.physical_assessment_details_json
      this.babyImages = firstDevelopmentMilestone?.growth_images_json
      this.communication = this.detail.communication;
      this.createGraph();

    }

  }
  ViewThree_year_above() {
    this.detail = [];
    // ;

    const firstDevelopmentMilestone = this.babyData?.development_milestones[0];
    if (firstDevelopmentMilestone) {
      this.detail = (JSON.parse(firstDevelopmentMilestone?.development_milestone));
      this.health_json = firstDevelopmentMilestone?.physical_assessment_details_json
      this.babyImages = firstDevelopmentMilestone?.growth_images_json
      this.createGraph();
    }
    // this.motor_coordination = this.detail.motor_coordination;
    // this.sensory = this.detail.sensory;
    // this.communication = this.detail.communication;


  }

  // graph 
  createGraph() {
    const root = document.documentElement;
    const documentStyle = getComputedStyle(root);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const heightData = new Array(12).fill(0);
    const weightData = new Array(12).fill(0);
    
    if (this.graph_data) {
      this.graph_data.forEach(dataItem => {
        if (dataItem && dataItem.time) {
          const monthIndex = new Date(dataItem.time).getMonth();
          const height = parseInt(dataItem.height);
          const weight = parseInt(dataItem.weight);
       
          if (!isNaN(height)) {
            heightData[monthIndex] = height;
          }

          if (!isNaN(weight)) {
            weightData[monthIndex] = weight;
          }
        }
      });
    }

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      datasets: [
        {
          label: 'Height',
          data: heightData,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4
        },
        {
          label: 'Weight',
          data: weightData,
          fill: false,
          borderColor: documentStyle.getPropertyValue('--red-500'),
          tension: 0.4
        },
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }
  toggleSize() {
    this.isExpanded = !this.isExpanded;

  }
  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }
  convertKgToPounds() {
    if (this.weightConverted) return; 

    // Get the FormArray from the form group
    // const healthFormArray = this.zeroToThreeMonitor.get('health_json') as FormArray;
    // Loop through each form group in the FormArray
    this.health_json.forEach((item) => {
      // Get the current weight value
      const weightInKg = item.weight;
      
      // Convert weight to pounds if it's a valid number
      if (weightInKg && !isNaN(weightInKg)) {
        const weightInPounds = (parseFloat(weightInKg) * 2.20462);
        item.weight =weightInPounds.toFixed(2)
      }
    });
  this.weightConverted = true; //

  }
  
  convertHeightToCm(): void {
    if (this.heightConverted) return;
  
    // Assuming health_json is an array of objects with 'height' as a key
    this.health_json.forEach((item) => {
      // Get the current height value from the item
      const heightInInches = item.height;
      
      if (heightInInches && !isNaN(heightInInches)) {
        const heightInCm = parseFloat(heightInInches) * 2.54;
        // Update the item with the new height value in cm
        item.height = heightInCm.toFixed(2);
      }
    });
    
    this.heightConverted = true;
  }
  getHWI() {
    const payload = {
      baby_details_id: localStorage.getItem('babyId') // Correctly set the key-value pair
    };
  
    this.maikaListServices.getHWI(payload).then((res: any) => {
      this.babyImg = res.data.map((item: any) => {
        
        // Ensure the image property is an array
        if (!Array.isArray(item.image)) {
          try {
            item.image = JSON.parse(item.image);
          } catch (e) {
            item.image = [];
          }
        }
  
        // Log each image URL
        item.image.forEach((img: any) => {
        });
        this.graph_data = res.data.map((item: any) => {
          return {
            height: item.height,
            weight: item.weight,
            time: item.date_of_assessment // Ensure the date format is correct
          };
        });
        return item;
      });
      this.createGraph();
     
  
      this.spinner.hide();
    }, (error: any) => {
      console.error('Error fetching HWI data:', error);
      this.spinner.hide();
    });
  }
  
}
