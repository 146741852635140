import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InitialAssessmentFormService } from 'src/app/services/initial-assessment-form.service';
import { CommonApiService } from 'src/app/services/common-api.service';

import { NgxSpinnerService } from 'ngx-spinner';

import {
  FormGroup,
  FormBuilder,
  FormControl,
  FormArray,
  AbstractControl,
  Validators,
  FormControlName
} from '@angular/forms'
import { timeInterval } from 'rxjs/operators';
import * as moment from 'moment';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
@Component({
  selector: 'app-health-monitoring',
  templateUrl: './health-monitoring.component.html',
  styleUrls: ['./health-monitoring.component.scss']
})
export class HealthMonitoringComponent implements OnInit {
  flag: any;
  isFeedingRespiration: boolean = false;
  isFeedingSpO2: boolean;
  healthData: any;
  babyGender: any;
  selectedDate: any;
  years: number;
  month: number;
  days: number;
  nurseName: any;
  logInputValue(value: string) {
  }
  myForm: FormGroup;
  breadcrumb = [
    {
      title: 'Health Monitoring Form',
      subTitle: 'Dashboard',
    },
  ]
  babyasthmatic: boolean = false;
  index: any = 0;
  babyDetails: any = [];
  permissionData: any = [];
  permissions: any = [];
  getToken: any;
  getRole: any;
  getname: any;
  frequency: any = [];
  startTime: string = ''; // Variable to hold start time
  endTime: string = ''; // Variable to hold end time
  timeDifference: string = ''; // Variable to display time difference
  frequencyValue: any;
  frequencyValue1: any
  frequencyValue2: any;
  frequencyValue3: any;
  isFeeding: boolean = false;
  isFeedingVital: boolean = false;
  frequencyValue4: any;
  maxDate = new Date();
  nurseData: any;
  isedit: boolean = false;
  babysick: boolean = false;
  frequencyList: any = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
    { name: 4, value: 4 },
    { name: 5, value: 5 },
    { name: 6, value: 6 },
    { name: 7, value: 7 },
    { name: 8, value: 8 },
    { name: 9, value: 9 },
    { name: 10, value: 10 },
    { name: 11, value: 11 },
    { name: 12, value: 12 },
  ];

  heathMonitoringForm: FormGroup
  constructor(
    private masterService: MasterService,

    private fb: FormBuilder,
    private commanServices: CommonApiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private router: Router,
    private common_service: CommonApiService,
    private initial_form_service: InitialAssessmentFormService,
    private daily_care_plan: MaikaListService,

  ) {
    this.getAllUserPermission();
    this.heathMonitoringForm = this.fb.group({
      baby_details_id: new FormControl(null, [Validators.required]),
      create_time: new FormControl('', [Validators.required]),
      update_time: new FormControl(''),
      created_by_user_id: new FormControl(''),
      updated_by_user_id: new FormControl(''),
      nurse_id: new FormControl(''),
      sick_or_not: new FormControl(''),
      remarks: new FormControl(''),
      temperature_json: this.fb.group({ // Nested form group for temperature_json
        temp_freq: new FormControl(''),
        start_time_temperature: new FormControl(''),
        end_time_temperature: new FormControl(''),
        temprature_extra_value: this.fb.array([
          this.fb.group({
            temp_time: new FormControl(null),
            actual_temp_time: new FormControl(null),
            temp_checkbox: new FormControl(null),
            value: new FormControl(null),
            action: new FormControl(null),
          })
        ]),
      }),
      vital_signs_json: this.fb.group({
        pulse_vital: this.fb.group({
          freq_pulse: new FormControl(''),
          start_time_pulse: new FormControl(''),
          end_time_pulse: new FormControl(''),
          vital_extra_value: this.fb.array([
            this.fb.group({
              vital_time: new FormControl(null),
              actual_vital_time: new FormControl(null),
              vital_checkbox: new FormControl(null),
              vital_value: new FormControl(null),
              vital_action: new FormControl(null),
            })
          ])
        }),
        respiration_vital: this.fb.group({
          yes_no_respiration: new FormControl(''),
          freq_respiration: new FormControl(''),
          start_time_respiration: new FormControl(''),
          end_time_respiration: new FormControl(''),
          respiration_extra_value: this.fb.array([
            this.fb.group({
              respiration_time: new FormControl(null),
              actual_respiration_time: new FormControl(null),
              respiration_checkbox: new FormControl(null),
              respiration_value: new FormControl(null),
              respiration_action: new FormControl(null),
            })
          ])
        }),
        spo2_vital: this.fb.group({
          freq_spo2: new FormControl(''),
          start_time_spo2: new FormControl(''),
          end_time_spo2: new FormControl(''),
          spo2_extra_value: this.fb.array([
            this.fb.group({
              spo2_time: new FormControl(null),
              actual_spo2_time: new FormControl(null),
              spo2_checkbox: new FormControl(null),
              spo2_value: new FormControl(null),
              spo2_action: new FormControl(null),
            })
          ])
        })
      }),
      doc_recommendation_json: this.fb.array([]),
      medications_json: this.fb.array([]),
      immunization: this.fb.group({
        // autocomplete fields 
        bcg_dose_date: new FormControl(null),
        bcg_dose_name:'BCG',
        HepatitiesB_1stdose_date: new FormControl(null),
        HepatitiesB_2nd_dose_date: new FormControl(null),
        HepatitiesB_1stdose_name:'Hepatitis B(1stDose)',
        HepatitiesB_2nd_dose_name:'Hexavalent Vaccine DTaP-IPV-Hib(1stDose) Hepatitis B (2ndDose)',
        pcv_1stdose_date: new FormControl(null),
        pcv_1stdose_name:'PCV (1stDose)',
        rotavirus_1stdose_date: new FormControl(null),
        rotavirus_1stdose_name:'Rotavirus',
        HepatitiesB_3rd_dose_date: new FormControl(null),
        HepatitiesB_3rd_dose_name:'Hexavalent Vaccine DTaP-IPV-Hib(2ndDose) Hepatitis B (3rdDose)',
        opv_1st_dose_date: new FormControl(null),
        opv_1st_dose_name:'OPV',
        pcv_2nd_dose_date: new FormControl(null),
        pcv_2nd_dose_name:'PCV (2ndDose)',
        rotavirus_2nd_dose_date: new FormControl(null),
        rotavirus_2nd_dose_name:'Rotavirus (2ndDose)',
        HepatitiesB_4th_dose_date: new FormControl(null),
        HepatitiesB_4rd_dose_name:'Pentavalent Vaccine DTaP-IPV-Hib(2ndDose) Hepatitis B (4thDose)',
        rotateqx3_dose_date: new FormControl(null),
        rotateqx3_dose_name:'Rotateq X3	',
        opv_2nd_dose_date: new FormControl(null),
        opv_2nd_dose_name:'OPV (2ndDose)',
        pcv_3rd_dose_date: new FormControl(null),
        pcv_3rd_dose_name:'PCV (3rdDose)',
        mmr1st_dose_date: new FormControl(null),
        mmr1st_dose_name:'MMR (1stDose)',
        chickenPox_1st_dose_date: new FormControl(null),
        chickenPox_1st_dose_name:'Chicken Pox (1stDose)',
        pcv_4th_dose_date: new FormControl(null),
        pcv_4th_dose_name:'PCV (4thDose)	',
        tetravalent_dpt_hiv_dose_date: new FormControl(null),
        tetravalent_dpt_hiv_dose_name:'Tetravalent Vaccine DPT-Hib',
        opv_4th_dose_date: new FormControl(null),
        opv_4th_dose_name:'OPV (4thDose)',
        mmr2nd_dose_date: new FormControl(null),
        mmr2nd_dose_name:'MMR (2ndDose)',
        dpt_dose_date: new FormControl(null),
        dpt_dose_name:'DPT',
        opv_3rd_dose_date: new FormControl(null),
        opv_3rd_dose_name:'OPV (3rdDose)',
        chickenPox_2nd_dose_date: new FormControl(null),
        chickenPox_2nd_dose_name:'Chicken Pox (2ndDose)',
        influenza_dose_date: new FormControl(null),
        influenza_dose_name:'Influenza Vaccine',
        HepatitiesA_1st_dose_date: new FormControl(null),
        HepatitiesA_1st_dose_name:'Hepatities A Vaccine (1stDose)',
        HepatitiesA_2nd_dose_date: new FormControl(null),
        HepatitiesA_2nd_dose_name:'Hepatities A Vaccine (2ndDose)',
        meningococcal_dose_date: new FormControl(null),
        meningococcal_dose_name: 'Meningococcal Vaccine',
        typhoid_dose_date: new FormControl(null),
        typhoid_dose_name:'Typhoid Vaccine',
        hpv_1st_dose_date: new FormControl(null),
        hpv_1st_dose_name:'HPV (1stDose)',
        hpv_2nd_dose_date: new FormControl(null),
        hpv_2nd_dose_name:'HPV (2ndDose)',
        hpv_3rd_dose_date: new FormControl(null),
        hpv_3rd_dose_name:'HPV (3rdDose)',
        // autocomplete fields end 
        bcg_brand: new FormControl(null),
        bcg_dose_given_date: new FormControl(null),
        bcg_weight: new FormControl(null),
        bcg_staff: new FormControl(null),
        bcg_batchNumber: new FormControl(null),
        bcg_clinicname: new FormControl(null),
        HepatitiesB_2nd_brand: new FormControl(null),
        HepatitiesB_2nd_dose_given_date: new FormControl(null),
        Hexavalent_1st_weight: new FormControl(null),
        Hexavalent_1st_staff: new FormControl(null),
        Hexavalent_1st_batchNumber: new FormControl(null),
        Hexavalent_1st_clinicname: new FormControl(null),
        HepatitiesB_1stdose_brand: new FormControl(null),
        HepatitiesB_1stdose_given_date: new FormControl(null),
        HepatitiesB_1stdose_weight: new FormControl(null),
        HepatitiesB_1stdose_staff: new FormControl(null),
        HepatitiesB_1stdose_batchNumber: new FormControl(null),
        HepatitiesB_1stdose_clinicname: new FormControl(null),
        pcv_1stdose_brand: new FormControl(null),
        pcv_1stdose_given_date: new FormControl(null),
        pcv_1stdose_weight: new FormControl(null),
        pcv_1stdose_staff: new FormControl(null),
        pcv_1stdose_batchNumber: new FormControl(null),
        pcv_1stdose_clinicname: new FormControl(null),
        rotavirus_1stdose_brand: new FormControl(null),
        rotavirus_1stdose_given_date: new FormControl(null),
        rotavirus_1stdose_weight: new FormControl(null),
        rotavirus_1stdose_staff: new FormControl(null),
        rotavirus_1stdose_batchNumber: new FormControl(null),
        rotavirus_1stdose_clinicname: new FormControl(null),
        HepatitiesB_3rd_dose_brand: new FormControl(null),
        HepatitiesB_3rd_dose_given_date: new FormControl(null),
        HepatitiesB_3rd_dose_weight: new FormControl(null),
        HepatitiesB_3rd_dose_staff: new FormControl(null),
        HepatitiesB_3rd_dose_batchNumber: new FormControl(null),
        HepatitiesB_3rd_dose_clinicname: new FormControl(null),
        opv_1st_dose_brand: new FormControl(null),
        opv_1st_dose_given_date: new FormControl(null),
        opv_1st_dose_weight: new FormControl(null),
        opv_1st_dose_staff: new FormControl(null),
        opv_1st_dose_batchNumber: new FormControl(null),
        opv_1st_dose_clinicname: new FormControl(null),
        pcv_2nd_dose_brand: new FormControl(null),
        pcv_2nd_dose_given_date: new FormControl(null),
        pcv_2nd_dose_weight: new FormControl(null),
        pcv_2nd_dose_staff: new FormControl(null),
        pcv_2nd_dose_batchNumber: new FormControl(null),
        pcv_2nd_dose_clinicname: new FormControl(null),
        rotavirus_2nd_dose_given_date: new FormControl(null),
        HepatitiesB_4th_dose_brand: new FormControl(null),
        HepatitiesB_4th_dose_given_date: new FormControl(null),
        HepatitiesB_4th_dose_weight: new FormControl(null),
        HepatitiesB_4th_dose_staff: new FormControl(null),
        HepatitiesB_4th_dose_batchNumber: new FormControl(null),
        HepatitiesB_4th_dose_clinicname: new FormControl(null),
        rotateqx3_dose_brand: new FormControl(null),
        rotateqx3_dose_given_date: new FormControl(null),
        rotateqx3_dose_weight: new FormControl(null),
        rotateqx3_dose_staff: new FormControl(null),
        rotateqx3_dose_batchNumber: new FormControl(null),
        rotateqx3_dose_clinicname: new FormControl(null),
        opv_2nd_dose_brand: new FormControl(null),
        opv_2nd_dose_given_date: new FormControl(null),
        opv_2nd_dose_weight: new FormControl(null),
        opv_2nd_dose_staff: new FormControl(null),
        opv_2nd_dose_batchNumber: new FormControl(null),
        opv_2nd_dose_clinicname: new FormControl(null),
        pcv_3rd_dose_brand: new FormControl(null),
        pcv_3rd_dose_given_date: new FormControl(null),
        pcv_3rd_dose_weight: new FormControl(null),
        pcv_3rd_dose_staff: new FormControl(null),
        pcv_3rd_dose_batchNumber: new FormControl(null),
        pcv_3rd_dose_clinicname: new FormControl(null),
        mmr1st_dose_brand: new FormControl(null),
        mmr1st_dose_given_date: new FormControl(null),
        mmr1st_dose_weight: new FormControl(null),
        mmr1st_dose_staff: new FormControl(null),
        mmr1st_dose_batchNumber: new FormControl(null),
        mmr1st_dose_clinicname: new FormControl(null),
        chickenPox_1st_dose_brand: new FormControl(null),
        chickenPox_1st_dose_given_date: new FormControl(null),
        chickenPox_1st_dose_weight: new FormControl(null),
        chickenPox_1st_dose_staff: new FormControl(null),
        chickenPox_1st_dose_batchNumber: new FormControl(null),
        chickenPox_1st_dose_clinicname: new FormControl(null),
        pcv_4th_dose_brand: new FormControl(null),
        pcv_4th_dose_given_date: new FormControl(null),
        pcv_4th_dose_weight: new FormControl(null),
        pcv_4th_dose_staff: new FormControl(null),
        pcv_4th_dose_batchNumber: new FormControl(null),
        pcv_4th_dose_clinicname: new FormControl(null),
        tetravalent_dpt_hiv_dose_brand: new FormControl(null),
        tetravalent_dpt_hiv_dose_given_date: new FormControl(null),
        tetravalent_dpt_hiv_dose_weight: new FormControl(null),
        tetravalent_dpt_hiv_dose_staff: new FormControl(null),
        tetravalent_dpt_hiv_dose_batchNumber: new FormControl(null),
        tetravalent_dpt_hiv_dose_clinicname: new FormControl(null),
        opv_4th_dose_brand: new FormControl(null),
        opv_4th_dose_given_date: new FormControl(null),
        opv_4th_dose_weight: new FormControl(null),
        opv_4th_dose_staff: new FormControl(null),
        opv_4th_dose_batchNumber: new FormControl(null),
        opv_4th_dose_clinicname: new FormControl(null),
        mmr2nd_dose_brand: new FormControl(null),
        mmr2nd_dose_given_date: new FormControl(null),
        mmr2nd_dose_weight: new FormControl(null),
        mmr2nd_dose_staff: new FormControl(null),
        mmr2nd_dose_batchNumber: new FormControl(null),
        mmr2nd_dose_clinicname: new FormControl(null),
        dpt_dose_brand: new FormControl(null),
        dpt_dose_given_date: new FormControl(null),
        dpt_dose_weight: new FormControl(null),
        dpt_dose_staff: new FormControl(null),
        dpt_dose_batchNumber: new FormControl(null),
        dpt_dose_clinicname:new FormControl (null),
        opv_3rd_dose_brand: new FormControl(null),
        opv_3rd_dose_given_date: new FormControl(null),
        opv_3rd_dose_weight: new FormControl(null),
        opv_3rd_dose_staff: new FormControl(null),
        opv_3rd_dose_batchNumber: new FormControl(null),
        opv_3rd_dose_clinicname: new FormControl (null),
        chickenPox_2nd_dose_brand: new FormControl(null),
        chickenPox_2nd_dose_given_date: new FormControl(null),
        chickenPox_2nd_dose_weight: new FormControl(null),
        chickenPox_2nd_dose_staff: new FormControl(null),
        chickenPox_2nd_dose_batchNumber: new FormControl(null),
        chickenPox_2nd_dose_clinicname:new FormControl(null),
        influenza_dose_brand: new FormControl(null),
        influenza_dose_given_date: new FormControl(null),
        influenza_dose_weight: new FormControl(null),
        influenza_dose_staff: new FormControl(null),
        influenza_dose_batchNumber: new FormControl(null),
        influenza_dose_clinicname:new FormControl (null),
        HepatitiesA_1st_dose_brand: new FormControl(null),
        HepatitiesA_1st_dose_given_date: new FormControl(null),
        HepatitiesA_1st_dose_weight: new FormControl(null),
        HepatitiesA_1st_dose_staff: new FormControl(null),
        HepatitiesA_1st_dose_batchNumber: new FormControl(null),
        HepatitiesA_1st_dose_clinicname: new FormControl(null),
        HepatitiesA_2nd_dose_brand: new FormControl(null),
        HepatitiesA_2nd_dose_given_date: new FormControl(null),
        HepatitiesA_2nd_dose_weight: new FormControl(null),
        HepatitiesA_2nd_dose_staff: new FormControl(null),
        HepatitiesA_2nd_dose_batchNumber: new FormControl(null),
        HepatitiesA_2nd_dose_clinicname: new FormControl(null),
        meningococcal_dose_brand: new FormControl(null),
        meningococcal_dose_given_date: new FormControl(null),
        meningococcal_dose_weight: new FormControl(null),
        meningococcal_dose_staff: new FormControl(null),
        meningococcal_dose_batchNumber: new FormControl(null),
        meningococcal_dose_clinicname:new FormControl(null),
        typhoid_dose_brand: new FormControl(null),
        typhoid_dose_given_date: new FormControl(null),
        typhoid_dose_weight: new FormControl(null),
        typhoid_dose_staff: new FormControl(null),
        typhoid_dose_batchNumber: new FormControl(null),
        typhoid__dose_clinicname: new FormControl(null),
        hpv_1st_dose_brand: new FormControl(null),
        hpv_1st_dose_given_date: new FormControl(null),
        hpv_1st_dose_weight: new FormControl(null),
        hpv_1st_dose_staff: new FormControl(null),
        hpv_1st_dose_batchNumber: new FormControl(null),
        hpv_1st_dose_clinicname:new FormControl (null),
        hpv_2nd_dose_brand: new FormControl(null),
        hpv_2nd_dose_given_date: new FormControl(null),
        hpv_2nd_dose_weight: new FormControl(null),
        hpv_2nd_dose_staff: new FormControl(null),
        hpv_2nd_dose_batchNumber: new FormControl(null),
        hpv_2nd_dose_clinicname:new FormControl(null),
        hpv_3rd_dose_brand: new FormControl(null),
        hpv_3rd_dose_given_date: new FormControl(null),
        hpv_3rd_dose_weight: new FormControl(null),
        hpv_3rd_dose_staff: new FormControl(null),
        hpv_3rd_dose_batchNumber: new FormControl(null),
        hpv_3rd_dose_clinicname: new FormControl(null),
        dtab_tpv_hib_1st_brand: new FormControl(null),
        dtab_tpv_hib_2nd_brand: new FormControl(null),
        dtab_tpv_hib_3rd_brand: new FormControl(null),
        rotavirus_2nddose_brand: new FormControl(null),
        dtab_tpv_hib_1st_dose_date: new FormControl(null),
        dtab_tpv_hib_1st_dose_given_date: new FormControl(null),
        dtab_tpv_hib_1st_weight: new FormControl(null),
        dtab_tpv_hib_1st_staff: new FormControl(null),
        dtab_tpv_hib_1st_batchNumber: new FormControl(null),
        dtab_tpv_hib_1st_clinicname: new FormControl(null),
        dtab_tpv_hib_2nd_dose_date: new FormControl(null),
        dtab_tpv_hib_2nd_dose_given_date: new FormControl(null),
        dtab_tpv_hib_2nd_dose_weight: new FormControl(null),
        dtab_tpv_hib_2nd_dose_staff: new FormControl(null),
        dtab_tpv_hib_2nd_dose_batchNumber: new FormControl(null),
        dtab_tpv_hib_2nd_dose_clinicname: new FormControl(null),
        dtab_tpv_hib_3rd_dose_date: new FormControl(null),
        dtab_tpv_hib_3rd_batchNumber: new FormControl(null),
        dtab_tpv_hib_3rd_clinicname: new FormControl(null),
        dtab_tpv_hib_3rd_staff: new FormControl(null),
        dtab_tpv_hib_3rd_weight: new FormControl(null),
        dtab_tpv_hib_3rd_given_date: new FormControl(null),
        rotavirus_2nd_dose_weight: new FormControl(null),
        rotavirus_2nd_dose_staff: new FormControl(null),
        rotavirus_2nd_dose_batchNumber: new FormControl(null),
        rotavirus_2nd_dose_clinicname: new FormControl(null),
      })
    })
  }

  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }
  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  ngOnInit(): void {
    this.getNurseDropDown();
    this.getHealthMonitoring();
    this.spinner.show();
    this.commanServices.getBabyDetails().subscribe((res: any) => {
      this.spinner.hide()
      this.babyDetails = res?.data;
      let babyId = localStorage.getItem('create');
      let data = this.babyDetails?.filter((res: any) => res?.id == babyId);

      if (data) {
        this.calculateDoseDates(new Date(this.convertDateFormat(data[0].date_of_birth)));
      }
      // For frequency dropdown list.
      for (let i = 1; i <= 10; i++) {
        this.frequency.push({ frequency: i });
      }
    });
    this.disableTemperatureControls();


    if (localStorage.getItem('create')) {
      const getEdit = localStorage.getItem('create');
      this.isedit = false;
      const babyId = localStorage.getItem('babyId');

      this.heathMonitoringForm.controls.baby_details_id.setValue(
        JSON.parse(getEdit)
      );


      this.getBabyDetailsInNew({ value: getEdit });

    }

    if (localStorage.getItem('editedOrderId')) {
      this.getEditFormData();
      this.calculateDoseDates(new Date(this.convertDateFormat('4-0')));

      this.disableForm()
      this.isedit = true;
    }

    else {
      this.addMedication('add', 0)
      this.isedit = false;
      this.addDocRecommendation('add', 0);
    }

    this.disableForm()
    this.getname = localStorage.getItem('name');
    this.getToken = localStorage.getItem('token');
    this.getRole = localStorage.getItem('role')
  }
  get immunizationForm() {
    return this.heathMonitoringForm.get('immunization') as FormGroup;
  }

  get temperature_json() {
    return this.heathMonitoringForm.get('temperature_json') as FormGroup;
  }
  get vital_signs_json() {
    return this.heathMonitoringForm.get('vital_signs_json') as FormGroup;
  }
  get doc_recommendation_json() {
    return this.heathMonitoringForm.get('doc_recommendation_json') as FormGroup;
  }
  get medications_json() {
    return this.heathMonitoringForm.get('medications_json') as FormGroup;
  }
  // calculate all Dose dates
  calculateDoseDates(Date_of_birth: Date) {
    // 1st table 
    // birth 
    this.immunizationForm.get('bcg_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 0));

    this.immunizationForm.get('HepatitiesB_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 0, 0));

    // 2 months
    this.immunizationForm.get('HepatitiesB_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));
    this.immunizationForm.get('pcv_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));
    this.immunizationForm.get('rotavirus_1stdose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));
    this.immunizationForm.get('dtab_tpv_hib_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 2, 0));

    // 4 months
    this.immunizationForm.get('HepatitiesB_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunizationForm.get('opv_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunizationForm.get('pcv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunizationForm.get('rotavirus_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));
    this.immunizationForm.get('dtab_tpv_hib_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 4, 0));


    // 6 months
    this.immunizationForm.get('HepatitiesB_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunizationForm.get('rotateqx3_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunizationForm.get('opv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunizationForm.get('pcv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));
    this.immunizationForm.get('dtab_tpv_hib_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));

    // 12 months
    this.immunizationForm.get('mmr1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunizationForm.get('chickenPox_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));

    // 15 months 
    this.immunizationForm.get('pcv_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 3, 1));

    // 18 months
    this.immunizationForm.get('tetravalent_dpt_hiv_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));
    this.immunizationForm.get('opv_4th_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));
    this.immunizationForm.get('mmr2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 1));

    // 5 years 
    this.immunizationForm.get('dpt_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));
    this.immunizationForm.get('opv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));
    this.immunizationForm.get('chickenPox_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 5));

    // 2nd table 
    // 6 months 
    this.immunizationForm.get('influenza_dose_date').setValue(this.addDate(Date_of_birth, 0, 6, 0));

    // 1 year 
    this.immunizationForm.get('HepatitiesA_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunizationForm.get('HepatitiesA_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));
    this.immunizationForm.get('meningococcal_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 1));

    // 2 year 
    this.immunizationForm.get('typhoid_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 2));

    // 11 years
    this.immunizationForm.get('hpv_1st_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));
    this.immunizationForm.get('hpv_2nd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));
    this.immunizationForm.get('hpv_3rd_dose_date').setValue(this.addDate(Date_of_birth, 0, 0, 11));
  }
  changeHapatitisBrand(event: any) {
    this.immunizationForm.get('HepatitiesB_1stdose_brand').setValue(event.target.value);
    this.immunizationForm.get('HepatitiesB_2nd_brand').setValue(event.target.value);
    this.immunizationForm.get('HepatitiesB_3rd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('HepatitiesB_4th_dose_brand').setValue(event.target.value);
  }
  changeDtapIpvHibBrand(event: any) {
    this.immunizationForm.get('dtab_tpv_hib_1st_brand').setValue(event.target.value);
    this.immunizationForm.get('dtab_tpv_hib_2nd_brand').setValue(event.target.value);
    this.immunizationForm.get('dtab_tpv_hib_3rd_brand').setValue(event.target.value);
  }

  changePCVBrand(event: any) {
   
    this.immunizationForm.get('pcv_1stdose_brand').setValue(event.target.value);
    this.immunizationForm.get('pcv_2nd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('pcv_3rd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('pcv_4th_dose_brand').setValue(event.target.value);
  }

  changeRotavirusBrand(event: any) {
   
    this.immunizationForm.get('rotavirus_1stdose_brand').setValue(event.target.value);
    this.immunizationForm.get('rotavirus_2nddose_brand').setValue(event.target.value);
  }

  changeOPVBrand(event: any) {
   
    this.immunizationForm.get('opv_1st_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('opv_2nd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('opv_3rd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('opv_4th_dose_brand').setValue(event.target.value);
  }

  changeMMRBrand(event: any) {
   
    this.immunizationForm.get('mmr1st_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('mmr2nd_dose_brand').setValue(event.target.value);
  }

  changeHPVBrand(event: any) {
   
    this.immunizationForm.get('hpv_1st_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('hpv_2nd_dose_brand').setValue(event.target.value);
    this.immunizationForm.get('hpv_3rd_dose_brand').setValue(event.target.value);
  }

  addDate(date: Date, days: number, months: number, years: number): Date {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + days);
    resultDate.setMonth(resultDate.getMonth() + months);
    resultDate.setFullYear(resultDate.getFullYear() + years);
    return resultDate;
  }

  //
  //
  //handleInput Changes for is sick or not
  handleInput(event: any, value: any) {
    if (value == 'sick') {
      this.enableTemperatureControls();
      this.babysick = true;
    }
    else if (value == 'notsick') {
      this.disableTemperatureControls();
      this.babysick = false;
    }
    else if (value == 'asthmatic') {
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.freq_respiration').enable();
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.start_time_respiration').enable();
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.end_time_respiration').enable();
    }
    else if (value == 'unasthmatic') {
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.freq_respiration').disable();
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.start_time_respiration').disable();
      this.heathMonitoringForm.get('vital_signs_json.respiration_vital.end_time_respiration').disable();
    }

  }


  enableTemperatureControls() {
    this.heathMonitoringForm.get('temperature_json.temp_freq').enable();
    this.heathMonitoringForm.get('temperature_json.start_time_temperature').enable();
    this.heathMonitoringForm.get('temperature_json.end_time_temperature').enable()
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.freq_pulse').enable();
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.start_time_pulse').enable();
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.end_time_pulse').enable();
    this.heathMonitoringForm.get('vital_signs_json.respiration_vital.yes_no_respiration').enable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.freq_spo2').enable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.start_time_spo2').enable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.end_time_spo2').enable()
  }

  disableTemperatureControls() {
    this.heathMonitoringForm.get('temperature_json.temp_freq').disable();
    this.heathMonitoringForm.get('temperature_json.start_time_temperature').disable();
    this.heathMonitoringForm.get('temperature_json.end_time_temperature').disable()
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.freq_pulse').disable();
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.start_time_pulse').disable();
    this.heathMonitoringForm.get('vital_signs_json.pulse_vital.end_time_pulse').disable();
    this.heathMonitoringForm.get('vital_signs_json.respiration_vital.yes_no_respiration').disable();
    this.heathMonitoringForm.get('vital_signs_json.respiration_vital.freq_respiration').disable();
    this.heathMonitoringForm.get('vital_signs_json.respiration_vital.start_time_respiration').disable();
    this.heathMonitoringForm.get('vital_signs_json.respiration_vital.end_time_respiration').disable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.freq_spo2').disable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.start_time_spo2').disable();
    this.heathMonitoringForm.get('vital_signs_json.spo2_vital.end_time_spo2').disable()

  }
  // 
  //
  // in that there is work done on functionality
  // getEditViewFormData(){
  //   const getEdit = localStorage.getItem('editedHealthId');
  //   this.commanServices.getByIdHealthMonitoring(getEdit).then((res: any) => {
  //     const healthData = res.data;
  //     const wakeupTime = this.parseISOString(healthData.created_at) || null
  //     this.heathMonitoringForm.get('baby_details_id').setValue(JSON.parse(healthData.baby_details_id));
  //     this.heathMonitoringForm.get('created_at').setValue(wakeupTime);
  //   })
  // }
  // 
  // 
  // nurse dropdown
  getNurseDropDown() {
    this.initial_form_service.getNurseDropDown().then((res: any) => {
      this.nurseData = res?.data;
    });
  }
  // 
  getEditFormData() {
    this.spinner.show()
    const getEdit = localStorage.getItem('editedOrderId')
    this.common_service.getByIdHealthMonitoring(getEdit).then((res: any) => {
      this.spinner.hide()

      const detailshealthmonitoring = res.data;
      this.healthData = detailshealthmonitoring
      this.nurseName = this.healthData.nurse_name
      // this.heathMonitoringForm.controls.create_time.setValue(new Date(detailshealthmonitoring.create_time))
      // this.heathMonitoringForm.controls.sick_or_not.setValue(detailshealthmonitoring.sick_or_not)
      // // this.heathMonitoringForm.controls.temperature_json..setValue(new Date(detailshealthmonitoring.temperature_json.start_time_temperature))
      // this.immunizationForm.get('bcg_brand').setValue(new Date(detailshealthmonitoring.temperature_json.start_time_temperature));
      // this.immunizationForm.get('end_time_temperature').setValue(new Date(detailshealthmonitoring.temperature_json.end_time_temperature));


      // set basic details 

      this.heathMonitoringForm.controls.nurse_id.setValue(
        JSON.parse(detailshealthmonitoring.nurse_id)
      );

      this.heathMonitoringForm.controls.baby_details_id.setValue(JSON.parse(detailshealthmonitoring.baby_details_id));
      this.heathMonitoringForm.get('create_time')?.setValue(detailshealthmonitoring.create_time ? new Date(detailshealthmonitoring.create_time) : null);
      this.heathMonitoringForm.get('sick_or_not')?.setValue(JSON.stringify(detailshealthmonitoring.sick_or_not));
      if (detailshealthmonitoring.sick_or_not) {
        this.enableTemperatureControls();
        this.babysick = true;
      }

      // let babyId = localStorage.getItem('editedOrderId');

      // let data = this.babyDetails?.filter((res: any) => res?.id == babyId);

      // if (data) {
      //   
      //   this.calculateDoseDates(new Date(this.convertDateFormat(data[0].date_of_birth)));


      // }
      // Temperature monitoring start 
      const temperatureJson = detailshealthmonitoring.temperature_json;
      this.temperature_json.get('temp_freq').setValue(temperatureJson?.temp_freq);
      this.temperature_json.get('start_time_temperature')?.setValue(temperatureJson?.start_time_temperature ? new Date(temperatureJson.start_time_temperature) : null);
      this.temperature_json.get('end_time_temperature')?.setValue(temperatureJson?.end_time_temperature ? new Date(temperatureJson.end_time_temperature) : null);

      this.clearTempratureCalculation();
      for (let i = 0; i < temperatureJson.temprature_extra_value.length; i++) {
        this.isFeeding = true;
        const TempratureCalculationArray = this.getTempratureExtraValue();
        let temp = temperatureJson.temprature_extra_value[i] || {};

        const newTempratureCalculationGroup = this.fb.group({
          // temp_time: new FormControl(new Date(temp.temp_time)),
          temp_time: new FormControl(temp.temp_time ? new Date(temp.temp_time) : null),
          actual_temp_time: new FormControl(temp.actual_temp_time ? new Date(temp.actual_temp_time) : null),
          value: new FormControl(temp.value),
          temp_checkbox: new FormControl(temp.temp_checkbox),
          action: new FormControl(temp.action),
        });
        TempratureCalculationArray.push(newTempratureCalculationGroup);
      }

      // Vital signs start
      const vitalSignsJson = detailshealthmonitoring.vital_signs_json;
      this.vital_signs_json.get('pulse_vital').get('freq_pulse')?.setValue(vitalSignsJson?.pulse_vital?.freq_pulse);
      this.vital_signs_json.get('pulse_vital').get('start_time_pulse')?.setValue(vitalSignsJson?.pulse_vital?.start_time_pulse ? new Date(vitalSignsJson.pulse_vital.start_time_pulse) : null);
      this.vital_signs_json.get('pulse_vital').get('end_time_pulse')?.setValue(vitalSignsJson?.pulse_vital?.end_time_pulse ? new Date(vitalSignsJson.pulse_vital.end_time_pulse) : null);
      this.clearVitalSignCalculation();
      this.isFeedingVital = true;
      for (let i = 0; i < vitalSignsJson.pulse_vital.vital_extra_value.length; i++) {

        const vitalSignCalculationArray = this.getVitalSign();
        let temp = vitalSignsJson.pulse_vital.vital_extra_value[i] || {};

        const newVitalSignCalculationGroup = this.fb.group({
          // vital_time: new FormControl(new Date(temp.vital_time)),
          vital_time: new FormControl(temp.vital_time ? new Date(temp.vital_time) : null),
          // actual_vital_time: new FormControl(new Date(temp.actual_vital_time)),
          actual_vital_time: new FormControl(temp.actual_vital_time ? new Date(temp.actual_vital_time) : null),
          vital_value: new FormControl(temp.vital_value),
          vital_action: new FormControl(temp.vital_action),
          vital_checkbox: new FormControl(temp.vital_checkbox),
        });
        vitalSignCalculationArray.push(newVitalSignCalculationGroup);
      }

      this.vital_signs_json.get('spo2_vital').get('freq_spo2')?.setValue(vitalSignsJson?.spo2_vital?.freq_spo2);
      this.vital_signs_json.get('spo2_vital').get('start_time_spo2')?.setValue(vitalSignsJson?.spo2_vital?.start_time_spo2 ? new Date(vitalSignsJson.spo2_vital.start_time_spo2) : null);
      this.vital_signs_json.get('spo2_vital').get('end_time_spo2')?.setValue(vitalSignsJson?.spo2_vital?.end_time_spo2 ? new Date(vitalSignsJson.spo2_vital.end_time_spo2) : null);
      this.clearSpO2Calculation();
      this.isFeedingSpO2 = true;
      for (let i = 0; i < vitalSignsJson.spo2_vital.spo2_extra_value.length; i++) {
        const SpO2CalcutionArray = this.getSpO2ExtraValue();
        let temp = vitalSignsJson.spo2_vital.spo2_extra_value[i] || {};

        const newSpO2Calculation = this.fb.group({
          // spo2_time: new FormControl(new Date(temp.spo2_time)),
          spo2_time: new FormControl(temp.spo2_time ? new Date(temp.spo2_time) : null),
          actual_spo2_time: new FormControl(temp.actual_spo2_time ? new Date(temp.actual_spo2_time) : null),
          // actual_spo2_time: new FormControl(new Date(temp.actual_spo2_time)),
          spo2_checkbox: new FormControl(temp.spo2_checkbox),
          spo2_value: new FormControl(temp.spo2_value),
          spo2_action: new FormControl(temp.spo2_action),
        });
        SpO2CalcutionArray.push(newSpO2Calculation);
      }

      this.vital_signs_json.get('respiration_vital').get('freq_respiration')?.setValue(vitalSignsJson?.respiration_vital?.freq_respiration);
      this.vital_signs_json.get('respiration_vital').get('start_time_respiration')?.setValue(vitalSignsJson?.respiration_vital?.start_time_respiration ? new Date(vitalSignsJson.respiration_vital.start_time_respiration) : null);
      this.vital_signs_json.get('respiration_vital').get('end_time_respiration')?.setValue(vitalSignsJson?.respiration_vital?.end_time_respiration ? new Date(vitalSignsJson.respiration_vital.end_time_respiration) : null);
      this.vital_signs_json.get('respiration_vital').get('yes_no_respiration')?.setValue(vitalSignsJson?.respiration_vital?.yes_no_respiration);
      this.clearRespirationCalculation()
      this.isFeedingRespiration = true;
      for (let i = 0; i < vitalSignsJson.respiration_vital.respiration_extra_value.length; i++) {
        
        const respirationCalcutionArray = this.getRespirationExtraValue();
        let temp = vitalSignsJson.respiration_vital.respiration_extra_value[i] || {};

        
        const newRespirationCalculation = this.fb.group({
          // respiration_time: new FormControl(new Date(temp.respiration_time)),
          respiration_time: new FormControl(temp.respiration_time ? new Date(temp.respiration_time) : null),
          actual_respiration_time: new FormControl(temp.actual_respiration_time ? new Date(temp.actual_respiration_time) : null),
          // actual_respiration_time: new FormControl(new Date(temp.actual_respiration_time)),
          respiration_checkbox: new FormControl(temp.respiration_checkbox),
          respiration_value: new FormControl(temp.respiration_value),
          respiration_action: new FormControl(temp.respiration_action),
        });
        respirationCalcutionArray.push(newRespirationCalculation);
      }

      if (vitalSignsJson?.respiration_vital?.yes_no_respiration) {
        this.heathMonitoringForm.get('vital_signs_json.respiration_vital.freq_respiration').enable();
        this.heathMonitoringForm.get('vital_signs_json.respiration_vital.start_time_respiration').enable();
        this.heathMonitoringForm.get('vital_signs_json.respiration_vital.end_time_respiration').enable();
      }


      // Doctor recommendations
      detailshealthmonitoring.doc_recommendation_json?.forEach((recommendation, i) => {
        let docRecommendation = this.getdocRecommendations();
        let doc_recommendation_json = new FormGroup({
          remarks_doc: new FormControl(recommendation?.remarks_doc),
        });
        docRecommendation.insert(i, doc_recommendation_json);
      });

      // Medication 
      detailshealthmonitoring.medications_json?.forEach((medication, i) => {
        // this.medications_json.controls[i].get('medicians_name')?.setValue(medication?.medicians_name);
        // this.medications_json.controls[i].get('medicians_dosage')?.setValue(medication?.medicians_dosage);
        // this.medications_json.controls[i].get('medicians_freq')?.setValue(medication?.medicians_freq);
        // this.medications_json.controls[i].get('medicians_date')?.setValue(medication?.medicians_date ? new Date(medication.medicians_date) : null);
        // this.medications_json.controls[i].get('medicians_time')?.setValue(medication?.medicians_time ? new Date(medication.medicians_time) : null);
        // this.medications_json.controls[i].get('medicians_remarks')?.setValue(medication?.medicians_remarks);



        let medicationRecommendation = this.getMedicationRecommendation();

        let medications_json = new FormGroup({
          medicians_name: new FormControl(medication?.medicians_name),
          medicians_dosage: new FormControl(medication?.medicians_dosage),
          medicians_freq: new FormControl(medication?.medicians_freq),
          medicians_date: new FormControl(medication?.medicians_date ? new Date(medication.medicians_date) : null),
          medicians_time: new FormControl(medication?.medicians_time ? new Date(medication.medicians_time) : null),
          medicians_remarks: new FormControl(medication?.medicians_remarks),
        });
        medicationRecommendation.insert(i, medications_json)

      });

      // immunization form start

      this.immunizationForm.get('bcg_brand').setValue(detailshealthmonitoring.immunization_json.bcg_brand);
      this.immunizationForm.get('bcg_dose_date').setValue((detailshealthmonitoring.immunization_json.bcg_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.bcg_dose_date));
      this.immunizationForm.get('bcg_dose_given_date').setValue((detailshealthmonitoring.immunization_json.bcg_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.bcg_dose_given_date));
      this.immunizationForm.get('bcg_weight').setValue(detailshealthmonitoring.immunization_json.bcg_weight);
      this.immunizationForm.get('bcg_staff').setValue(detailshealthmonitoring.immunization_json.bcg_staff);
      this.immunizationForm.get('bcg_batchNumber').setValue(detailshealthmonitoring.immunization_json.bcg_batchNumber);
      this.immunizationForm.get('bcg_clinicname').setValue(detailshealthmonitoring.immunization_json.bcg_clinicname);
      
      this.immunizationForm.get('HepatitiesB_1stdose_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_brand);
      this.immunizationForm.get('HepatitiesB_1stdose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_date));
      this.immunizationForm.get('HepatitiesB_1stdose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_given_date));
      this.immunizationForm.get('HepatitiesB_1stdose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_weight);
      this.immunizationForm.get('HepatitiesB_1stdose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_staff);
      this.immunizationForm.get('HepatitiesB_1stdose_batchNumber').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_batchNumber);
      this.immunizationForm.get('HepatitiesB_1stdose_clinicname').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_1stdose_clinicname);

      this.immunizationForm.get('dtab_tpv_hib_1st_brand').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_brand);
      this.immunizationForm.get('dtab_tpv_hib_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_dose_date));
      this.immunizationForm.get('dtab_tpv_hib_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_dose_given_date));
      this.immunizationForm.get('dtab_tpv_hib_1st_weight').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_weight);
      this.immunizationForm.get('dtab_tpv_hib_1st_staff').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_staff);
      this.immunizationForm.get('dtab_tpv_hib_1st_batchNumber').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_batchNumber);
      this.immunizationForm.get('dtab_tpv_hib_1st_clinicname').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_1st_clinicname);

      this.immunizationForm.get('HepatitiesB_2nd_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_2nd_brand);
      this.immunizationForm.get('HepatitiesB_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_2nd_dose_date));
      this.immunizationForm.get('HepatitiesB_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_2nd_dose_given_date));
      this.immunizationForm.get('Hexavalent_1st_weight').setValue(detailshealthmonitoring.immunization_json.Hexavalent_1st_weight);
      this.immunizationForm.get('Hexavalent_1st_staff').setValue(detailshealthmonitoring.immunization_json.Hexavalent_1st_staff);
      this.immunizationForm.get('Hexavalent_1st_batchNumber').setValue(detailshealthmonitoring.immunization_json.Hexavalent_1st_batchNumber);
      this.immunizationForm.get('Hexavalent_1st_clinicname').setValue(detailshealthmonitoring.immunization_json.Hexavalent_1st_clinicname);

      this.immunizationForm.get('pcv_1stdose_brand').setValue(detailshealthmonitoring.immunization_json.pcv_1stdose_brand);
      this.immunizationForm.get('pcv_1stdose_date').setValue((detailshealthmonitoring.immunization_json.pcv_1stdose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_1stdose_date));
      this.immunizationForm.get('pcv_1stdose_given_date').setValue((detailshealthmonitoring.immunization_json.pcv_1stdose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_1stdose_given_date));
      this.immunizationForm.get('pcv_1stdose_weight').setValue(detailshealthmonitoring.immunization_json.pcv_1stdose_weight);
      this.immunizationForm.get('pcv_1stdose_staff').setValue(detailshealthmonitoring.immunization_json.pcv_1stdose_staff);
      this.immunizationForm.get('pcv_1stdose_batchNumber').setValue(detailshealthmonitoring.immunization_json.pcv_1stdose_batchNumber);
      this.immunizationForm.get('pcv_1stdose_clinicname').setValue(detailshealthmonitoring.immunization_json.pcv_1stdose_clinicname);

      this.immunizationForm.get('rotavirus_1stdose_brand').setValue(detailshealthmonitoring.immunization_json.rotavirus_1stdose_brand);
      this.immunizationForm.get('rotavirus_1stdose_date').setValue((detailshealthmonitoring.immunization_json.rotavirus_1stdose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotavirus_1stdose_date));
      this.immunizationForm.get('rotavirus_1stdose_given_date').setValue((detailshealthmonitoring.immunization_json.rotavirus_1stdose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotavirus_1stdose_given_date));
      this.immunizationForm.get('rotavirus_1stdose_weight').setValue(detailshealthmonitoring.immunization_json.rotavirus_1stdose_weight);
      this.immunizationForm.get('rotavirus_1stdose_staff').setValue(detailshealthmonitoring.immunization_json.rotavirus_1stdose_staff);
      this.immunizationForm.get('rotavirus_1stdose_batchNumber').setValue(detailshealthmonitoring.immunization_json.rotavirus_1stdose_batchNumber);
      this.immunizationForm.get('rotavirus_1stdose_clinicname').setValue(detailshealthmonitoring.immunization_json.rotavirus_1stdose_clinicname);

      this.immunizationForm.get('dtab_tpv_hib_2nd_brand').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_brand);
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_date));
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_given_date));
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_weight);
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_staff);
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_batchNumber);
      this.immunizationForm.get('dtab_tpv_hib_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_2nd_dose_clinicname);

      this.immunizationForm.get('HepatitiesB_3rd_dose_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_brand);
      this.immunizationForm.get('HepatitiesB_3rd_dose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_date));
      this.immunizationForm.get('HepatitiesB_3rd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_given_date));
      this.immunizationForm.get('HepatitiesB_3rd_dose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_weight);
      this.immunizationForm.get('HepatitiesB_3rd_dose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_staff);
      this.immunizationForm.get('HepatitiesB_3rd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_batchNumber);
      this.immunizationForm.get('HepatitiesB_3rd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_clinicname);

      this.immunizationForm.get('opv_1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_brand);
      this.immunizationForm.get('opv_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.opv_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_1st_dose_date));
      this.immunizationForm.get('opv_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.opv_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_1st_dose_given_date));
      this.immunizationForm.get('opv_1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_weight);
      this.immunizationForm.get('opv_1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_staff);
      this.immunizationForm.get('opv_1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_batchNumber);
      this.immunizationForm.get('opv_1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_clinicname);

      this.immunizationForm.get('pcv_2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.pcv_2nd_dose_brand);
      this.immunizationForm.get('pcv_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.pcv_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_2nd_dose_date));
      this.immunizationForm.get('pcv_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.pcv_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_2nd_dose_given_date));
      this.immunizationForm.get('pcv_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_weight);
      this.immunizationForm.get('pcv_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_3rd_dose_staff);
      this.immunizationForm.get('pcv_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.pcv_2nd_dose_batchNumber);
      this.immunizationForm.get('pcv_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.pcv_2nd_dose_clinicname);

      this.immunizationForm.get('rotavirus_2nddose_brand').setValue(detailshealthmonitoring.immunization_json.rotavirus_2nddose_brand);
      this.immunizationForm.get('rotavirus_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_date));
      this.immunizationForm.get('rotavirus_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_given_date));
      this.immunizationForm.get('rotavirus_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_weight);
      this.immunizationForm.get('rotavirus_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_staff);
      this.immunizationForm.get('rotavirus_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_batchNumber);
      this.immunizationForm.get('rotavirus_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.rotavirus_2nd_dose_clinicname);

      this.immunizationForm.get('opv_1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_brand);
      this.immunizationForm.get('opv_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.opv_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_1st_dose_date));
      this.immunizationForm.get('opv_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.opv_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_1st_dose_given_date));
      this.immunizationForm.get('opv_1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_weight);
      this.immunizationForm.get('opv_1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_staff);
      this.immunizationForm.get('opv_1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_batchNumber);
      this.immunizationForm.get('opv_1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.opv_1st_dose_clinicname);

      this.immunizationForm.get('dtab_tpv_hib_3rd_brand').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_brand);
      this.immunizationForm.get('dtab_tpv_hib_3rd_dose_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_dose_date));
      this.immunizationForm.get('dtab_tpv_hib_3rd_given_date').setValue((detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_given_date));
      this.immunizationForm.get('dtab_tpv_hib_3rd_weight').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_weight);
      this.immunizationForm.get('dtab_tpv_hib_3rd_staff').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_staff);
      this.immunizationForm.get('dtab_tpv_hib_3rd_batchNumber').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_batchNumber);
      this.immunizationForm.get('dtab_tpv_hib_3rd_clinicname').setValue(detailshealthmonitoring.immunization_json.dtab_tpv_hib_3rd_clinicname);

      this.immunizationForm.get('HepatitiesB_4th_dose_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_brand);
      this.immunizationForm.get('HepatitiesB_4th_dose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_date));
      this.immunizationForm.get('HepatitiesB_4th_dose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_given_date));
      this.immunizationForm.get('HepatitiesB_4th_dose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_weight);
      this.immunizationForm.get('HepatitiesB_4th_dose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_staff);
      this.immunizationForm.get('HepatitiesB_4th_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_batchNumber);
      this.immunizationForm.get('HepatitiesB_4th_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.HepatitiesB_4th_dose_clinicname);

      this.immunizationForm.get('rotateqx3_dose_brand').setValue(detailshealthmonitoring.immunization_json.rotateqx3_dose_brand);
      this.immunizationForm.get('rotateqx3_dose_date').setValue((detailshealthmonitoring.immunization_json.rotateqx3_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotateqx3_dose_date));
      this.immunizationForm.get('rotateqx3_dose_given_date').setValue((detailshealthmonitoring.immunization_json.rotateqx3_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.rotateqx3_dose_given_date));
      this.immunizationForm.get('rotateqx3_dose_weight').setValue(detailshealthmonitoring.immunization_json.rotateqx3_dose_weight);
      this.immunizationForm.get('rotateqx3_dose_staff').setValue(detailshealthmonitoring.immunization_json.rotateqx3_dose_staff);
      this.immunizationForm.get('rotateqx3_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.rotateqx3_dose_batchNumber);
      this.immunizationForm.get('rotateqx3_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.rotateqx3_dose_clinicname);

      this.immunizationForm.get('opv_2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.opv_2nd_dose_brand);
      this.immunizationForm.get('opv_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.opv_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_2nd_dose_date));
      this.immunizationForm.get('opv_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.opv_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_2nd_dose_given_date));
      this.immunizationForm.get('opv_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.opv_2nd_dose_weight);
      this.immunizationForm.get('opv_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.opv_2nd_dose_staff);
      this.immunizationForm.get('opv_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.opv_2nd_dose_batchNumber);
      this.immunizationForm.get('opv_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.opv_2nd_dose_clinicname);

      this.immunizationForm.get('pcv_3rd_dose_brand').setValue(detailshealthmonitoring.immunization_json.pcv_3rd_dose_brand);
      this.immunizationForm.get('pcv_3rd_dose_date').setValue((detailshealthmonitoring.immunization_json.pcv_3rd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_3rd_dose_date));
      this.immunizationForm.get('pcv_3rd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.pcv_3rd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_3rd_dose_given_date));
      this.immunizationForm.get('pcv_3rd_dose_weight').setValue(detailshealthmonitoring.immunization_json.pcv_3rd_dose_weight);
      this.immunizationForm.get('pcv_3rd_dose_staff').setValue(detailshealthmonitoring.immunization_json.pcv_3rd_dose_staff);
      this.immunizationForm.get('pcv_3rd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.pcv_3rd_dose_batchNumber);
      this.immunizationForm.get('pcv_3rd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.pcv_3rd_dose_clinicname);

      this.immunizationForm.get('mmr1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.mmr1st_dose_brand);
      this.immunizationForm.get('mmr1st_dose_date').setValue((detailshealthmonitoring.immunization_json.mmr1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.mmr1st_dose_date));
      this.immunizationForm.get('mmr1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.mmr1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.mmr1st_dose_given_date));
      this.immunizationForm.get('mmr1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.mmr1st_dose_weight);
      this.immunizationForm.get('mmr1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.mmr1st_dose_staff);
      this.immunizationForm.get('mmr1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.mmr1st_dose_batchNumber);
      this.immunizationForm.get('mmr1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.mmr1st_dose_clinicname);

      this.immunizationForm.get('chickenPox_1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_brand);
      this.immunizationForm.get('chickenPox_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.chickenPox_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_date));
      this.immunizationForm.get('chickenPox_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.chickenPox_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_given_date));
      this.immunizationForm.get('chickenPox_1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_weight);
      this.immunizationForm.get('chickenPox_1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_staff);
      this.immunizationForm.get('chickenPox_1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_batchNumber);
      this.immunizationForm.get('chickenPox_1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.chickenPox_1st_dose_clinicname);

      this.immunizationForm.get('pcv_4th_dose_brand').setValue(detailshealthmonitoring.immunization_json.pcv_4th_dose_brand);
      this.immunizationForm.get('pcv_4th_dose_date').setValue((detailshealthmonitoring.immunization_json.pcv_4th_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_4th_dose_date));
      this.immunizationForm.get('pcv_4th_dose_given_date').setValue((detailshealthmonitoring.immunization_json.pcv_4th_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.pcv_4th_dose_given_date));
      this.immunizationForm.get('pcv_4th_dose_weight').setValue(detailshealthmonitoring.immunization_json.pcv_4th_dose_weight);
      this.immunizationForm.get('pcv_4th_dose_staff').setValue(detailshealthmonitoring.immunization_json.pcv_4th_dose_staff);
      this.immunizationForm.get('pcv_4th_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.pcv_4th_dose_batchNumber);
      this.immunizationForm.get('pcv_4th_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.pcv_4th_dose_clinicname);

      this.immunizationForm.get('tetravalent_dpt_hiv_dose_brand').setValue(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_brand);
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_date').setValue((detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_date));
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_given_date').setValue((detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_given_date));
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_weight').setValue(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_weight);
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_staff').setValue(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_staff);
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_batchNumber);
      this.immunizationForm.get('tetravalent_dpt_hiv_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.tetravalent_dpt_hiv_dose_clinicname);

      this.immunizationForm.get('dpt_dose_brand').setValue(detailshealthmonitoring.immunization_json.dpt_dose_brand);
      this.immunizationForm.get('dpt_dose_date').setValue((detailshealthmonitoring.immunization_json.dpt_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dpt_dose_date));
      this.immunizationForm.get('dpt_dose_given_date').setValue((detailshealthmonitoring.immunization_json.mmr1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.dpt_dose_given_date));
      this.immunizationForm.get('dpt_dose_weight').setValue(detailshealthmonitoring.immunization_json.dpt_dose_weight);
      this.immunizationForm.get('dpt_dose_staff').setValue(detailshealthmonitoring.immunization_json.dpt_dose_staff);
      this.immunizationForm.get('dpt_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.dpt_dose_batchNumber);
      this.immunizationForm.get('dpt_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.dpt_dose_clinicname);

      this.immunizationForm.get('opv_3rd_dose_brand').setValue(detailshealthmonitoring.immunization_json.opv_3rd_dose_brand);
      this.immunizationForm.get('opv_3rd_dose_date').setValue((detailshealthmonitoring.immunization_json.opv_3rd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_3rd_dose_brand));
      this.immunizationForm.get('opv_3rd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.opv_3rd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_3rd_dose_given_date));
      this.immunizationForm.get('opv_3rd_dose_weight').setValue(detailshealthmonitoring.immunization_json.opv_3rd_dose_weight);
      this.immunizationForm.get('opv_3rd_dose_staff').setValue(detailshealthmonitoring.immunization_json.opv_3rd_dose_staff);
      this.immunizationForm.get('opv_3rd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.opv_3rd_dose_batchNumber);
      this.immunizationForm.get('opv_3rd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.opv_3rd_dose_clinicname);

      this.immunizationForm.get('opv_4th_dose_brand').setValue(detailshealthmonitoring.immunization_json.opv_4th_dose_brand);
      this.immunizationForm.get('opv_4th_dose_date').setValue((detailshealthmonitoring.immunization_json.opv_4th_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_4th_dose_date));
      this.immunizationForm.get('opv_4th_dose_given_date').setValue((detailshealthmonitoring.immunization_json.opv_4th_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.opv_4th_dose_given_date));
      this.immunizationForm.get('opv_4th_dose_weight').setValue(detailshealthmonitoring.immunization_json.opv_4th_dose_weight);
      this.immunizationForm.get('opv_4th_dose_staff').setValue(detailshealthmonitoring.immunization_json.opv_4th_dose_staff);
      this.immunizationForm.get('opv_4th_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.opv_4th_dose_batchNumber);
      this.immunizationForm.get('opv_4th_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.opv_4th_dose_clinicname);

      this.immunizationForm.get('mmr2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.mmr2nd_dose_brand);
      this.immunizationForm.get('mmr2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.mmr2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.mmr2nd_dose_date));
      this.immunizationForm.get('mmr2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.mmr2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.mmr2nd_dose_given_date));
      this.immunizationForm.get('mmr2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.mmr2nd_dose_weight);
      this.immunizationForm.get('mmr2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.mmr2nd_dose_staff);
      this.immunizationForm.get('mmr2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.mmr2nd_dose_batchNumber);
      this.immunizationForm.get('mmr2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.mmr2nd_dose_clinicname);

      this.immunizationForm.get('chickenPox_2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_brand);
      this.immunizationForm.get('chickenPox_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_date));
      this.immunizationForm.get('chickenPox_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_given_date));
      this.immunizationForm.get('chickenPox_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_weight);
      this.immunizationForm.get('chickenPox_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_staff);
      this.immunizationForm.get('chickenPox_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_batchNumber);
      this.immunizationForm.get('chickenPox_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.chickenPox_2nd_dose_clinicname);

      // 2nd table 
      this.immunizationForm.get('influenza_dose_brand').setValue(detailshealthmonitoring.immunization_json.influenza_dose_brand);
      this.immunizationForm.get('influenza_dose_date').setValue((detailshealthmonitoring.immunization_json.influenza_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.influenza_dose_date));
      this.immunizationForm.get('influenza_dose_given_date').setValue((detailshealthmonitoring.immunization_json.influenza_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.influenza_dose_given_date));
      this.immunizationForm.get('influenza_dose_weight').setValue(detailshealthmonitoring.immunization_json.influenza_dose_weight);
      this.immunizationForm.get('influenza_dose_staff').setValue(detailshealthmonitoring.immunization_json.influenza_dose_staff);
      this.immunizationForm.get('influenza_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.influenza_dose_batchNumber);
      this.immunizationForm.get('influenza_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.influenza_dose_clinicname);

      this.immunizationForm.get('HepatitiesA_1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_brand);
      this.immunizationForm.get('HepatitiesA_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_date));
      this.immunizationForm.get('HepatitiesA_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_given_date));
      this.immunizationForm.get('HepatitiesA_1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_weight);
      this.immunizationForm.get('HepatitiesA_1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_staff);
      this.immunizationForm.get('HepatitiesA_1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_batchNumber);
      this.immunizationForm.get('HepatitiesA_1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_1st_dose_clinicname);

      this.immunizationForm.get('HepatitiesA_2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_brand);
      this.immunizationForm.get('HepatitiesA_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_date));
      this.immunizationForm.get('HepatitiesA_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_given_date));
      this.immunizationForm.get('HepatitiesA_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_weight);
      this.immunizationForm.get('HepatitiesA_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_staff);
      this.immunizationForm.get('HepatitiesA_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_batchNumber);
      this.immunizationForm.get('HepatitiesA_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.HepatitiesA_2nd_dose_clinicname);

      this.immunizationForm.get('meningococcal_dose_brand').setValue(detailshealthmonitoring.immunization_json.meningococcal_dose_brand);
      this.immunizationForm.get('meningococcal_dose_date').setValue((detailshealthmonitoring.immunization_json.meningococcal_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.meningococcal_dose_date));
      this.immunizationForm.get('meningococcal_dose_given_date').setValue((detailshealthmonitoring.immunization_json.meningococcal_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.meningococcal_dose_given_date));
      this.immunizationForm.get('meningococcal_dose_weight').setValue(detailshealthmonitoring.immunization_json.meningococcal_dose_weight);
      this.immunizationForm.get('meningococcal_dose_staff').setValue(detailshealthmonitoring.immunization_json.meningococcal_dose_staff);
      this.immunizationForm.get('meningococcal_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.meningococcal_dose_batchNumber);
      this.immunizationForm.get('meningococcal_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.meningococcal_dose_clinicname);

      this.immunizationForm.get('typhoid_dose_brand').setValue(detailshealthmonitoring.immunization_json.typhoid_dose_brand);
      this.immunizationForm.get('typhoid_dose_date').setValue((detailshealthmonitoring.immunization_json.typhoid_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.typhoid_dose_date));
      this.immunizationForm.get('typhoid_dose_given_date').setValue((detailshealthmonitoring.immunization_json.typhoid_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.typhoid_dose_given_date));
      this.immunizationForm.get('typhoid_dose_weight').setValue(detailshealthmonitoring.immunization_json.typhoid_dose_weight);
      this.immunizationForm.get('typhoid_dose_staff').setValue(detailshealthmonitoring.immunization_json.typhoid_dose_staff);
      this.immunizationForm.get('typhoid_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.typhoid_dose_batchNumber);
      this.immunizationForm.get('typhoid__dose_clinicname').setValue(detailshealthmonitoring.immunization_json.typhoid__dose_clinicname);

      this.immunizationForm.get('hpv_1st_dose_brand').setValue(detailshealthmonitoring.immunization_json.hpv_1st_dose_brand);
      this.immunizationForm.get('hpv_1st_dose_date').setValue((detailshealthmonitoring.immunization_json.hpv_1st_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_1st_dose_date));
      this.immunizationForm.get('hpv_1st_dose_given_date').setValue((detailshealthmonitoring.immunization_json.hpv_1st_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_1st_dose_given_date));
      this.immunizationForm.get('hpv_1st_dose_weight').setValue(detailshealthmonitoring.immunization_json.hpv_1st_dose_weight);
      this.immunizationForm.get('hpv_1st_dose_staff').setValue(detailshealthmonitoring.immunization_json.hpv_1st_dose_staff);
      this.immunizationForm.get('hpv_1st_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.hpv_1st_dose_batchNumber);
      this.immunizationForm.get('hpv_1st_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.hpv_1st_dose_clinicname);

      this.immunizationForm.get('hpv_2nd_dose_brand').setValue(detailshealthmonitoring.immunization_json.hpv_2nd_dose_brand);
      this.immunizationForm.get('hpv_2nd_dose_date').setValue((detailshealthmonitoring.immunization_json.hpv_2nd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_2nd_dose_date));
      this.immunizationForm.get('hpv_2nd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.hpv_2nd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_2nd_dose_given_date));
      this.immunizationForm.get('hpv_2nd_dose_weight').setValue(detailshealthmonitoring.immunization_json.hpv_2nd_dose_weight);
      this.immunizationForm.get('hpv_2nd_dose_staff').setValue(detailshealthmonitoring.immunization_json.hpv_2nd_dose_staff);
      this.immunizationForm.get('hpv_2nd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.hpv_2nd_dose_batchNumber);
      this.immunizationForm.get('hpv_2nd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.hpv_2nd_dose_clinicname);

      this.immunizationForm.get('hpv_3rd_dose_brand').setValue(detailshealthmonitoring.immunization_json.hpv_3rd_dose_brand);
      this.immunizationForm.get('hpv_3rd_dose_date').setValue((detailshealthmonitoring.immunization_json.hpv_3rd_dose_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_3rd_dose_date));
      this.immunizationForm.get('hpv_3rd_dose_given_date').setValue((detailshealthmonitoring.immunization_json.hpv_3rd_dose_given_date == null) ? null : new Date(detailshealthmonitoring.immunization_json.hpv_3rd_dose_given_date));
      this.immunizationForm.get('hpv_3rd_dose_weight').setValue(detailshealthmonitoring.immunization_json.hpv_3rd_dose_weight);
      this.immunizationForm.get('hpv_3rd_dose_staff').setValue(detailshealthmonitoring.immunization_json.hpv_3rd_dose_staff);
      this.immunizationForm.get('hpv_3rd_dose_batchNumber').setValue(detailshealthmonitoring.immunization_json.hpv_3rd_dose_batchNumber);
      this.immunizationForm.get('hpv_3rd_dose_clinicname').setValue(detailshealthmonitoring.immunization_json.hpv_3rd_dose_clinicname);

      // immunization form end

      this.heathMonitoringForm.get('remarks').setValue(detailshealthmonitoring.remarks);
      this.disableForm()
      // this.heathMonitoringForm.controls.end_time_temperature.setValue(new Date(detailshealthmonitoring.temperature_json.end_time_temperature))
    }, (error: HttpErrorResponse) => {
      this.spinner.hide()
    
    })
  }

  //
  //
  // it used for take input only in number
  keyPress(event: KeyboardEvent) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }
  // this is used for checked it is empty or not
  isset(variable) {
    return typeof variable !== 'undefined' && variable !== null;
  }
  private parseISOString(s: string): Date | null {
    if (!s) return null;
    return new Date(s);
  }
  ////////
  // Add Functionality 
  ////
  getTempratureExtraValue(): FormArray {
    return this.heathMonitoringForm.get('temperature_json.temprature_extra_value') as FormArray;
  }
  clearTempratureCalculation() {
    (this.heathMonitoringForm.get('temperature_json.temprature_extra_value') as FormArray).clear();
  }
  addTempratureCalculation(time): void {
    const TempratureCalculationArray = this.getTempratureExtraValue();

    const newTempratureCalculationGroup = this.fb.group({
      temp_time: new FormControl(time),
      actual_temp_time: new FormControl(null),
      temp_checkbox: new FormControl(null),
      value: new FormControl(null),
      action: new FormControl(null),
    });

    TempratureCalculationArray.push(newTempratureCalculationGroup);

  }
  editAutoMaticTimeFill(event: Event) {
    event.preventDefault();
    let temperatureJsonFormGroup = this.heathMonitoringForm.get('temperature_json') as FormGroup;

    // Accessing the temperature_json control within the nested form group
    let temFrequency = temperatureJsonFormGroup.get('temp_freq').value;
    let startTimeTemperatureControl = new Date(temperatureJsonFormGroup.get('start_time_temperature').value);
    let endimeTemperatureControl = new Date(temperatureJsonFormGroup.get('end_time_temperature').value);
    if (this.isset(temFrequency) && this.isset(startTimeTemperatureControl) && this.isset(endimeTemperatureControl)) {
      this.clearTempratureCalculation();
      let update = startTimeTemperatureControl;
      while (update < endimeTemperatureControl) {
        if (update < endimeTemperatureControl) {
          let time = new Date();
          time.setHours(update.getHours())
          time.setMinutes(update.getMinutes())
          this.addTempratureCalculation(time);
        }
        update.setHours(update.getHours() + temFrequency)
      }
      this.isFeeding = true;
    }
  }
  // /
  // 
  // add functionality for vital sign
  // 
  getVitalSign(): FormArray {
    return (this.heathMonitoringForm.get('vital_signs_json.pulse_vital') as FormGroup).get('vital_extra_value') as FormArray;
  }
  clearVitalSignCalculation() {
    ((this.heathMonitoringForm.get('vital_signs_json.pulse_vital') as FormGroup).get('vital_extra_value') as FormArray).clear();
    // (this.heathMonitoringForm.get('vital_signs_json.pulse_vital.vital_sign_extra_value') as FormArray).clear();
  }
  addVitalSignCalculation(time): void {
    const vitalSignCalculationArray = this.getVitalSign();
    const newVitalSignCalculationGroup = this.fb.group({
      vital_time: new FormControl(time),
      actual_vital_time: new FormControl(null),
      vital_checkbox: new FormControl(null),
      vital_value: new FormControl(null),
      vital_action: new FormControl(null),
    });
    vitalSignCalculationArray.push(newVitalSignCalculationGroup);
  }
  editAutoMaticVitalSignFill(event: Event) {
    event.preventDefault();
    let vitalSignJsonFormGroup1 = this.heathMonitoringForm.get('vital_signs_json') as FormGroup;
    let vitalSignJsonFormGroup = vitalSignJsonFormGroup1.get('pulse_vital') as FormGroup;
    let vitaltemp = vitalSignJsonFormGroup.get('freq_pulse').value;
    let startTimeVitalSignControl = new Date(vitalSignJsonFormGroup.get('start_time_pulse').value);
    let endimeVitalSignControl = new Date(vitalSignJsonFormGroup.get('end_time_pulse').value);
    if (this.isset(vitaltemp) && this.isset(startTimeVitalSignControl) && this.isset(endimeVitalSignControl)) {
      this.clearVitalSignCalculation();
      let update = startTimeVitalSignControl;
      while (update < endimeVitalSignControl) {
        if (update < endimeVitalSignControl) {
          let time = new Date();
          time.setHours(update.getHours())
          time.setMinutes(update.getMinutes())
          this.addVitalSignCalculation(time);
        }
        update.setHours(update.getHours() + vitaltemp)
      }
      this.isFeedingVital = true;
    }
  }
  // 
  //
  // add functionality for SpO2 frequency
  // 
  // 
  getSpO2ExtraValue(): FormArray {
    return (this.heathMonitoringForm.get('vital_signs_json.spo2_vital') as FormGroup).get('spo2_extra_value') as FormArray;
  }
  clearSpO2Calculation() {
    ((this.heathMonitoringForm.get('vital_signs_json.spo2_vital') as FormGroup).get('spo2_extra_value') as FormArray).clear();
  }
  addSpO2Calculation(time): void {
    
    const SpO2CalcutionArray = this.getSpO2ExtraValue();
    const newSpO2Calculation = this.fb.group({
      spo2_time: new FormControl(time),
      actual_spo2_time: new FormControl(null),
      spo2_checkbox: new FormControl(null),
      spo2_value: new FormControl(null),
      spo2_action: new FormControl(null),
    });
    SpO2CalcutionArray.push(newSpO2Calculation);
  }
  editSpO2(event: Event) {
    event.preventDefault();
    let SpO2JsonFormGroup1 = this.heathMonitoringForm.get('vital_signs_json') as FormGroup;
    let SpO2JsonFormGroup = SpO2JsonFormGroup1.get('spo2_vital') as FormGroup;
    let SpO2temp = SpO2JsonFormGroup.get('freq_spo2').value;
    let startTimeSpO2Control = new Date(SpO2JsonFormGroup.get('start_time_spo2').value);
    let endimeSpO2Control = new Date(SpO2JsonFormGroup.get('end_time_spo2').value);
    if (this.isset(SpO2temp) && this.isset(startTimeSpO2Control) && this.isset(endimeSpO2Control)) {
      this.clearSpO2Calculation();
      let update = startTimeSpO2Control;
      while (update < endimeSpO2Control) {
        if (update < endimeSpO2Control) {
          let time = new Date();
          time.setHours(update.getHours())
          time.setMinutes(update.getMinutes())
          this.addSpO2Calculation(time);
        }
        update.setHours(update.getHours() + SpO2temp)
      }
      this.isFeedingSpO2 = true;
    }
  }
  // 
  //
  // add functionality for resspiration
  // 
  // 
  getRespirationExtraValue(): FormArray {
    return (this.heathMonitoringForm.get('vital_signs_json.respiration_vital') as FormGroup).get('respiration_extra_value') as FormArray;
  }
  clearRespirationCalculation() {
    ((this.heathMonitoringForm.get('vital_signs_json.respiration_vital') as FormGroup).get('respiration_extra_value') as FormArray).clear();
  }
  addRespirationCalculation(time): void {
    
    const respirationCalcutionArray = this.getRespirationExtraValue();
    const newRespirationCalculation = this.fb.group({
      respiration_time: new FormControl(time),
      actual_respiration_time: new FormControl(null),
      respiration_checkbox: new FormControl(null),
      respiration_value: new FormControl(null),
      respiration_action: new FormControl(null),
    });
    respirationCalcutionArray.push(newRespirationCalculation);
  }
  editRespiration(event: Event) {
    event.preventDefault();
    let respirationFormGroup1 = this.heathMonitoringForm.get('vital_signs_json') as FormGroup;
    let respirationFormGroup = respirationFormGroup1.get('respiration_vital') as FormGroup;
    let respirationFrequency = respirationFormGroup.get('freq_respiration').value;
    let startTimeRespirationControl = new Date(respirationFormGroup.get('start_time_respiration').value);
    let endTimeRespirationControl = new Date(respirationFormGroup.get('end_time_respiration').value);
    if (this.isset(respirationFrequency) && this.isset(startTimeRespirationControl) && this.isset(endTimeRespirationControl)) {
      this.clearRespirationCalculation();
      let update = startTimeRespirationControl;
      while (update < endTimeRespirationControl) {
       
        if (update < endTimeRespirationControl) {
          let time = new Date();
          time.setHours(update.getHours())
          time.setMinutes(update.getMinutes())
          this.addRespirationCalculation(time);
        }
        update.setHours(update.getHours() + respirationFrequency)
      }
      this.isFeedingRespiration = true;
    }
  }
  // 
  // get api of health monitoring
  // 
  getHealthMonitoring() {
    this.spinner.show()
    this.commanServices.getHealthMonitoring().then(
      (res: any) => {
        this.spinner.hide()
        this.getHealthMonitoring = res?.data;
      },
      (error: HttpErrorResponse) => {
        this.spinner.hide()
      
      }
    );

    setTimeout(() => {
      this.disableForm()
    }, 1000);

  }

  disableForm() {
    const a = this.heathMonitoringForm.value
    var disable = a.immunization
    let filteredObject = {};
    for (let key in disable) {
      if (disable[key] != null && disable[key] != '') {
        filteredObject[key] = disable[key];
        const control = this.heathMonitoringForm.get('immunization.' + key);

        if (control) {
          control.disable();
        }
      }
      else {
      }

    }
  }
  convertDateFormat(inputDate) {
    // Split the input date into parts using '/'
    const parts = inputDate.split('/');

    // Rearrange the parts in the desired order: mm, dd, yyyy
    const formattedDate = `${parts[1]}/${parts[0]}/${parts[2]}`;
    return formattedDate;
  }
  // babay details
  getBabyDetails(event: any) {
    let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);

    this.calculateDoseDates(new Date(this.convertDateFormat(data[0].date_of_birth)));
    let firstLetter = data[0]?.gender.toLowerCase();
    // let capitalize =
    //   firstLetter.slice(0, 1).toUpperCase() +
    //   firstLetter.slice(1, firstLetter.length);
  }

  getBabyDetailsInNew(event: any) {
    // let data = this.babyDetails?.filter((res: any) => res?.id == event?.value);
    let data

    const babyId = localStorage.getItem('babyId');
    this.spinner.show()
    this.initial_form_service
      .getInitialAssessmentForm(babyId)
      .then((res: any) => {
        this.spinner.hide()
        data = res.data[0];
        this.nurseName = data?.nurse_name
        
        this.heathMonitoringForm.controls.nurse_id.setValue(
          JSON.parse(data.associated_nurse_id)
        );
      }, (error: any) => {
        this.spinner.hide()

        // Handle any errors that occurred during the API call
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error?.message,
        });
        // this.spinner.hide();
      }
      );

  }

  // Soft Reloading
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  ///////////////////////////////////




  // add functionality
  // add functionality for doctor recommendation
  getdocRecommendations(): FormArray {
    return this.heathMonitoringForm.get('doc_recommendation_json') as FormArray;
  }
  addDocRecommendation(string: any, index: number): void {
    let docRecommendation = this.getdocRecommendations();
    if (string == 'add') {
      let doc_recommendation_json = new FormGroup({
        remarks_doc: new FormControl(''),
      });
      docRecommendation.insert(index + 1, doc_recommendation_json);
    } else {
      docRecommendation.removeAt(index);
    }
  }
  // add functionality for medication
  getMedicationRecommendation(): FormArray {
    return this.heathMonitoringForm.get('medications_json') as FormArray;
  }
  addMedication(string: any, index: number): void {
    let medicationRecommendation = this.getMedicationRecommendation();
    if (string == 'add') {
      let medications_json = new FormGroup({
        medicians_name: new FormControl(''),
        medicians_dosage: new FormControl(''),
        medicians_freq: new FormControl(null),
        medicians_date: new FormControl(''),
        medicians_time: new FormControl(''),
        medicians_remarks: new FormControl(''),
      });
      medicationRecommendation.insert(index + 1, medications_json)
    } else {
      medicationRecommendation.removeAt(index);
    }
  }
  // this is date and time formate function
  // 
  formatDate(date) {
    if (!date) {
      return null; // or handle accordingly based on your requirements
    }

    // Check if date is already a Date object
    if (date instanceof Date) {
      return date.toISOString().replace("T", " ").replace(/\.\d+Z/, "");
    }

    // Try to parse the date string
    const parsedDate = new Date(date);

    // Check if parsing was successful and it is a valid Date object
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toISOString().replace("T", " ").replace(/\.\d+Z/, "");
    } else {
      return date; // Return original value if parsing fails
    }
  }

  // Submit function on submit 
  submitFormHealthMonitoring(healthMonitoring) {
    this.heathMonitoringForm.enable();
    // const previousData = this.healthData?.immunization_json
    let data = JSON.parse(JSON.stringify(this.heathMonitoringForm.value))

    const newData = data.immunization
    // const mergedObject = { ...newData };

    // if (previousData) {
    //   for (const [key, value] of Object.entries(previousData)) {
    //     if (!mergedObject.hasOwnProperty(key) ||
    //       (value !== null && value !== undefined && value !== '' && (typeof value === 'string' && value.trim() !== ''))) {
    //       mergedObject[key] = value;
    //     }
    //   }
    // }

    // return 0;  

    let payload = {
      "baby_details_id": data.baby_details_id,
      "create_time": this.formatDate(data.create_time),
      "nurse_id": data.nurse_id,
      // "created_by_user_id":data.created_by_user_id,
      "doc_recommendation_json": data.doc_recommendation_json,
      "medications_json": data.medications_json,
      "remarks": data.remarks,
       "sick_or_not": parseInt(data.sick_or_not, 10),
      "temperature_json": data.temperature_json,
      "update_time": data.update_time,
      "vital_signs_json": data.vital_signs_json,
      "immunization_json": data.immunization,
    }

    Object.keys(this.heathMonitoringForm.controls).forEach((key) => { });
    window.scrollTo(0, 0);
    if (healthMonitoring.valid) {
      if (this.isedit) {
        const id = localStorage.getItem('editedOrderId');
        this.spinner.show()

        this.commanServices.putHealthMonitoring(payload, id).then((res: any) => {
          this.spinner.hide()
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res?.message,
          })
          setTimeout(() => {
            this.router.navigateByUrl('/portal/first-screen')

          }, 1000);

          this.isedit = false;
        },
          (error: any) => {
            this.spinner.hide();
            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        )
      }
      else {
        this.commanServices.postHealthMonitoring(payload).then((res: any) => {
          this.spinner.hide()
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: res?.message,
          })
          setTimeout(() => {
            this.router.navigateByUrl('/portal/first-screen')
          }, 400);
        },

          (error: any) => {
            this.spinner.hide()
            // Handle any errors that occurred during the API call
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error?.message,
            });
          }
        )
      }
    }
    else {
      this.spinner.hide()
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please Fill All the Details!',
      });
    }
  }


  logout() {
    localStorage.clear();
    // window.location.reload();
    this.router.navigate(['/']);
  }

}

