import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
//IMPORT SPINNER MODULE
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//import accordian
import { AccordionModule } from 'primeng/accordion';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { AppDownloadComponent } from './components/common/app-download/app-download.component';
import { HowItWorksComponent } from './components/common/how-it-works/how-it-works.component';
import { HomeoneBlogComponent } from './components/pages/home-demo-one/homeone-blog/homeone-blog.component';
import { FeedbackStyleOneComponent } from './components/common/feedback-style-one/feedback-style-one.component';
import { HomeoneDestinationsComponent } from './components/pages/home-demo-one/homeone-destinations/homeone-destinations.component';
import { CategoryComponent } from './components/common/category/category.component';
import { HomeoneListingsComponent } from './components/pages/home-demo-one/homeone-listings/homeone-listings.component';
import { FeaturesComponent } from './components/common/features/features.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { HometwoListingsComponent } from './components/pages/home-demo-two/hometwo-listings/hometwo-listings.component';
import { HometwoDestinationsComponent } from './components/pages/home-demo-two/hometwo-destinations/hometwo-destinations.component';
import { HometwoEventsComponent } from './components/pages/home-demo-two/hometwo-events/hometwo-events.component';
import { HometwoBlogComponent } from './components/pages/home-demo-two/hometwo-blog/hometwo-blog.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { BlogGridComponent } from './components/pages/blog-grid/blog-grid.component';
import { BlogRightSidebarComponent } from './components/pages/blog-right-sidebar/blog-right-sidebar.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ProductsListComponent } from './components/pages/products-list/products-list.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { RelatedProductsComponent } from './components/pages/products-details/related-products/related-products.component';
import { AuthorProfileComponent } from './components/pages/author-profile/author-profile.component';
import { CategoriesComponent } from './components/pages/categories/categories.component';
import { TopPlaceComponent } from './components/pages/top-place/top-place.component';
import { ListingsDetailsComponent } from './components/pages/listings-details/listings-details.component';
import { EventsDetailsComponent } from './components/pages/events-details/events-details.component';
import { EventsComponent } from './components/pages/events/events.component';
import { VerticalListingsLeftSidebarComponent } from './components/pages/vertical-listings-left-sidebar/vertical-listings-left-sidebar.component';
import { VerticalListingsRightSidebarComponent } from './components/pages/vertical-listings-right-sidebar/vertical-listings-right-sidebar.component';
import { VerticalListingsFullWidthComponent } from './components/pages/vertical-listings-full-width/vertical-listings-full-width.component';
import { GridListingsLeftSidebarComponent } from './components/pages/grid-listings-left-sidebar/grid-listings-left-sidebar.component';
import { GridListingsRightSidebarComponent } from './components/pages/grid-listings-right-sidebar/grid-listings-right-sidebar.component';
import { GridListingsFullWidthComponent } from './components/pages/grid-listings-full-width/grid-listings-full-width.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { DashboardNavbarComponent } from './components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from './components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { CopyrightsComponent } from './components/pages/dashboard/copyrights/copyrights.component';
import { StatsComponent } from './components/pages/dashboard/stats/stats.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RecentActivitiesComponent } from './components/pages/dashboard/recent-activities/recent-activities.component';
import { DashboardMessagesComponent } from './components/pages/dashboard/dashboard-messages/dashboard-messages.component';
import { DashboardBookingsComponent } from './components/pages/dashboard/dashboard-bookings/dashboard-bookings.component';
import { DashboardWalletComponent } from './components/pages/dashboard/dashboard-wallet/dashboard-wallet.component';
import { DashboardReviewsComponent } from './components/pages/dashboard/dashboard-reviews/dashboard-reviews.component';
import { DashboardInvoiceComponent } from './components/pages/dashboard/dashboard-invoice/dashboard-invoice.component';
import { DashboardMyProfileComponent } from './components/pages/dashboard/dashboard-my-profile/dashboard-my-profile.component';
import { DashboardAddListingsComponent } from './components/pages/dashboard/dashboard-add-listings/dashboard-add-listings.component';
import { DashboardBookmarksComponent } from './components/pages/dashboard/dashboard-bookmarks/dashboard-bookmarks.component';
import { DashboardMyListingsComponent } from './components/pages/dashboard/dashboard-my-listings/dashboard-my-listings.component';
import { LoginRegisterModule } from './login-register/login-register.module';
import { InitialAssessmentFormComponent } from './components/dr-sunny/initial-assessment-form/initial-assessment-form.component';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { NannyTaskComponent } from './components/dr-sunny/nanny-task/nanny-task.component';
import { ZeroToThreeMonthsComponent } from './components/dr-sunny/care-plans/zero-to-three-months/zero-to-three-months.component';
import { FourMonthsToOneYrComponent } from './components/dr-sunny/care-plans/four-months-to-one-yr/four-months-to-one-yr.component';
import { NannyDailyMonitoringComponent } from './components/dr-sunny/nanny-daily-monitoring/nanny-daily-monitoring.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NannyPlansComponent } from './components/dr-sunny/masters/nanny-plans/nanny-plans.component';
import { TableModule } from 'primeng/table';
import { StoolMasterComponent } from './components/dr-sunny/masters/stool-master/stool-master.component';
import { UrineMasterComponent } from './components/dr-sunny/masters/urine-master/urine-master.component';
import { OneYearToThreeYearComponent } from './components/dr-sunny/care-plans/one-year-to-three-year/one-year-to-three-year.component';
import { EnvironmentAndChiledSaftyAssessmentComponent } from './components/dr-sunny/environment-and-chiled-safty-assessment/environment-and-chiled-safty-assessment.component';
import { FeedingAssesmentFormComponent } from './components/dr-sunny/feeding-assesment-form/feeding-assesment-form/feeding-assesment-form.component';
import { ToastModule } from 'primeng/toast';
import { SharedModule } from './modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { PtAssessmentComponent } from './components/dr-sunny/PT/pt-assessment/pt-assessment.component';
import { DailyNotesComponent } from './components/dr-sunny/PT/daily-notes/daily-notes.component';
import { InitialAssessmentAdultComponent } from './components/dr-sunny/PT/initial-assessment-adult/initial-assessment-adult.component';
import { InitialAssesseemntPediaComponent } from './components/dr-sunny/PT/initial-assesseemnt-pedia/initial-assesseemnt-pedia.component';
import { DailyNotesListComponent } from './components/dr-sunny/maika-listing/daily-notes-list/daily-notes-list.component';
import { InitialAssListComponent } from './components/dr-sunny/maika-listing/initial-ass-list/initial-ass-list.component';
import { NannyTaskListComponent } from './components/dr-sunny/maika-listing/nanny-task-list/nanny-task-list.component';
import { EnvironmentAndChildSafetyAssListComponent } from './components/dr-sunny/maika-listing/environment-and-child-safety-ass-list/environment-and-child-safety-ass-list.component';
import { DialogModule } from 'primeng/dialog';
import { ZeroToThreeMonitorComponent } from './components/dr-sunny/growth_monitoring/zero-to-three-monitor/zero-to-three-monitor.component';
import { FourToOneYrMonitorComponent } from './components/dr-sunny/growth_monitoring/four-to-one-yr-monitor/four-to-one-yr-monitor.component';
import { OneToThreeYrMonitorComponent } from './components/dr-sunny/growth_monitoring/one-to-three-yr-monitor/one-to-three-yr-monitor.component';
import { AboveThreeYrMonitorComponent } from './components/dr-sunny/growth_monitoring/above-three-yr-monitor/above-three-yr-monitor.component';
import { ZeroToThreeMonthsListComponent } from './components/dr-sunny/maika-listing/care-plans-list/zero-to-three-months-list/zero-to-three-months-list.component';
import { FourMonthsToTwoYearsListComponent } from './components/dr-sunny/maika-listing/care-plans-list/four-months-to-two-years-list/four-months-to-two-years-list.component';
// import { TwoYearsToAboveListComponent } from './components/dr-sunny/maika-listing/care-plans-list/two-years-to-above-list/two-years-to-above-list.component';
import { FeedingAssessmentListComponent } from './components/dr-sunny/maika-listing/feeding-assessment-list/feeding-assessment-list.component';
import { ThreeYearsAndAboveComponent } from './components/dr-sunny/care-plans/three-years-and-above/three-years-and-above/three-years-and-above.component';
import { OneYearToThreeYearListComponent } from './components/dr-sunny/maika-listing/care-plans-list/one-year-to-three-year-list/one-year-to-three-year-list.component';
import { ThreeYearToAboveListComponent } from './components/dr-sunny/maika-listing/care-plans-list/three-year-to-above-list/three-year-to-above-list.component';
import { ZeroThreeGrowthMonitorListComponent } from './components/dr-sunny/maika-listing/growth-monitoring/zero-three-growth-monitor-list/zero-three-growth-monitor-list.component';
import { NannyDailyMonitorComponent } from './components/dr-sunny/maika-listing/nanny-daily-monitor/nanny-daily-monitor.component';
import { FourMonthsToOneYearListComponent } from './components/dr-sunny/maika-listing/growth-monitor-list/four-months-to-one-year-list/four-months-to-one-year-list.component';
import { ThreeYearAndAboveMonitorListComponent } from './components/dr-sunny/maika-listing/growth-monitor-list/three-year-and-above-monitor-list/three-year-and-above-monitor-list.component';
import { OneToThreeYearMonitorListComponent } from './components/dr-sunny/maika-listing/growth-monitor-list/one-to-three-year-monitor-list/one-to-three-year-monitor-list.component';
import { PtAssessmentListingComponent } from './components/dr-sunny/PT/pt-listing/pt-assessment-listing/pt-assessment-listing.component';
import { PtDailyTaskListingComponent } from './components/dr-sunny/PT/pt-listing/pt-daily-task-listing/pt-daily-task-listing.component';
// import { PtAssessmentAdultsListingComponent } from './components/dr-sunny/PT/pt-listing/pt-assessment-adults-listing/pt-assessment-adults-listing.component';
import { PtAssessmentAdultsListingComponent } from './components/dr-sunny/PT/pt-listing/pt-assessment-adults-listing/pt-assessment-adults-listing.component';
import { PtAssesmentPediaListingComponent } from './components/dr-sunny/PT/pt-listing/pt-assesment-pedia-listing/pt-assesment-pedia-listing.component';
import { JwtInterceptor, } from 'src/app/helpers/jwt.interceptor';
import { UsersRegistrationComponent } from './components/dr-sunny/masters/users-registration/users-registration.component';
import { InitialAssessmentListComponent } from './components/dr-sunny/initial-assessment-list/initial-assessment-list.component';
import { HealthMonitoringComponent } from './components/dr-sunny/health-monitoring/health-monitoring.component';
import { ExtraActivitiesComponent } from './components/dr-sunny/extra-activities/extra-activities.component';
import { ImmunizationComponent } from './components/dr-sunny/immunization/immunization.component';
// import { HealthMonitoringListingComponent } from './components/dr-sunny/maika-listing/health-monitoring-listing/health-monitoring-listing.component';
import { HealthMonitoringListingComponent } from './components/dr-sunny/maika-listing/health-monitoring-listing/health-monitoring-listing.component';
import { HealthMonitoringSummaryComponent } from './components/dr-sunny/maika-listing/health-monitoring-listing/health-monitoring-summary/health-monitoring-summary.component';
import { RolesPermissionComponent } from './components/dr-sunny/masters/roles-permission/roles-permission.component';
import { FirstScreenComponent } from './components/dr-sunny/first-screen/first-screen.component';
import { EditZeroToThreeMonthsComponent } from './components/dr-sunny/nurse-task-edit/edit-zero-to-three-months/edit-zero-to-three-months.component';
import { EditFourMonthsToOneYearComponent } from './components/dr-sunny/nurse-task-edit/edit-four-months-to-one-year/edit-four-months-to-one-year.component';
import { EditOneYearToThreeYearComponent } from './components/dr-sunny/nurse-task-edit/edit-one-year-to-three-year/edit-one-year-to-three-year.component';
import { EditThreeYearsAndAboveComponent } from './components/dr-sunny/nurse-task-edit/edit-three-years-and-above/edit-three-years-and-above.component';
import { PatientViewComponent } from './components/dr-sunny/patient-view/patient-view.component';
import { DailyCarePlanComponent } from './components/dr-sunny/maika-listing/daily-care-plan/daily-care-plan.component';
import { GrowthMonitoringListComponent } from './components/dr-sunny/maika-listing/growth-monitoring-list/growth-monitoring-list.component';
import { NurseSummaryComponent } from './components/dr-sunny/nurse-summary/nurse-summary.component';
import { DailyTaskSummaryComponent } from './components/dr-sunny/daily-task-summary/daily-task-summary.component';
import { PermissionForRolesComponent } from './components/dr-sunny/masters/permission-for-roles/permission-for-roles.component';
import { ChartModule } from 'primeng/chart';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { GrowthMonitoringSummaryComponent } from './components/dr-sunny/maika-listing/growth-monitoring-summary/growth-monitoring-summary.component';
// import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { HealthMonitoringAddtaskComponent } from './components/dr-sunny/maika-listing/health-monitoring-listing/health-monitoring-addtask/health-monitoring-addtask.component';
import { MomentsMemoriesComponent } from './components/dr-sunny/masters/moments-memories/moments-memories.component';
import { MemoriesMomentsComponent } from './components/dr-sunny/memories-moments/memories-moments.component';
import { HealthMonitoringDailySummaryComponent } from './components/dr-sunny/health-monitoring-daily-summary/health-monitoring-daily-summary.component';
import { AuditLogsComponent } from './components/dr-sunny/audit-logs/audit-logs.component';
import { GrowthMonitoringCaptureComponent } from './components/dr-sunny/growth_monitoring/growth-monitoring-capture/growth-monitoring-capture.component';

// import { UserRegistrationListingComponent } from './login-register/user-registration-listing/user-registration-listing.component'
// import { AgmCoreModule } from '@agm/core';
@NgModule({
  declarations: [

    AppComponent,
    HomeDemoOneComponent,
    HomeDemoTwoComponent,
    AppDownloadComponent,
    HowItWorksComponent,
    HomeoneBlogComponent,
    FeedbackStyleOneComponent,
    HomeoneDestinationsComponent,
    CategoryComponent,
    HomeoneListingsComponent,
    FeaturesComponent,
    HomeoneBannerComponent,
    FooterStyleOneComponent,
    NavbarStyleOneComponent,
    NavbarStyleTwoComponent,
    HometwoBannerComponent,
    HometwoListingsComponent,
    HometwoDestinationsComponent,
    HometwoEventsComponent,
    HometwoBlogComponent,
    ComingSoonComponent,
    NotFoundComponent,
    AboutUsComponent,
    PartnerComponent,
    TeamComponent,
    FunfactsComponent,
    HowItWorksPageComponent,
    PricingComponent,
    GalleryComponent,
    FaqComponent,
    ContactComponent,
    FooterStyleTwoComponent,
    BlogGridComponent,
    BlogRightSidebarComponent,
    BlogDetailsComponent,
    ProductsListComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    RelatedProductsComponent,
    AuthorProfileComponent,
    CategoriesComponent,
    TopPlaceComponent,
    ListingsDetailsComponent,
    EventsDetailsComponent,
    EventsComponent,
    VerticalListingsLeftSidebarComponent,
    VerticalListingsRightSidebarComponent,
    VerticalListingsFullWidthComponent,
    GridListingsLeftSidebarComponent,
    GridListingsRightSidebarComponent,
    GridListingsFullWidthComponent,
    DashboardComponent,
    DashboardNavbarComponent,
    // DashboardSidemenuComponent,
    CopyrightsComponent,
    StatsComponent,
    RecentActivitiesComponent,
    DashboardMessagesComponent,
    DashboardBookingsComponent,
    DashboardWalletComponent,
    DashboardReviewsComponent,
    DashboardInvoiceComponent,
    DashboardMyProfileComponent,
    DashboardAddListingsComponent,
    DashboardBookmarksComponent,
    DashboardMyListingsComponent,
    InitialAssessmentFormComponent,
    ZeroToThreeMonthsComponent,
    FourMonthsToOneYrComponent,
    NannyTaskComponent,
    NannyDailyMonitoringComponent,
    ZeroToThreeMonthsComponent,
    NannyPlansComponent,
    StoolMasterComponent,
    UrineMasterComponent,
    OneYearToThreeYearComponent,
    EnvironmentAndChiledSaftyAssessmentComponent,
    FeedingAssesmentFormComponent,
    PtAssessmentComponent,
    DailyNotesComponent,
    InitialAssessmentAdultComponent,
    InitialAssesseemntPediaComponent,
    DailyNotesListComponent,
    InitialAssListComponent,
    NannyTaskListComponent,
    EnvironmentAndChildSafetyAssListComponent,
    ZeroToThreeMonitorComponent,
    FourToOneYrMonitorComponent,
    OneToThreeYrMonitorComponent,
    AboveThreeYrMonitorComponent,
    ZeroToThreeMonthsListComponent,
    FourMonthsToTwoYearsListComponent,
    // TwoYearsToAboveListComponent,
    FeedingAssessmentListComponent,
    ThreeYearsAndAboveComponent,
    OneYearToThreeYearListComponent,
    ThreeYearToAboveListComponent,
    ZeroThreeGrowthMonitorListComponent,
    NannyDailyMonitorComponent,
    FourMonthsToOneYearListComponent,
    ThreeYearAndAboveMonitorListComponent,
    OneToThreeYearMonitorListComponent,
    PtAssessmentListingComponent,
    PtDailyTaskListingComponent,
    PtAssessmentAdultsListingComponent,
    PtAssesmentPediaListingComponent,
    UsersRegistrationComponent,
    InitialAssessmentListComponent,
    HealthMonitoringComponent,
    ExtraActivitiesComponent,
    ImmunizationComponent,
    HealthMonitoringListingComponent,
    HealthMonitoringSummaryComponent,
    RolesPermissionComponent,
    FirstScreenComponent,
    EditZeroToThreeMonthsComponent,
    EditFourMonthsToOneYearComponent,
    EditOneYearToThreeYearComponent,
    EditThreeYearsAndAboveComponent,
    PatientViewComponent,
    DailyCarePlanComponent,
    GrowthMonitoringListComponent,
    NurseSummaryComponent,
    DailyTaskSummaryComponent,
    PermissionForRolesComponent,
    GrowthMonitoringSummaryComponent,
    HealthMonitoringAddtaskComponent,
    MomentsMemoriesComponent,
    MemoriesMomentsComponent,
    HealthMonitoringDailySummaryComponent,
    AuditLogsComponent,
    GrowthMonitoringCaptureComponent
    // UserRegistrationListingComponent
  ],
  imports: [
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAa7BxtAHgGAxAoSCV8x4OBUJfpm5k-cG4', // Replace with your Google Maps API key
    //   libraries: ['places'] // Add any additional libraries you need
    // }),
    TableModule,
    ChartModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    // SelectDropDownModule,
    NgxTypedJsModule,
    NgxSpinnerModule,
    FormsModule,
    NgxPaginationModule,
    LoginRegisterModule,
    CalendarModule,
    DropdownModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastModule,
    CommonModule,
    SharedModule,
    ConfirmDialogModule,
    DialogModule,
    AccordionModule,
    MultiSelectModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
