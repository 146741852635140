<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Timeline Area -->
<section class="timeline-area ptb-100">
    <div class="container">
        <div class="main-timeline">
            <div class="timeline" *ngFor="let Content of timelineContent;">
                <span class="icon">{{Content.number}}</span>
                <div class="timeline-content">
                    <h3 class="title">{{Content.title}}</h3>
                    <p class="description">{{Content.description}}</p>
                    <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Timeline Area -->

<section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
    <app-how-it-works></app-how-it-works>
</section>

<app-footer-style-one></app-footer-style-one>