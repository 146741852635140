<p-toast></p-toast>
<!-- <p-confirmDialog></p-confirmDialog> -->
<p-confirmDialog></p-confirmDialog>

<!-- <p-confirmDialog></p-confirmDialog> -->
<!-- <div class="loginRegisterModal">
  <div class="modal1">
    <div class="modal1-dialog modal1-dialog-centered">
      <div class="modal1-content">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <button
              class="nav-link active"
              id="login-tab"
              data-bs-toggle="tab"
              data-bs-target="#login"
              type="button"
              role="tab"
              aria-controls="login"
              aria-selected="true"
            >
              Register
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="vesax-register">
            <form
              [formGroup]="registerForm"
              (ngSubmit)="saveRegisterForm()"
              #register="ngForm"
            >
              <div class="form-group">
                <input
                  formControlName="name"
                  type="text"
                  placeholder="Username"
                  class="form-control form-input"
                  [class.is-invalid]="
                    (register.submitted && registerForm.get('name')?.invalid) ||
                    (registerForm.get('name')?.invalid &&
                      registerForm.get('name').touched)
                  "
                />
                <div class="invalid-feedback">Please enter name</div>
              </div>
              <div class="form-group">
                <input
                  formControlName="email"
                  type="email"
                  placeholder="Email"
                  class="form-control form-input"
                  [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('email')?.invalid) ||
                    (registerForm.get('email')?.invalid &&
                      registerForm.get('email').touched)
                  "
                />
                <div class="invalid-feedback">Please enter email</div>
              </div>
              <div class="form-group">
                <input
                  formControlName="password"
                  type="password"
                  placeholder="Password"
                  class="form-control form-input"
                  [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('password')?.invalid) ||
                    (registerForm.get('password')?.invalid &&
                      registerForm.get('password').touched)
                  "
                />
                <div class="invalid-feedback">Please enter password</div>
              </div>
              <div class="form-group">
                <input
                  formControlName="mobile"
                  type="number"
                  placeholder="Contact No"
                  class="form-control form-input"
                />
                <div class="invalid-feedback">
                                    Please enter contact no
                                </div> -->
<!-- <div *ngIf="controlsValidate.mobile.errors?.pattern" class="invalid-feedback">
                                    Please enter a valid contact no
                                </div> -->
<!-- </div>
              <button type="submit" class="login-form">Register Now</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- // add profile section 15-03-2024 by giriraj -->
<div class="main-content d-flex flex-column">
  <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
    <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
      <div class="menu-profile">
        <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        <span class="name">{{getname}}</span>
      </div>
    </a>
    <div class="dropdown-menu d-flex flex-column align-items-center">
      <div class="dropdown-header align ">
        <div class="figure mb-3">
          <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
        </div>
        <div class="info text-center">
          <span class="name" style="white-space: break-spaces;">{{getname}}</span>
          <p class="mb-3 email">{{getRole}}</p>
        </div>
      </div>
      <!-- <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
            </ul>
        </div> -->
      <div class="dropdown-footer">
        <ul class="profile-nav">
          <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                  class="logout-text">Logout</span></a></li>
        </ul>
      </div>

    </div>
  </div>
  <br>
  <br>
  <p-accordion [ngStyle]="{'background-color': 'white'}">
    <p-accordionTab header="User Registration Form" [(selected)]="activeState[0]" styleClass="firstaccordion">
      <form [formGroup]="registerForm" (ngSubmit)="saveRegisterForm()" #register="ngForm">
        <div class="add-listings-box">
          <h3>Create New User</h3>
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">First Name<span class="required">*</span></label>
                <input type="text" formControlName="first_name" id="firstname" class="form-control"
                    placeholder="First Name" [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('first_name')?.invalid) ||
                    (registerForm.get('first_name')?.invalid &&
                      registerForm.get('first_name').touched)
                  " />
                <div class="invalid-feedback">First Name is required</div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Last Name<span class="required">*</span></label>
                <input type="text" formControlName="last_name" id="lastname" class="form-control"
                    placeholder="Last Name" [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('last_name')?.invalid) ||
                    (registerForm.get('last_name')?.invalid &&
                      registerForm.get('last_name').touched)
                  " />
                <div class="invalid-feedback">Last Name is required</div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">User Name<span class="required">*</span></label>
                <input type="text" formControlName="user_name" id="username" class="form-control"
                    placeholder="User Name" [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('user_name')?.invalid) ||
                    (registerForm.get('user_name')?.invalid &&
                      registerForm.get('user_name').touched)
                  " />
                <div class="invalid-feedback">User Name is required</div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Password<span class="required">*</span></label>
                <input formControlName="password" type="password" placeholder="Password" class="form-control form-input"
                    [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('password')?.invalid) ||
                    (registerForm.get('password')?.invalid &&
                      registerForm.get('password').touched)
                  " />
                <div class="invalid-feedback" *ngIf="registerForm.get('password')?.errors?.minlength">
                  Password must be at least 8 characters long.
                </div>
                <div class="invalid-feedback" *ngIf="registerForm.get('password')?.errors?.required">
                  Password is required
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Employee Id<span class="required">*</span></label>
                <input type="text" formControlName="employee_id" id="employeeid" class="form-control"
                    placeholder="Employee Id" [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('employee_id')?.invalid) ||
                    (registerForm.get('employee_id')?.invalid &&
                      registerForm.get('employee_id').touched)
                  " />
                <div class="invalid-feedback">Employee ID is required</div>
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Contact No<span class="required">*</span></label>
                <input type="text" formControlName="mobile" id="mobile" class="form-control" placeholder="Contact No"
                    [class.is-invalid]="
                    (register.submitted &&
                      registerForm.get('mobile')?.invalid) ||
                    (registerForm.get('mobile')?.invalid &&
                      registerForm.get('mobile').touched)
                  " />
                <div *ngIf="
                    registerForm.get('mobile').hasError('required') &&
                    (registerForm.get('mobile').dirty ||
                      registerForm.get('mobile').touched)
                  " class="invalid-feedback">
                  Mobile number is required.
                </div>
                <!-- <div
                  *ngIf="
                    registerForm.get('mobile').hasError('pattern') &&
                    (registerForm.get('mobile').dirty ||
                      registerForm.get('mobile').touched)
                  "
                  class="invalid-feedback"
                >
                  Please enter a valid digit mobile number.
                </div> -->
              </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Email Id<span class="required">*</span></label>
                <input type="email" formControlName="email" id="email" class="form-control" placeholder="Email Id"
                    [class.is-invalid]="
                    registerForm.get('email')?.invalid &&
                    (registerForm.get('email').dirty ||
                      registerForm.get('email').touched)
                  " />
                <div *ngIf="
                    registerForm.get('email').hasError('required') &&
                    (registerForm.get('email').dirty ||
                      registerForm.get('email').touched)
                  " class="invalid-feedback">
                  Email is required.
                </div>
                <div *ngIf="
                    registerForm.get('email').hasError('email') &&
                    (registerForm.get('email').dirty ||
                      registerForm.get('email').touched)
                  " class="invalid-feedback">
                  Please enter a valid email address.
                </div>
                <!-- <div
                  *ngIf="registerForm.get('email').hasError('emailWithoutCom')"
                  class="invalid-feedback"
                >
                  Email should contain .com domain.
                </div> -->
              </div>
            </div>
            <!-- <div class="col-lg-4 col-sm-6 col-12">
          <div class="form-group">
            <label class="p-0">Nationality</label>
            <input
              type="text"
              formControlName="nationality"
              id="nationality"
              class="form-control"
              placeholder="Nationality"
            />
          </div>
        </div> -->
            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Nationality</label>
                <div class="card flex justify-content-center">
                  <p-dropdown formControlName="nationality" [options]="country" optionLabel="name" optionValue="name"
                      [filter]="true" filterBy="name" placeholder="Nationality" [resetFilterOnHide]="true">
                  </p-dropdown>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-12">
              <div class="form-group">
                <label class="p-0">Role<span class="required">*</span></label>

                <div class="card flex justify-content-center">
                  <p-dropdown formControlName="role_id" [options]="roleList" optionLabel="name" optionValue="id"
                      [filter]="true" filterBy="name" placeholder="Role" [resetFilterOnHide]="true" [class.is-invalid]="
                      (register.submitted &&
                        registerForm.get('role_id')?.invalid) ||
                      (registerForm.get('role_id')?.invalid &&
                        registerForm.get('role_id').touched)
                    ">
                  </p-dropdown>
                  <div class="invalid-feedback">Role is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label class="p-0">Status</label>
              <div class="card flex justify-content-center">
                <p-dropdown formControlName="is_active" [options]="statusList" optionLabel="name" optionValue="value"
                    [filter]="true" filterBy="name" placeholder="Status" [resetFilterOnHide]="true">
                </p-dropdown>
              </div>
              <!-- <p>
                <input
                  type="radio"
                  id="active"
                  formControlName="is_active"
                  name="is_active"
                  value=1
                 
                />
                <label for="active">Active</label>
              </p>
              <p>
                <input
                  type="radio"
                  id="not_active"
                  formControlName="is_active"
                  name="is_active"
                  value=0
                 
                />
                <label for="not_active">Not Active</label>
              </p> -->
            </div>
          </div>
        </div>
        <!-- <button type="submit" class="login-form">Register Now</button> -->
        <div class="add-listings-btn" style="text-align: right">
          <button type="submit">Submit</button>
        </div>
      </form>
    </p-accordionTab>
  </p-accordion>
  <div style="background-color: #fafafa; height: 10px"></div>
  <div class="add-listings-box">
    <div class="Master-view-box">
      <div class="theader d-flex justify-content-between">
        <!-- <div class="d-flex w-100">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              class="form-control text-center"
              scriptInjection
              placeholder="Search Keyword"
              (input)="dt1.filterGlobal($event.target.value, 'contains')"
              [(ngModel)]="filterVal"
            />
          </span>

          <p-button
            type="button"
            class="mx-2"
            (click)="reset(dt1)"
            icon="bx bx-reset"
            tooltipPosition="bottom"
          ></p-button>
        </div> -->
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-12 col-md-12">
          <div class="theader d-flex  justify-content-between">
        <div class="d-flex flex-wrap  w-100">
          <span class="p-input-icon-left ml-auto mr-2 w-100%">
            <i class="pi pi-search"></i>
            <input pInputText type="text" class="form-control " scriptInjection
                placeholder="Search Keyword"style="text-align: left; padding-left: 20%; height: 45px !important" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                [(ngModel)]="filterVal" />
          </span>
          <p-button type="button" class="mx-2" (click)="reset(dt1)" icon="bx bx-reset"
              tooltipPosition="bottom"></p-button>
        </div>
        <div class="add-listings-btn mb-0 ms-2" style="text-align: right" >
            <button (click)="getAllData()"class="mx-2"  type="button" >{{ isToggled ? 'STAFF': 'PATIENT' }}</button>
      </div>
    </div>  

        <h3>List of Users</h3>
        <p-table #dt1 [value]="userreglist" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
        [tableStyle]="{ 'min-width': '50rem', width: '100%' }"styleClass="p-datatable-sm p-datatable-customers"
            [rowsPerPageOptions]="[10, 25, 50]" responsiveLayout="scroll"  [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [globalFilterFields]="[
            'first_name',
            'last_name',
            'user_name',
            'employee_id',
            'email',
            'role_name',
            'mobile',
            'is_active'
          ]" editMode="row"  [ngStyle]="{ 'min-width': '50rem', 'width': '100%' }">
          <ng-template pTemplate="header">
            <tr>
              <th width="52">S. No.</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>User Name</th>
              <th>Employee Id</th>
              <th>Email Id</th>
              <th>Role</th>
              <th>Contact No.</th>
              <th>User Status</th>
              <th class="text-center">Action</th>
              <th class="text-center">Permissions</th>
            </tr>
          </ng-template>

          <!-- table to show data -->
          <ng-template pTemplate="body" let-list let-i="rowIndex">
            <tr (click)="highlightEdit($event, i)">
              <td>
                {{ i + 1 }}
              </td>
              <td> 
                <input class="editInput" type="text"  value="{{list.first_name}}"
                    [readonly]="isEdit || i !== selectedRowIndex" (change)="change($event, i, 'first_name')"/>
                 </td>
              <td><input class="editInput" type="text"value="{{list.last_name}}"
                    [readonly]="isEdit || i !== selectedRowIndex" (change)="change($event, i, 'last_name')"/></td>
              <td><input class="editInput" type="text" value="{{list.user_name}}"
                    [readonly]="isEdit || i !== selectedRowIndex" (change)="change($event, i, 'user_name')"/></td>
              <td><input class="editInput" type="text"  value="{{list.employee_id}}"
                    [readonly]="isEdit || i !== selectedRowIndex"  (change)="change($event, i, 'employee_id')"/></td>
              <td><input class="editInput" type="text"  value="{{list.email}}"
                    [readonly]="isEdit || i !== selectedRowIndex"(change)="change($event, i, 'email')" /></td>
              <td>
                <select class="editInput"  [(ngModel)]="editData[i].role_id"  
                    [disabled]="isEdit || i !== selectedRowIndex"  >
                  <option [value]="role.id" *ngFor="let role of roleList" >{{role.name}}</option>
                </select>
              </td>
              <td><input class="editInput" type="text"  value="{{list.mobile}}"
                    [readonly]="isEdit || i !== selectedRowIndex" (change)="change($event, i, 'mobile')"  /></td>
              <td>
                <select  class="editInput" value="{{list.is_active}}"
                    [disabled]="isEdit || i !== selectedRowIndex" (change)="change($event, i, 'is_active')">
                  <option value="1">Active</option>
                  <option value="0">Not Active</option>
                </select>
                <!-- {{
                    list?.is_active === 1
                      ? "Active"
                      : list?.is_active === 0
                      ? "Not Active"
                      : "-"
                  }} -->
              </td>
              <td class="text-center nowrap">
                <button pButton pRipple class="p-button-rounded" (click)="updateEdit(i, $event, list.id)"
                    *ngIf="updateButtonVisibility[i]"><i class="bx bx-check"></i></button>
                <button pButton pRipple class="p-button-rounded p-button-icon " pTooltip="Edit" tooltipPosition="bottom"
                    (click)="showMaximizableDialog(list.id, i)"><i class="bx bx-pencil"></i></button>

                <button pButton pRipple class="p-button-rounded p-button-icon" (click)="deleteUser(list.id, i)" cli
                    pTooltip="Delete" tooltipPosition="bottom">
                  <i class="bx bx-trash"></i>
                </button>



              </td>
              <td><button pButton pRipple class="p-button-rounded" (click)="routeTOPermission(list.id, list.role_id)"><i
                      class="bx bx-lock"></i></button></td>
              <!-- <td>{{ formatDateTime(list?.created_at) ?? "-" }}</td> -->
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <!----------------------------------------------------------- view dialog --------------------------------------------------------->
  <!-- ***************************************************************************************************************** -->
  <div class="add-listings-box">
    <p-dialog header="Baby Details" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
        [draggable]="false" [resizable]="false" styleClass="custom-dialog">
      <form [formGroup]="editRegisterForm" (ngSubmit)="submitEditForm(register)" #register="ngForm">
        <ng-container>
          <div class="row">
            <div class="col-lg-12">
              <div class="my-profile-box">
                <div class="row">
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>First Name :</label>
                      <input type="text" class="form-control" formControlName="first_name" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Last Name :</label>
                      <input type="text" class="form-control" formControlName="last_name" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>User Name :</label>
                      <input type="text" class="form-control" formControlName="user_name" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Role :</label>
                      <p-dropdown formControlName="role_id" [options]="roleList" optionLabel="name" optionValue="id"
                          [filter]="true" filterBy="name" placeholder="Role" [resetFilterOnHide]="true">
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Nationality :</label>
                      <p-dropdown formControlName="nationality" [options]="country" optionLabel="name"
                          optionValue="name" [filter]="true" filterBy="name" placeholder="Nationality"
                          [resetFilterOnHide]="true">
                      </p-dropdown>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Employee Id :</label>
                      <input type="text" class="form-control" formControlName="employee_id" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Contact No :</label>
                      <input type="text" class="form-control" formControlName="mobile" />
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="row">
                      <div class="form-group">
                        <label>Status :</label>
                        <div class="card flex justify-content-center">
                          <p-dropdown formControlName="is_active" [options]="statusList" optionLabel="name"
                              optionValue="value" [filter]="true" filterBy="name" placeholder="Status"
                              [resetFilterOnHide]="true">
                          </p-dropdown>
                        </div>
                        <!-- <label class="p-0">Status</label>
                        <p>
                          <input
                            type="radio"
                            id="active"
                            formControlName="is_active"
                            value="1"
                            name="is_active"
                          />
                          <label for="active">Active</label>
                        </p>
                        <p>
                          <input
                            type="radio"
                            id="not_active"
                            formControlName="is_active"
                            value="0"
                            name="is_active"
                          />
                          <label for="not_active">Not Active</label>
                        </p> -->
                      </div>
                    </div>

                  </div>
                  <div class="col-xl-4 col-lg-12 col-md-12">
                    <div class="form-group">
                      <label>Email Id :</label>
                      <input type="text" class="form-control" formControlName="email" />
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <p-button type="submit" (click)="submitEditForm(register)" label="Submit" [ngStyle]="{'padding-bottom': '2%'}" styleClass="p-button-text"></p-button>

        </ng-container>
      </form>
    </p-dialog>
  </div>
</div>





<div class="modal fade" id="confirmPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Modal title</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure want to delete ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>