<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align ">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
                </ul>
            </div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <p-confirmDialog></p-confirmDialog>
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">{{Content.subTitle}}</li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <div class="Master-view-box">
            <div class="theader d-flex justify-content-between flex-wrap">
                <div class="d-flex mb-2">
                    <span class="p-input-icon-left ml-auto mr-2 w-100%">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" class="form-control"
                            style="text-align: left; padding-left: 20%; height: 45px !important" scriptInjection
                            placeholder="Search Keyword" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                            [(ngModel)]="filterVal" />
                    </span>
                    <p-button type="button" class="mx-2" icon="bx bx-reset" tooltipPosition="bottom"
                        (click)="reset(dt1)">
                    </p-button>
                </div>
                <div class="add-listings-btn d-flex mb-0" style="text-align: right;">
                    <a class="nav-link">
                        <button (click)="genratenurseLink()" type="submit" *ngIf="checkPermission(9)">Add</button>
                    </a>
                    <button type="button" pButton pRipple icon="pi pi-file-pdf" class="p-button-warning ms-2"
                        pTooltip="PDF" tooltipPosition="bottom">
                    </button>
                </div>

            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-12 col-md-12">
                <p-table #dt1 dataKey="id" [rows]="10" [loading]="loading" [showCurrentPageReport]="true"
                    [tableStyle]="{'min-width': '50rem', 'width' : '100%'}" styleClass="p-datatable-sm
p-datatable-customers" responsiveLayout="scroll" [totalRecords]="datalength" [rowsPerPageOptions]="[10, 25, 50]"
                    [paginator]="true" currentPageReportTemplate="Showing {first} to
    {last} of {totalRecords} entries"
                    [globalFilterFields]="['created_by_user_name','name', 'date_of_birth', 'age','created_at','nanny_task_details_json?.remarks ']"
                    [value]="dailyCare" editMode="row">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width: 62px">S. No.</th>
                            <th>Date Added</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th [width]="110" class="text-center">Action</th>
                            <th>Remark</th>
                            <th>Created by</th>
                            <th>Status</th>
                        </tr>


                    </ng-template>
                    <!-- tables to show data -->
                    <ng-template pTemplate="body" let-list let-i="rowIndex">
                        <tr>
                            <td>
                                {{ i + 1 }}
                            </td>
                            <td>
                                {{ list?.created_at ? (list.created_at | date: 'd-MMM-yyyy') : '-' }}
                            </td>
                            <td>{{ 'Daily Care Plan ' + (datalength- i) }}</td>
                            <td>
                                {{ list?.care_plan_id === 1 ? '0-3 months' : list?.care_plan_id === 2 ? '4 months - 1
                                year' : list?.care_plan_id === 3 ? '1-3 years' : list?.care_plan_id === 4 ? '3 years and
                                above' : '-' }}
                            </td>

                            <td class="text-center">
                                <button pButton pRipple class="p-button-rounded p-button-text p-button-info view-button"
                                    pTooltip="View" tooltipPosition="bottom" (click)="showMaximizableDialog(list.id)"><i
                                        class="far fa-eye"></i>
                                </button>
                                <button *ngIf="checkPermission(11)" pButton pRipple
                                    class="p-button-rounded p-button-text p-button-info view-button" pTooltip="Edit"
                                    tooltipPosition="bottom" (click)="EditOnClick(list.id)"><i
                                        class="far fa-pencil"></i>
                                </button>
                                <button *ngIf="checkPermission(12)" pButton pRipple
                                    class="p-button-rounded p-button-text p-button-info view-button" pTooltip="Edit"
                                    tooltipPosition="bottom" (click)="deleteplan(list.id)"><i class="far fa-trash"></i>
                                </button>
                                <!-- <button *ngIf="checkPermission(12)" pButton pRipple icon="bx bx-trash"
                                    class="p-button-rounded p-button-text p-button-info view-button"
                                    (click)="deleteplan(list.id)"></button> -->
                            </td>
                            <td>{{ list?.nanny_task_details_json?.remarks ?? '-' }}</td>

                            <td>{{ list?.created_by_user_name ?? '-' }}</td>
                            <td [ngClass]="{'active-style': list?.is_active === 1, 'inactive-style': list?.is_active === 0}">
                {{ list?.is_active === 1 ? 'Active' : list?.is_active === 0 ? 'Inactive' : '-' }}
              </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="8">No Data Found.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
            <!----------------------------------------------------------- view dialog --------------------------------------------------------->
            <!-- ***************************************************************************************************************** -->
            <p-dialog header="Zero to Three Months Baby Details" [(visible)]="displayMaximizable" [modal]="true"
                [closable]="false" [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false">
                <!-- Start -->
                <ng-container>
                    <div class="row viewSummary ">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <form>
                                    <!-- Baby detail -->
                                    <!-- accordian on listing -->
                                    <p-accordion>
                                        <p-accordionTab header="Baby Details">
                                            <div class="row peach-bg">
                                                <div class=" my-3">
                                                    <!-- <h3>Baby Details</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Name :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.name ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label>Gender :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.gender ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Date of Birth :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.date_of_birth ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Days :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.days ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Months :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.months ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Years :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.years ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <!-- <div class="form-group">
                        <label for="">Food/Medicine Allergy</label>
                        <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                    }}" />
                    </div> -->
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Food/Medicine Allergy:</label>
                                                                    <div type="text" readonly *ngIf="dataonzeroposition?.food_medicine_allergy !==
                                                        null && dataonzeroposition?.food_medicine_allergy
                                                        !== '' && dataonzeroposition?.food_medicine_allergy
                                                        !== undefined; else elseBlock">

                                                                        {{ dataonzeroposition?.food_medicine_allergy ==
                                                                        '1'? 'Yes': 'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component:</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.food_medicine_allergy_specify_prod??'-'
                                                                    }}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- feeding assesment listing  -->
                                        <p-accordionTab header="Feeding Assesment">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <div class="row">
                                                        <!-- <label class="subHeading my-2">Assesment</label> -->
                                                        <div class="col-xl-6 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Baby is not interested when offered to
                                                                    breastfeed,
                                                                    sleepy</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.answer ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                            col-md-12 ">
                                                            <div class="form-group">
                                                                <label for="">Baby is showing feeding cues but not
                                                                    attaching</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.attach ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches at the breast but quickly falls
                                                                    asleep</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.Asleep ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches for short bursts with long
                                                                    pause</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.LongPause ?? '-' | titlecase
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches well with long rhythmical sucking
                                                                    and
                                                                    swallowing
                                                                    for a short feed</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.rhythmical ?? '-' | titlecase
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches well for a sustained period with
                                                                    long
                                                                    rhythmical
                                                                    sucking and swallowing</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.swallowing ?? '-' | titlecase
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Normal skin color and tone</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.Normal_skin ?? '-' | titlecase
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Gaining weight appropriately</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.weight ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Other Concerns</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.Other_concerns ?? '-' |
                                                                    titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- DAILY CARE -->
                                        <p-accordionTab header="Daily Cares">
                                            <!-- <h3>Daily Cares</h3> -->
                                            <!-- <div class="add-listings-box py-0"> -->
                                            <div class="row peach-bg">
                                                <!-- <h3 class=""></h3> -->
                                                <!-- <label class="subHeading my-2">Feeding: Prepared By</label> -->
                                                <div type="text" class="my-2">
                                                    Feeding: Prepared By
                                                    {{
                                                    (dataonzeroposition?.feeding_prepared_mother == 1 ? 'Mother' : '') +
                                                    (dataonzeroposition?.feeding_prepared_mother == 1 &&
                                                    dataonzeroposition?.feeding_prepared_nanny == 1 ? ' and ' : '') +
                                                    (dataonzeroposition?.feeding_prepared_nanny == 1 ? 'Nurse' : '') +
                                                    (dataonzeroposition?.feeding_prepared_other == 1 &&
                                                    (dataonzeroposition?.feeding_prepared_mother == 1 ||
                                                    dataonzeroposition?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                    (dataonzeroposition?.feeding_prepared_other == 1 ? 'Other' : '')
                                                    }}
                                                </div>
                                                <!-- <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Mother</label>
                                                        <div type="text" readonly>{{ dataonzeroposition?.feeding_prepared_mother == 1? 'Yes': '-'
                            }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Nurse</label>
                                                        <div type="text" readonly>{{ dataonzeroposition?.feeding_prepared_nanny == 1? 'Yes': '-'
                                                    }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Other</label>
                                                        <div type="text" readonly>{{ dataonzeroposition?.feeding_prepared_other == 1? 'Yes': '-'
                                                    }}</div>
                                                    </div>
                                                </div> -->
                                                <!-- </div> -->
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="Milk Feeding">
                                            <div class="row peach-bg">
                                                <label class="my-3">Breast Feeding Information Summary</label>
                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Is Select</label>
                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.feeding_breast == 1? 'Yes': '-'
                                                                }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.feeding_frequency_breast?? '-'}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Breast Feeding Remark</label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.breast_remark??'-' }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Breast Feeding Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of breast_feeding let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.feeding_breast_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row peach-bg my-3">
                                                <label class="my-3">Formula Feeding Information Summary</label>
                                                <!-- <div class="row">
                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Formula</label>
                                                        <div type="text" readonly>{{ dataonzeroposition?.feeding_type == 1? 'Yes': '-'
                                                    }}</div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Frequency</label>
                                                        <div type="text" readonly>
                                                            {{dataonzeroposition?.feeding_frequency?.frequency??'-' }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Start Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(dataonzeroposition?.feeding_time)}}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">End Time</label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(dataonzeroposition?.feeding_breast_time_end)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Amount</label>
                                                        <div type="text" readonly>
                                                            {{dataonzeroposition?.feeding_amount_per??'-' }}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Type of Formula</label>
                                                        <div type="text" readonly>
                                                            {{dataonzeroposition?.type_of_formula??'-' }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="add-listings-box py-0 my-3">
                                                <h3 class="mb-0">Formula Feeding Time and Amount </h3>
                                                <div class="row" *ngFor="let data of bottle">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Done or Not</label>
                                                            <div type="text" readonly>{{ data?.feeding_checkbox == 1? 'Yes': '-'
                                                    }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Time</label>
                                                            <div type="text" readonly>
                                                                {{formatDateTime(data?.formula_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Actual Time</label>
                                                            <div type="text" readonly>
                                                                {{formatDateTime(data?.nurse_feeding)}}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Amount</label>
                                                            <div type="text" readonly>
                                                                {{data?.amount??'-' }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Name of Formula</label>
                                                            <div type="text" readonly>
                                                                {{data?.remark??'-' }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Remark</label>
                                                            <div type="text" readonly>
                                                                {{data?.formula_remark??'-' }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> -->
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                    <div class="col-12 mb-4">
                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Formula</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_type == 1 ? 'Yes' : '-'
                                                                ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_frequency?.frequency??
                                                                '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(dataonzeroposition?.feeding_time) }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                formatDateTime(dataonzeroposition?.feeding_breast_time_end)
                                                                }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Amount</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_amount_per ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Type of Formula</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.type_of_formula ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Formula Feeding</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.formula_remark ?? '-' }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="">Formula Feeding Time</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let data of bottle let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(data?.formula_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- DAILY CARE END-->

                                        <!-- Bottle Sterilization -->

                                        <p-accordionTab header="Bottle Sterilization">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Bottle Sterilization</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">All</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                            }}" /> -->

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bottle_sterilization_all== 1? 'Yes':
                                                                '-' ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Bottle Sterilization All at a Time</label>
                                                            <!-- <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                            <div type="text" readonly>
                                                                {{formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">One At A time</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bottle_sterilization_onetime== 1?
                                                                'Yes': '-' ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Bottle Sterilization One Reference
                                                                Time</label>
                                                            <!-- <input type="text" readonly class="form-control"
                                                                                        [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                            <div type="text" readonly>
                                                                {{formatDateTime(dataonzeroposition?.bottle_sterilization_one_at_a_time)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Bottle Sterilization One at a Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let data of stellrization let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(data?.sterilization_timeArray)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bottle Sterilization End-->

                                        <!-- Food/Medicine Allergy -->
                                        <p-accordionTab header="Food/Medicine Allergy"
                                            [class.allergyhighlight]="dataonzeroposition?.food_medicine_allergy == '1'">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Food/Medicine Allergy</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <!-- <div class="form-group">
                        <label for="">Food/Medicine Allergy</label>
                        <input type="text" readonly class="form-control" value="{{ viewDetail?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                    }}" />
                    </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <div type="text" readonly *ngIf="dataonzeroposition?.food_medicine_allergy !==
                                                        null && dataonzeroposition?.food_medicine_allergy
                                                        !== '' && dataonzeroposition?.food_medicine_allergy
                                                        !== undefined; else elseBlock">

                                                                    {{ dataonzeroposition?.food_medicine_allergy == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <div type="text" readonly>
                                                                        {{"-"}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.food_medicine_allergy_specify_prod??'-'
                                                                }}
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Food/Medicine Allergy End-->
                                        <p-accordionTab header="Bathing">
                                            <div>
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Bathing</h3> -->
                                                <div class="row peach-bg">
                                                    <div class="my-3">
                                                        <!-- Sponge -->
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Sponge</label>
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_sponge == 1? 'Yes': '-'
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.bathing_frequency_sponge??'-'
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="row">
                                                            <label for="">Time of Sponge Bath</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let time of bathing  let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{ formatDateTime(time?.bathing_sponge_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Tub -->
                                                <div class="row peach-bg my-3">
                                                    <div class="my-3">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Tub</label>
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_tub == 1? 'Yes': '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.bathing_tub_frequency??'-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label for="">Time of Tub Bath</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let time of tub  let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{ formatDateTime(time?.bathing_tub_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- BATHING END -->
                                        <!-- diaper change -->
                                        <p-accordionTab header="Daiper Change">
                                            <div class="row peach-bg">
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                    <div class="col-12 mb-4">
                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Diaper Change</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_type == 1 ? 'Yes' : '-' ??
                                                                '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_change_frequency ??'-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(dataonzeroposition?.diaper_label) }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                formatDateTime(dataonzeroposition?.diaper_label_end_time)
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="">Diaper Time</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let data of diaper let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(data?.diaper_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="my-4">
                                                    
                                                    <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                        <div class="form-group">
                                                            <label>Frequency of Diaper</label>
                                                            <div type="text" readonly>{{ dataonzeroposition?.diaper_change_frequency ?? '-'
                                            }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Change Time of Diaper</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of diaper_Frequency let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.diaper_label)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> -->

                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares -->
                                        <p-accordionTab header="Skin Care">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Skin Cares</h3> -->
                                                <div class="my-3"></div>
                                                <div class="row" *ngFor="let care of skin">
                                                    <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Skin care product for baby:</label>
                                                            <div type="text"> {{
                                                                (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                                (care?.skin_care_lotion == 1 &&
                                                                care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                                (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                                (care?.skin_care_others == 1 &&
                                                                (care?.skin_care_lotion == 1 ||
                                                                care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                                (care?.skin_care_others == 1 ? 'Others' : '')
                                                                }}</div>

                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group viewSummary">
                                                            <label>Specify Product:</label>
                                                            <div type="text" readonly>{{ care?.skin_care_specify_produ
                                                                ?care?. skin_care_specify_produ:'-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares End-->

                                        <!-- Wipes -->
                                        <p-accordionTab header="Wipes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Wipes</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <!-- <div class="form-group">
                        <label>Wipes</label>
                        <input type="text" readonly class="form-control" value="{{ viewDetail?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                        }}" />
                    </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Wipes</label>
                                                                <div type="text" readonly>

                                                                    {{ dataonzeroposition?.skin_care_Wipes == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <div type="text" readonly>
                                                                        {{"-"}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label>Special Instruction</label>
                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.skin_care_special_instructions ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Wipes End-->

                                        <!-- Changing Clothes -->
                                        <p-accordionTab header="Changing Clothes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Changing Clothes</h3> -->
                                                <div class="my-3">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>Special Instructions</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                dataonzeroposition?.changing_colthes_spiecal_instructions
                                                                ?? '-'}}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Changing Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of clothes let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.Changing_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Changing Clothes End-->

                                        <!-- Sleeping -->
                                        <p-accordionTab header="Sleeping">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Sleeping</h3> -->
                                                    <div class="row" *ngFor="let sleeping of sleepingmother">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label>With Mother Sleeping Time</label>

                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <!-- <label for="">Sleeping Time</label> -->
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleepingmother let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_mother_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label>With Nurse Sleeping Time</label>

                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <!-- <label for="">Sleeping Time</label> -->
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleeping_nurse let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_nanny_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping End-->

                                        <!-- Temperature Monitoring -->
                                        <!-- <p-accordionTab header="Temperature Monitoring">
                    <h3>Temperature Monitoring</h3>
                    <div class="row">
                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                            <div class="form-group">
                                <label>Frequency</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.temperature_monitoring_frequency?? '-'
                        }}" />
                            </div>
                        </div>
                    </div>
                </p-accordionTab> -->
                                        <!-- Temperature Monitoring End-->

                                        <!-- Vitamins/Medications -->
                                        <p-accordionTab header="Vitamins">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <div class="my-3">
                                                    <!-- <h3>Vitamins/Medications</h3> -->
                                                    <div class="row" *ngFor="let vitamin of vitamins">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">


                                                                <label>Medications</label>
                                                                <div type="text" readonly>
                                                                    {{ vitamin?.vitamin ? vitamin?.vitamin : '-'}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label>Frequency</label>
                                                                <div type="text" readonly>{{ vitamin?.frequency ?
                                                                    vitamin?.frequency : '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <label for="">Time of Medications</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let times of vitamin.medicine_time let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox" readonly>
                                                                        {{ formatDateTime(times?.time) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Vitamins/Medications End-->

                                        <!-- Vaccinations -->
                                        <!-- <p-accordionTab header="Vaccinations">
                    <h3>Vaccinations</h3>
                    <div class="row" *ngFor="let vaccine of vaccination">
                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                            <div class="form-group">
                                <label>Vaccinations</label>
                                <input type="text" readonly class="form-control" value="{{ vaccine?.vaccination ? vaccine?.vaccination: '-'
                        }}" />
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                            <div class="form-group">
                                <label>Date of Administration</label>
                                <input type="text" readonly class="form-control" value="{{ vaccine?.date_administration ? (vaccine?.date_administration | date: 'dd/MM/yyyy' ): '-'
                        }}" />
                            </div>
                        </div>
                    </div>
                </p-accordionTab> -->

                                        <!-- Vaccinations End-->

                                        <!-- Remark -->
                                        <p-accordionTab header="Remark">

                                            <div class="row peach-bg">
                                                <!-- <h3>Remarks</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">

                                                            <div type="text" readonly>{{ dataonzeroposition?.remarks ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                    <!-- Remark End-->
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
<div class="row">
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>created at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.created_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>updated at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.updated_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>updated at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.updated_at ?? '-' }}" />
    </div>
</div>

</div>
    <button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
        tooltipPosition="bottom" (click)="editOnClick(edit)"></button>
    <p-button icon="pi pi-check" (click)="displayMaximizable = false" label="Ok"
        styleClass="p-button-text"></p-button>


</ng-template> -->

                <!-- <ng-template pTemplate="footer" let-list let-i="rowIndex">
<div class="row">
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>created at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.created_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>updated at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.updated_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>updated at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.updated_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">

    <div class="form-group">
        <label>updated at</label>
        <input type="text" readonly class="form-control"
            value="{{ viewDetail?.updated_at ?? '-' }}" />
    </div>
</div>
<div class="col-xl-2 col-lg-12
col-md-12">
    <button pButton pRipple class="p-button-rounded p-button-icon bx bx-pencil" pTooltip="Edit"
        tooltipPosition="bottom" (click)="editOnCLick(edit)">
    </button>
    <p-button icon="pi pi-check" (click)="displayMaximizable=
    false" label="Ok" styleClass="p-button-text"></p-button>
</div>
</div>
</ng-template> -->

                <ng-template pTemplate="footer" let-list let-i="rowIndex">
                    <div class="row ">
                        <div class="col-md">
                            <table class="table-stamp w-100 text-start">
                                <tr class="row">
                                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{
                                        viewDetail?.created_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Created Date&Time:</b>
                                        {{ viewDetail.created_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{
                                        viewDetail?.updated_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated Date&Time:</b>
                                        {{ viewDetail.updated_at.substring(0, 16).replace('T', ' ') }}

                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-auto"> <p-button (click)="softReload(); displayMaximizable=false;" label="Ok"
                                styleClass="p-button-text"></p-button> </div>
                    </div>


                </ng-template>



                <!-- <ng-template pTemplate="footer">

</ng-template> -->
            </p-dialog>
            <!----------------------------------------------------------- view 4m-1y dialog --------------------------------------------------------->
            <p-dialog header="Four Months to One Year
Baby Details" [(visible)]="displayMaximizable1" [modal]="true" [style]="{ width: '90vw' }" [draggable]="false"
                [closable]="false" [resizable]="false">
                <!-- Start -->
                <ng-container>
                    <div class="row viewSummary ">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <form>
                                    <!-- Baby detail -->
                                    <!-- accordion on listing -->
                                    <p-accordion>
                                        <p-accordionTab header="Baby Details">
                                            <!-- <div class="add-listings-box py-0">
                                                <h3>Baby Details</h3> -->
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Name :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.name ?? '-' | titlecase }}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.name ?? '-' |
                                                                    titlecase
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label>Gender :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.gender ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.gender ?? '-' |
                                                                    titlecase }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Date of Birth :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.date_of_birth ?? '-'}}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.date_of_birth ??
                                                                    '-' |
                                                                    titlecase }}</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Days :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.days ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.days ?? '-' |
                                                                    titlecase
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Months :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.months ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.months ?? '-' |
                                                                    titlecase }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Years :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            value="{{viewDetail?.years ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.years ?? '-'}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Food/Medicine Allergy:</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                        *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock"
                                                        value="{{ dataonzeroposition?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock">
                                                                        {{dataonzeroposition?.food_medicine_allergy ==
                                                                        '1'?
                                                                        'Yes': 'No'
                                                                        }}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <input type="text" readonly class="form-control"
                                                                            value="-" />
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component:</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy_specify_prod ?? '-'
                    }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.food_medicine_allergy_specify_prod
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div> -->
                                        </p-accordionTab>



                                        <p-accordionTab header="Feeding Assesment">
                                            <!-- feeding assesment listing  -->

                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <!-- <label for="">Baby is not interested when offered to
                                                                breastfeed,
                                                                sleepy</label> -->
                                                                <label for="">Baby shows disinterest in breastfeeding
                                                                    when
                                                                    sleepy</label>

                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.answer?? '-'
                                                                      }}" /> -->
                                                                <div type="text" readonly>{{dataonzeroposition?.answer
                                                                    ??
                                                                    '-' | titlecase }}</div>

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <!-- <label for="">Baby is showing feeding cues but not
                                                                attaching</label> -->
                                                                <label for="">Baby exhibits hunger signs but isn't
                                                                    latching
                                                                    on</label>

                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.attach??'-'
                                                                       }}" /> -->
                                                                <div type="text" readonly>{{dataonzeroposition?.attach??
                                                                    '-' | titlecase }}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <!-- <label for="">Attaches at the breast but quickly falls
                                                            asleep</label> -->
                                                                <label for="">Baby latches onto the breast but quickly
                                                                    falls
                                                                    asleep</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Asleep?? '-'
                                                              }}" /> -->
                                                                <div type="text" readonly>{{dataonzeroposition?.Asleep??
                                                                    '-' | titlecase }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                     col-md-12">
                                                            <div class="form-group">
                                                                <!-- <label for="">Attaches for short bursts with long
                                                            pause</label> -->
                                                                <label for="">Baby briefly attaches with prolonged
                                                                    breaks</label>

                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.LongPause??'-'
                                                            }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.LongPause??
                                                                    '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches well with long rhythmical sucking
                                                                    and
                                                                    swallowing
                                                                    for a short feed</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.rhythmical?? '-'
                                                    }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.rhythmical??
                                                                    '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Attaches well for a sustained period with
                                                                    long
                                                                    rhythmical
                                                                    sucking and swallowing</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.swallowing??'-'
                                                            }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.swallowing??
                                                                    '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-6 col-lg-12
                                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Normal skin color and tone</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Normal_skin?? '-'
                                                              }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.Normal_skin??
                                                                    '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-6 col-lg-12
                                                         col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Gaining weight appropriately</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.weight??'-'
                                                               }}" /> -->

                                                                <div type="text" readonly>{{dataonzeroposition?.weight??
                                                                    '-' | titlecase }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6 col-lg-12
                                                                           col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Other Concerns</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.Other_concerns??'-'
                                                          }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.Other_concerns??
                                                                    '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <p-accordionTab header="Daily Cares">

                                            <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                            <div class="row viewSummary  peach-bg">
                                                <div class="my-3">
                                                    <!-- <label for=""></label> -->
                                                    <div type="text">Feeding:Prepared by
                                                        {{
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 ? 'Mother' :
                                                        '') +
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 &&
                                                        dataonzeroposition?.feeding_prepared_nanny == 1 ? ' and ' : '')
                                                        +
                                                        (dataonzeroposition?.feeding_prepared_nanny == 1 ? 'Nurse' : '')
                                                        +
                                                        (dataonzeroposition?.feeding_prepared_other == 1 &&
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 ||
                                                        dataonzeroposition?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                        (dataonzeroposition?.feeding_prepared_other == 1 ? 'Other' : '')
                                                        }}
                                                    </div>
                                                </div>
                                            </div>

                                        </p-accordionTab>
                                        <!-- MILK -->
                                        <p-accordionTab header="Milk Feeding">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <label class="my-3">Breast Feeding Information Summary</label>
                                                <!-- <h3>Milk</h3> -->
                                                <div class="my-3">
                                                    <div class="row">
                                                        <!-- <label class="subHeading my-2">Breast Feeding</label> -->
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Is Select </label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_breast== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.feeding_breast== 1? 'Yes': '-'
                                                                    ??
                                                                    '-' | titlecase }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_frequency_breast ?? '-'
                        }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.feeding_frequency_breast ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Breast Feeding Remark:</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.type_of_formula ?? '-'
                                                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.breast_remark ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <table class="table">
                                                    <tr>
                                                        <th>Breast</th>
                                                        <th>Frequency</th>
                                                        <th>Breast Feeding Remark</th>
                                                    </tr>
                                                    <tr>
                                                        <td>{{ dataonzeroposition?.feeding_breast == 1 ? 'Yes' : '-' }}</td>
                                                        <td>{{ dataonzeroposition?.feeding_frequency_breast ?? '-' }}</td>
                                                        <td>{{ dataonzeroposition?.breast_remark ?? '-' }}</td>
                                                    </tr>
                                                </table> -->
                                                    <div class="row">
                                                        <label for="">Breast Feeding Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of breast_feeding let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.feeding_breast_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row peach-bg my-4">
                                                <label class="my-3">Formula Feeding Information Summary</label>
                                                <div class="row">
                                                    <div class="my-4">
                                                        <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                        <div class="col-12 mb-4">
                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Formula</label>
                                                                <div type="text" readonly>
                                                                    {{ dataonzeroposition?.feeding_type == 1 ? 'Yes' :
                                                                    '-' ?? '-' }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Frequency</label>
                                                                <div type="text" readonly>
                                                                    {{ dataonzeroposition?.feeding_frequency ?? '-' }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Start Time</label>
                                                                <div type="text" readonly>
                                                                    {{ formatDateTime(dataonzeroposition?.feeding_time)
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">End Time</label>
                                                                <div type="text" readonly>
                                                                    {{
                                                                    formatDateTime(dataonzeroposition?.formula_feeding_time)
                                                                    }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Amount</label>
                                                                <div type="text" readonly>
                                                                    {{ dataonzeroposition?.feeding_amount_per ?? '-' }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Type of Formula</label>
                                                                <div type="text" readonly>
                                                                    {{ dataonzeroposition?.type_of_formula ?? '-' }}
                                                                </div>
                                                            </div>

                                                            <div class="form-group d-inline-block mx-4">
                                                                <label for="">Formula Feeding</label>
                                                                <div type="text" readonly>
                                                                    {{ dataonzeroposition?.formula_remark ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Formula Feeding Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let data of bottle let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(data?.formula_timeArray)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </p-accordionTab>
                                        <!-- MILK END-->

                                        <!-- Bottle Sterilization -->
                                        <p-accordionTab header="Bottle Sterilization">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Bottle Sterilization</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">

                                                            <div class="form-group">
                                                                <label for="">All</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bottle_sterilization_all== 1?
                                                                    'Yes':
                                                                    '-' ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Bottle Sterilization All at a Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">One At A time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bottle_sterilization_onetime==
                                                                    1?
                                                                    'Yes': '-' ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Bottle Sterilization One Reference
                                                                    Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                                                        [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(dataonzeroposition?.bottle_sterilization_one_at_a_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label for="">Bottle Sterilization One at a Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let data of stellrization let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(data?.sterilization_timeArray)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bottle Sterilization End-->

                                        <!--Solid -->
                                        <p-accordionTab header="Solid Food">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Solid</h3> -->
                                                <div class="my-3">
                                                    <!-- Snack -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Snack</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ?? '-'
                    }}" /> -->

                                                                <!-- <div type="text" readonly>{{
                                                                dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ??
                                                                '-'
                                                                }}</div> -->

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(dataonzeroposition?.solid_other_snack_time)" /> -->

                                                                <div type="text" readonly>{{
                                                                    formatDateTime(dataonzeroposition?.solid_other_snack_time)
                                                                    }}</div>

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.soild_other_snack_food_components ?? '-'
                    }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.soild_other_snack_food_components
                                                                    ??
                                                                    '-'
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Regular Meal -->
                                                    <div class="row" *ngFor="let food of regular">
                                                        <!-- Breakfast -->
                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Breakfast</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_type == 1? 'Yes': '-'?? '-'
                    }}" /> -->

                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_type == 1?
                                                                'Yes': '-'?? '-'
                                                                }}</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss' ): '-'
                    }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_time)}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_food_components ?  food?.regular_meal_food_components:  '-'
                    }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_food_components ?
                                                                    food?.regular_meal_food_components: '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <!-- Lunch -->
                                                    <div class="row" *ngFor="let food of lunch">
                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Lunch</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch== 1? 'Yes': '-' ?? '-'
                    }}" /> -->

                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_lunch== 1?
                                                                'Yes': '-' ?? '-'
                                                                }}</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss' ): '-'
                    }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_lunch_time)}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{  food?.regular_meal_lunch_food_components ?  food?.regular_meal_lunch_food_components:'-'
                    }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_lunch_food_components ?
                                                                    food?.regular_meal_lunch_food_components:'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Dinner -->
                                                    <div class="row" *ngFor="let food of dinner">
                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Dinner</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner== 1? 'Yes': '-' ?? '-'
                    }}" /> -->
                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_dinner== 1?
                                                                'Yes': '-' ?? '-'
                                                                }}</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss' ): '-'
                    }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_dinner_time)}}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components: '-'
                    }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_dinner_food_components ?
                                                                    food?.regular_meal_dinner_food_components: '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Solid End-->
                                        <p-accordionTab header="Food/Medicine Allergy"
                                            [class.allergyhighlight]="dataonzeroposition?.food_medicine_allergy == '1'">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Food/Medicine Allergy</h3> -->
                                                <div class="row my-4">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <!-- <div class="form-group">
                        <label for="">Food/Medicine Allergy</label>
                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                        }}" />
                    </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <div type="text" readonly *ngIf="dataonzeroposition?.food_medicine_allergy !==
                                                        null && dataonzeroposition?.food_medicine_allergy
                                                        !== '' && dataonzeroposition?.food_medicine_allergy
                                                        !== undefined; else elseBlock">

                                                                    {{ dataonzeroposition?.food_medicine_allergy == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <div type="text" readonly>
                                                                        {{"-"}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.food_medicine_allergy_specify_prod??'-'
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>


                                        <!-- Bathing -->
                                        <p-accordionTab header="Bathing">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Bathing</h3> -->
                                                <div class="row">
                                                    <!-- Sponge -->
                                                    <!-- <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Sponge</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_sponge== 1? 'Yes': '-' ?? '-'
                    }}" />

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bathing_sponge== 1? 'Yes': '-' ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Frequency</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_frequency_sponge ?? '-'
                    }}" />
                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bathing_frequency_sponge ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div> -->
                                                    <div class="row my-3">
                                                        <label for="">Time of Sponge Bath:</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of bathing  let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.bathing_sponge_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>


                                                <!-- Tub -->
                                                <div class="row">
                                                    <!-- <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Tub</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub == 1? 'Yes': '-'?? '-'
                    }}" />

                                                            <div type="text" readonly>{{ dataonzeroposition?.bathing_tub
                                                                == 1? 'Yes': '-'?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div> -->
                                                    <!-- <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Frequency</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub_frequency ?? '-'
                    }}" />

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bathing_tub_frequency ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div> -->
                                                    <div class="row my-3">
                                                        <label for="">Time of Tub Bath:</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of tub  let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.bathing_tub_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <!-- Shower -->
                                                <!-- <div class="row">
                                                    <div class="col-xl-4 col-lg-12
                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Shower</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower== 1? 'Yes': '-' ?? '-'
                    }}" />

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bathing_shower== 1? 'Yes': '-' ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Frequency</label>
                                                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower_frequency ?? '-'
                    }}" />

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.bathing_shower_frequency ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div>

                                                </div> -->
                                                <div class="row my-3">
                                                    <label for="">Time of Shower Bath:</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let time of shower let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(time?.bathing_shower_time)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </p-accordionTab>
                                        <!-- Bathing End -->
                                        <p-accordionTab header="Daiper Change">
                                            <div class="row peach-bg">
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                    <div class="col-12 mb-4">
                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Diaper Change</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_type == 1 ? 'Yes' : '-' ??
                                                                '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_change_frequency ??'-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(dataonzeroposition?.diaper_label) }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                formatDateTime(dataonzeroposition?.diaper_label_end_time)
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="">Diaper Time</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let data of diaper let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(data?.diaper_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="my-4">
                                                    
                                                    <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                        <div class="form-group">
                                                            <label>Frequency of Diaper</label>
                                                            <div type="text" readonly>{{ dataonzeroposition?.diaper_change_frequency ?? '-'
                                            }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Change Time of Diaper</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of diaper_Frequency let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.diaper_label)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> -->

                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares -->
                                        <p-accordionTab header="Skin Cares">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Skin Cares</h3> -->
                                                    <div class="row" *ngFor="let care of skin ">

                                                        <div class=" col-lg-4
                  ">
                                                            <div class="form-group viewSummary">
                                                                <label for="">Skin care product for baby:</label>
                                                                <div type="text"> {{
                                                                    (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                                    (care?.skin_care_lotion == 1 &&
                                                                    care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                                    (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                                    (care?.skin_care_others == 1 &&
                                                                    (care?.skin_care_lotion == 1 ||
                                                                    care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                                    (care?.skin_care_others == 1 ? 'Others' : '')
                                                                    }}</div>

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label>Specify Product</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ care?.skin_care_specify_produ ? care?.skin_care_specify_produ :'-'
                    }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    care?.skin_care_specify_produ ?
                                                                    care?.skin_care_specify_produ :'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares End-->

                                        <!-- Wipes -->
                                        <p-accordionTab header="Wipes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Wipes</h3> -->
                                                <div class="row my-4">
                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <!-- <div class="form-group">
                        <label>Wipes</label>
                        <input type="text" readonly class="form-control" value="{{ data?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                    }}" />
                    </div> -->

                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Wipes</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== ''  && dataonzeroposition?.skin_care_Wipes !== undefined; else elseBlock"
                                                        value="{{ dataonzeroposition?.skin_care_Wipes == '1'? 'Yes': 'No'}}" /> -->

                                                                <div type="text" readonly
                                                                    *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== ''  && dataonzeroposition?.skin_care_Wipes !== undefined; else elseBlock">
                                                                    {{ dataonzeroposition?.skin_care_Wipes == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <input type="text" readonly class="form-control"
                                                                        value="-" />
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                        <div class="form-group">
                                                            <label>Special Instruction</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.skin_care_special_instructions ?? '-'
                    }}" /> -->

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.skin_care_special_instructions ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Wipes End-->

                                        <!-- Changing Clothes -->
                                        <p-accordionTab header="Changing Clothes">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Changing Clothes</h3>   -->
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>Special Instructions</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                dataonzeroposition?.changing_colthes_spiecal_instructions
                                                                ?? '-'}}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Changing Time:</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of clothes let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.Changing_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Changing Clothes End-->

                                        <!-- Sleeping -->
                                        <p-accordionTab header="Sleeping">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Sleeping</h3> -->
                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-1 col-md-12">
                                                        <div class="form-group">
                                                            <label>With Mother</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row">
                                                            <label for="">With Mother Sleeping Time:-</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleepingmother let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_mother_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label>With Nurse</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row my-1">
                                                            <label for="">With Nurse Sleeping Time:-</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleeping_nurse let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_nanny_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping End-->

                                        <!-- Sleep Training-->
                                        <p-accordionTab header="Sleep Training">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Sleep Training</h3> -->
                                                    <div class="row">
                                                        <!-- <label class="subHeading my-2">Assesment</label> -->
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Sleep Training
                                                                </label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.sleep_train?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.sleep_train?? '-'
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Wake up time (morning)</label>
                                                                <!-- <input type="text" readonly class="form-control" [value]="formatDateTime( dataonzeroposition?.walk_time)
                        " /> -->

                                                                <div type="text" readonly>{{formatDateTime(
                                                                    dataonzeroposition?.walk_time)
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Nap time</label>
                                                                <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (dataonzeroposition?.nap_time)
                            " /> -->

                                                                <div type="text" readonly>{{formatDateTime
                                                                    (dataonzeroposition?.nap_time)
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Sleep time (evening)</label>
                                                                <!-- <input type="text" readonly class="form-control" [value]="formatDateTime (dataonzeroposition?.sleep_time)
                        " /> -->

                                                                <div type="text" readonly>{{formatDateTime
                                                                    (dataonzeroposition?.sleep_time)
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Stroller</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.stroller??'-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.stroller??'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Can the baby get himself back to sleep
                                                                    when
                                                                    interrupted
                                                                    during short naps?</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.interrupt?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.interrupt??
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Needs dummy to help soothe the baby during
                                                                    sleep</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.need_dummy??'-'
                            }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.need_dummy??'-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Pacifier</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.pacifier?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.pacifier??
                                                                    '-'
                                                                    }}
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Songs before sleep
                                                                </label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.song_sleep??'-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.song_sleep??'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Reading Sleep</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.reading_sleep??'-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.reading_sleep??'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Ponits End -->

                                        <!-- Temperature Monitoring -->
                                        <p-accordionTab header="Vitamins/Medications">
                                            <!-- <h3>Temperature Monitoring</h3>
                            <div class="row">
                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                    <div class="form-group">
                                        <label>Frequency</label>
                                        <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.temperature_monitoring_frequency ??  '-'
                        }}" />
                                    </div>
                                </div>
                            </div> -->
                                            <!-- Temperature Monitoring End-->

                                            <!-- Vitamins/Medications -->
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Vitamins/Medications</h3> -->
                                                    <div class="row" *ngFor="let vitamin of vitamins">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label>Medications</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.vitamin ? vitamin?.vitamin : '-'
                        }}" /> -->

                                                                <div type="text" readonly>{{ vitamin?.vitamin ?
                                                                    vitamin?.vitamin : '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label>Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.frequency ? vitamin?.frequency : '-'
                                                        }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{ vitamin?.frequency ? vitamin?.frequency : '-' }}
                                                                </div>

                                                            </div>
                                                        </div>



                                                        <div class="row">
                                                            <label for="">Time of Medications</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let times of vitamin.medicine_time let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox" readonly>
                                                                        {{ formatDateTime(times?.time) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Vitamins/Medications End-->

                                                    <!-- Vaccinations -->
                                                    <!-- <h3>Vaccinations</h3>
                            <div class="row" *ngFor="let vaccine of vaccination">
                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                    <div class="form-group">
                                        <label>Vaccinations</label>
                                        <input type="text" readonly class="form-control" value="{{ vaccine?.vaccination ? vaccine?.vaccination :'-'
                        }}" />
                                    </div>
                                </div>

                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                    <div class="form-group">
                                        <label>Date of Administration</label>
                                        <input type="text" readonly class="form-control" value="{{ vaccine?.date_administration ?(vaccine?.date_administration | date: 'dd/MM/yyyy'): '-'
                        }}" />
                                    </div>
                                </div>
                            </div> -->
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Vaccinations End-->

                                        <!-- Remark -->
                                        <p-accordionTab header="Remark">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Remark</h3> -->
                                                <div class="my-3">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.remarks ?? '-'
                        }}" /> -->
                                                                <div type="text" readonly>{{ dataonzeroposition?.remarks
                                                                    ??
                                                                    '-'
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Remark End-->
                                    </p-accordion>
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-template pTemplate="footer" let-list let-i="rowIndex">
                    <div class="row ">
                        <div class="col-md">
                            <table class="table-stamp w-100 text-start">
                                <tr class="row">
                                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{
                                        viewDetail?.created_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Created
                                            Date&Time:</b>{{ viewDetail.created_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{
                                        viewDetail?.updated_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated
                                            Date&Time:</b>{{ viewDetail.updated_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-auto"> <p-button (click)="softReload(); displayMaximizable1=false;"
                                label="Ok" styleClass="p-button-text"></p-button> </div>
                    </div>


                </ng-template>
            </p-dialog>
            <!----------------------------------------------------------- one to three year --------------------------------------------------------->
            <!-- ***************************************************************************************************************** -->
            <p-dialog header="One Year to Three Years Baby Details" [(visible)]="displayMaximizable2" [modal]="true"
                [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false" [closable]="false">
                <!-- Start -->
                <ng-container>
                    <div class="row viewSummary ">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <form>
                                    <!-- Baby detail -->
                                    <!-- accordian on listing -->
                                    <p-accordion>
                                        <p-accordionTab header="Baby Details">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3>Baby Details</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Name :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.name ?? '-' | titlecase }}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.name ?? '-' |
                                                                    titlecase
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Gender :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.gender ?? '-'}}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.gender ?? '-' |
                                                                    titlecase }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Date of Birth :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.date_of_birth ?? '-'}}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.date_of_birth ??
                                                                    '-' |
                                                                    titlecase }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Days :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.days ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.days ?? '-' |
                                                                    titlecase
                                                                    }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Months :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.months ?? '-'}}" /> -->

                                                                <div type="text" readonly>{{viewDetail?.months ?? '-' |
                                                                    titlecase }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label>Years :</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        value="{{viewDetail?.years ?? '-'}}" /> -->
                                                                <div type="text" readonly>{{viewDetail?.years ?? '-'}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Food/Medicine Allergy:</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock"
                                                            value="{{ dataonzeroposition?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock">
                                                                        {{dataonzeroposition?.food_medicine_allergy ==
                                                                        '1'?
                                                                        'Yes': 'No'
                                                                        }}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <input type="text" readonly class="form-control"
                                                                            value="-" />
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component:</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy_specify_prod ?? '-'
                        }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.food_medicine_allergy_specify_prod
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- DAILY CARE -->

                                        <p-accordionTab header="Daily Cares">

                                            <div class="row viewSummary peach-bg">
                                                <div class="my-3">
                                                    <!-- <label for=""></label> -->
                                                    <div type="text">
                                                        Feeding:Prepared by
                                                        {{
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 ? 'Mother' :
                                                        '') +
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 &&
                                                        dataonzeroposition?.feeding_prepared_nanny == 1 ? ' and ' : '')
                                                        +
                                                        (dataonzeroposition?.feeding_prepared_nanny == 1 ? 'Nurse' : '')
                                                        +
                                                        (dataonzeroposition?.feeding_prepared_other == 1 &&
                                                        (dataonzeroposition?.feeding_prepared_mother == 1 ||
                                                        dataonzeroposition?.feeding_prepared_nanny == 1) ? ', ' : '') +
                                                        (dataonzeroposition?.feeding_prepared_other == 1 ? 'Other' : '')
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- DAILY CARE END -->

                                        <!-- MILK -->
                                        <p-accordionTab header="Milk">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Milk</h3> -->
                                                    <div class="row">
                                                        <label>Breast Feeding Summary</label>
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Is Select </label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_breast== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.feeding_breast== 1? 'Yes': '-'
                                                                    ??
                                                                    '-' | titlecase }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.feeding_frequency_breast ?? '-'
                        }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.feeding_frequency_breast ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Breast Feeding Remark:</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.type_of_formula ?? '-'
                                                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.breast_remark ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <label for="">Breast Feeding Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of breast_feeding let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.feeding_breast_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-xl-4 col-lg-12
                            col-md-12"  *ngIf="dataonzeroposition?.feeding_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>

                                <input type="text" readonly class="form-control" value=" {{ dataonzeroposition?.feeding_breast_time ?? '-'
                            }}" />
                            </div>
                        </div> -->
                                            <div class="row peach-bg my-3">


                                                <div class="row">
                                                    <label class="my-3">Formula Feeding Summary</label>
                                                    <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                    <div class="col-12 mb-4">
                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Formula</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_type == 1 ? 'Yes' : '-'
                                                                ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_frequency ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(dataonzeroposition?.feeding_time) }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                formatDateTime(dataonzeroposition?.feeding_formula_time_end)
                                                                }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Amount</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.feeding_amount_per ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Type of Formula</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.type_of_formula ?? '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Formula Feeding</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.formula_remark ?? '-' }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="">Formula Feeding Time</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let data of bottle let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(data?.formula_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </p-accordionTab>
                                        <!-- MILK END-->

                                        <!-- Bottle Sterilization -->
                                        <p-accordionTab header="Bottle Sterilization">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Bottle Sterilization</h3> -->
                                                <div class="my-4">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">All</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_all== 1? 'Yes': '-' ?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.all_time== 1? 'Yes':
                                                                    '-' ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Bottle Sterilization All at a Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                    [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(dataonzeroposition?.bottle_sterilization_all)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">One At A time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bottle_sterilization_onetime== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.onetime== 1?
                                                                    'Yes': '-' ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Bottle Sterilization One Reference
                                                                    Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                                                        [value]="formatDateTime(dataonzeroposition?.bottle_sterilization_all_time)" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(dataonzeroposition?.bottle_sterilization_one_at_a_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label for="">Bottle Sterilization One at a Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let data of stellrization let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(data?.sterilization_timeArray)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bottle Sterilization End-->

                                        <!--Solid -->
                                        <p-accordionTab header="Solid Food">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Solid</h3> -->
                                                <!-- Snack -->
                                                <div class="my-3">


                                                    <!-- Regular Meal -->
                                                    <div class="row" *ngFor="let food of regular">
                                                        <!-- Breakfast -->
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Breakfast</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            [value]="food?.regular_meal_type ? 'Yes' : '-'"> -->

                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_type == 1?
                                                                'Yes': '-'?? '-'
                                                                }}</div> -->

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_time ? (food?.regular_meal_time | date: 'h:mm:ss' ): '-'
                            }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_time)}}
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-xl-4 col-lg-12
                                col-md-12" *ngIf="dataonzeroposition?.regular_meal_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_time ?? '-'
                                }}" />

                            </div>
                        </div> -->

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_food_components ?  food?.regular_meal_food_components:  '-'
                                }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_food_components ?
                                                                    food?.regular_meal_food_components: '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                    </div>


                                                    <!-- Lunch -->
                                                    <div class="row" *ngFor="let food of lunch">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Lunch</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            [value]="food?.regular_meal_lunch ? 'Yes' : '-'"> -->
                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_lunch== 1?
                                                                'Yes': '-' ?? '-'
                                                                }}</div> -->

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_lunch_time ? (food?.regular_meal_lunch_time | date: 'h:mm:ss' ): '-'
                            }}" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_lunch_time)}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12" *ngIf="dataonzeroposition?.regular_meal_lunch_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_lunch_time ?? '-'
                        }}" />

                            </div>
                        </div> -->


                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{  food?.regular_meal_lunch_food_components ?  food?.regular_meal_lunch_food_components:'-'
                                }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_lunch_food_components ?
                                                                    food?.regular_meal_lunch_food_components:'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Snack</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ?? '-'
                                }}" /> -->

                                                                <!-- <div type="text" readonly>{{
                                                                dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ??
                                                                '-'
                                                                }}</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                            [value]="formatDateTime(dataonzeroposition?.solid_other_snack_time)" /> -->
                                                                <div type="text" readonly>{{
                                                                    formatDateTime(dataonzeroposition?.solid_other_snack_time)
                                                                    }}</div>

                                                            </div>
                                                        </div>

                                                        <!-- <div class="col-xl-4 col-lg-12
                            col-md-12" *ngIf="dataonzeroposition?.solid_other_snack_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack_time ?? '-'
                                }}" />
                            </div>
                        </div> -->

                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.soild_other_snack_food_components ?? '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.soild_other_snack_food_components
                                                                    ??
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Dinner -->
                                                    <div class="row" *ngFor="let food of dinner">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Dinner</label>

                                                                <!-- <input type="text" readonly class="form-control"
                                                            [value]="food?.regular_meal_dinner ? 'Yes' : '-'"> -->
                                                                <!-- <div type="text" readonly>{{ food?.regular_meal_dinner== 1?
                                                                'Yes': '-' ?? '-'
                                                                }}</div> -->
                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Time</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_time ? (food?.regular_meal_dinner_time | date: 'h:mm:ss' ): '-'
                            }}" /> -->
                                                                <div type="text" readonly>
                                                                    {{formatDateTime(food?.regular_meal_dinner_time)}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-4 col-lg-12
                                col-md-12"
                            *ngIf="dataonzeroposition?.regular_meal_dinner_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_dinner_time ?? '-'
                                }}" />

                            </div>
                        </div> -->


                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ food?.regular_meal_dinner_food_components ? food?.regular_meal_dinner_food_components: '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    food?.regular_meal_dinner_food_components ?
                                                                    food?.regular_meal_dinner_food_components: '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Solid End-->

                                        <!-- Food/Medicine Allergy -->
                                        <p-accordionTab header="Food/Medicine Allergy"
                                            [class.allergyhighlight]="dataonzeroposition?.food_medicine_allergy == '1'">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Food/Medicine Allergy</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                        <!-- <div class="form-group">
                                <label for="">Food/Medicine Allergy</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                                *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock"
                                                                value="{{ dataonzeroposition?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->


                                                                <div type="text" readonly
                                                                    *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock">
                                                                    {{dataonzeroposition?.food_medicine_allergy == '1'?
                                                                    'Yes': 'No'
                                                                    }}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                                    value="-" /> -->
                                                                    <div type="text" readonly>-</div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy_specify_prod ?? '-'
                                }}" /> -->

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.food_medicine_allergy_specify_prod
                                                                ?? '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Food/Medicine Allergy End-->
                                        <p-accordionTab header="Daiper Change">
                                            <div class="row peach-bg">
                                                <div class="row">
                                                    <!-- <label class="subHeading my-2">Formula Feeding</label> -->
                                                    <div class="col-12 mb-4">
                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Diaper Change</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_type == 1 ? 'Yes' : '-' ??
                                                                '-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Frequency</label>
                                                            <div type="text" readonly>
                                                                {{ dataonzeroposition?.diaper_change_frequency ??'-' }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">Start Time</label>
                                                            <div type="text" readonly>
                                                                {{ formatDateTime(dataonzeroposition?.diaper_label) }}
                                                            </div>
                                                        </div>

                                                        <div class="form-group d-inline-block mx-4">
                                                            <label for="">End Time</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                formatDateTime(dataonzeroposition?.diaper_label_end_time)
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <label for="">Diaper Time</label>
                                                    <div class="col-md-3 form-group"
                                                        *ngFor="let data of diaper let index = index">
                                                        <div class="input-group2 ">
                                                            <span class="input-group-text "> {{ index+1 }}. </span>
                                                            <div class="formbox">
                                                                {{ formatDateTime(data?.diaper_timeArray)}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div class="my-4">
                                                    
                                                    <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                        <div class="form-group">
                                                            <label>Frequency of Diaper</label>
                                                            <div type="text" readonly>{{ dataonzeroposition?.diaper_change_frequency ?? '-'
                                            }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Change Time of Diaper</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of diaper_Frequency let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.diaper_label)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div> -->

                                            </div>
                                        </p-accordionTab>
                                        <!-- Bathing -->
                                        <p-accordionTab header="Bathing">
                                            <div class="row peach-bg">
                                                <!-- <h3>Bathing </h3> -->
                                                <!-- Tub -->
                                                <div class="my-3">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Tub</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub== 1? 'Yes': '-' ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_tub ==
                                                                    1? 'Yes': '-'?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_tub_frequency ?? '-'
                            }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_tub_frequency ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-xl-4 col-lg-12
                            col-md-12" *ngIf="dataonzeroposition?.bathing_tub_time === '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="-" />

                            </div>
                        </div> -->
                                                        <div class="row">
                                                            <label for="">Time of Tub Bath</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let time of tub  let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{ formatDateTime(time?.bathing_tub_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Shower -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Shower</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower== 1? 'Yes': '-' ?? '-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_shower== 1? 'Yes': '-'
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.bathing_shower_frequency ??'-'
                            }}" /> -->

                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_shower_frequency ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Time of Shower Bath</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of shower let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.bathing_shower_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- <h3>Bathing</h3> -->
                                        <!-- Bathing End -->

                                        <!-- potty training -->
                                        <p-accordionTab header="Potty Training">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3>Potty Training</h3> -->
                                                    <div class="row" *ngFor="let data of training">

                                                        <!-- <div class="form-group">
                                <label for="">Potty Training</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.potty_training== 1? 'Yes': '-' ?? '-'
                            }}" />
                            </div> -->
                                                        <ng-container>
                                                            <div class="col-xl-4 col-lg-12
                                                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Potty Training</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="data?.potty_training !== null && data?.potty_training !== ''  && data?.potty_training !== undefined; else elseBlock"
                                                            value="{{ data?.potty_training == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="data?.potty_training !== null && data?.potty_training !== ''  && data?.potty_training !== undefined; else elseBlock">
                                                                        {{ data?.potty_training == '1'? 'Yes': 'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>

                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-12
                                                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Training</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            value="{{ data?.training_child??'-'}}" /> -->

                                                                    <div type="text" readonly>
                                                                        {{ data?.training_child??'-'}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>


                                                    <div class="row">
                                                        <label for="">Time of Potty Training</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let data of training let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(data?.time_training_child)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- potty training End-->

                                        <!-- Skin Cares -->
                                        <p-accordionTab header="Skin Cares">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Skin Cares</h3> -->
                                                    <div class="row" *ngFor="let care of skin ">
                                                        <div class="form-group viewSummary">
                                                            <label for="">Skin care product for baby:</label>
                                                            <div type="text"> {{
                                                                (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                                (care?.skin_care_lotion == 1 &&
                                                                care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                                (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                                (care?.skin_care_others == 1 &&
                                                                (care?.skin_care_lotion == 1 ||
                                                                care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                                (care?.skin_care_others == 1 ? 'Others' : '')
                                                                }}</div>

                                                        </div>


                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label>Specify Product</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ care?.skin_care_specify_produ ? care?.skin_care_specify_produ :'-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    care?.skin_care_specify_produ ?
                                                                    care?.skin_care_specify_produ :'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares End-->

                                        <!-- Wipes -->
                                        <p-accordionTab header="Wipes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Wipes</h3> -->
                                                <div class="row my-4">
                                                    <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                        <!-- <div class="form-group">
                                <label>Wipes</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Wipes</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                        *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== ''  && dataonzeroposition?.skin_care_Wipes !== undefined; else elseBlock"
                                        value="{{ dataonzeroposition?.skin_care_Wipes == '1'? 'Yes': 'No'}}" />
                                    <ng-template #elseBlock>
                                        <input type="text" readonly class="form-control" value="-" />
                                    </ng-template> -->
                                                                <!-- <input type="text" readonly class="form-control"
                                                                *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== '' && dataonzeroposition?.skin_care_Wipes !== 0; else elseBlock"
                                                                value="{{ dataonzeroposition?.skin_care_Wipes == '1' ? 'Yes' : 'No'}}" /> -->
                                                                <div type="text" readonly
                                                                    *ngIf="dataonzeroposition?.skin_care_Wipes !== null && dataonzeroposition?.skin_care_Wipes !== ''  && dataonzeroposition?.skin_care_Wipes !== undefined; else elseBlock">
                                                                    {{ dataonzeroposition?.skin_care_Wipes == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>

                                                                <ng-template #elseBlock>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                                    value="-" /> -->
                                                                    <div type="text" readonly>-</div>
                                                                </ng-template>

                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                        <div class="form-group">
                                                            <label>Special Instruction</label>
                                                            <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.skin_care_special_instructions ?? '-'
                                }}" /> -->

                                                            <div type="text" readonly>{{
                                                                dataonzeroposition?.skin_care_special_instructions ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Wipes End-->

                                        <!-- Changing Clothes -->
                                        <p-accordionTab header="Changing Clothes">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Changing Clothes</h3> -->
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>Special Instructions</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                dataonzeroposition?.changing_colthes_spiecal_instructions
                                                                ?? '-'}}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Changing Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of clothes let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.Changing_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Changing Clothes End-->
                                        <!-- sleep training -->
                                        <p-accordionTab header=" Sleep Time">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3>Sleep Timing</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">

                                                            <label>Sleep Training
                                                            </label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.sleeping_training_checkbox ??'-'}}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" *ngFor="let time of sleep">
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label> Sleep Time</label>
                                                                <!-- <input type="text" readonly class="form-control"
                                                        [value]="formatDateTime(time?.sleeping_time)" /> -->

                                                                <div type="text" readonly>
                                                                    {{formatDateTime(time?.sleeping_time) }}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                        col-md-12">
                                                            <div class="form-group">
                                                                <label> Sleep Remark</label>

                                                                <div type="text" readonly>
                                                                    {{ time?.sleeping_remark ?? '-'}}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping Independently -->
                                        <p-accordionTab header="Sleeping Independently">


                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3>Sleeping</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">

                                                            <label>Sleeping Independently
                                                            </label>
                                                            <div type="text" readonly> {{
                                                                dataonzeroposition?.sleeping_independently== 1? 'Yes':
                                                                '-' ?? '-'
                                                                }} </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                        <div class="form-group">
                                                            <label>With Mother</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row my-4">
                                                            <label for="">With Mother Sleeping Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleepingmother let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_mother_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                        <div class="form-group">
                                                            <label>With Nurse</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row">
                                                            <label for="">With NurseSleeping Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleeping_nurse let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_nanny_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping Independently End -->

                                        <!-- Temperature Monitoring -->
                                        <p-accordionTab header="Vitamins/Medications">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->

                                                    <!-- Temperature Monitoring End-->

                                                    <!-- Vitamins/Medications -->
                                                    <!-- <h3>Vitamins/Medications</h3> -->
                                                    <div class="row" *ngFor="let vitamin of vitamins">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Medications</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.vitamin ?  vitamin?.vitamin: '-'
                                }}" /> -->

                                                                <div type="text" readonly>{{ vitamin?.vitamin ?
                                                                    vitamin?.vitamin : '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Frequency</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ vitamin?.frequency ? vitamin?.frequency : '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{ vitamin?.frequency ?
                                                                    vitamin?.frequency : '-' }}</div>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label for="">Time of Medications</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let times of vitamin.medicine_time let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox" readonly>
                                                                        {{ formatDateTime(times?.time) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Vitamins/Medications End-->

                                                    <!-- Vaccinations -->
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Vaccinations End-->

                                        <!-- Playing with child outside? -->
                                        <p-accordionTab header="Activities">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3>Activities</h3> -->
                                                    <!-- <h3>Playing with child outside</h3> -->
                                                    <div class="row" *ngFor="let child of playing">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <!-- <div class="form-group">
                                <label for="">Playing with child outside</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail.play_child_outside== 1 'Yes': '-'  '-'
                                }}" />
                            </div> -->
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Playing with child outside</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock"
                                                            value="{{ child?.play_child_outside == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock">
                                                                        {{ child?.play_child_outside == '1'? 'Yes':
                                                                        'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="-" /> -->
                                                                        <div type="text" readonly>-</div>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ child?.play_child_outside_special_Instructions? child?.play_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    child?.play_child_outside_special_Instructions?
                                                                    child?.play_child_outside_special_Instructions: '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <label for="">play child outside time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of playing let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(child?.play_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Playing with child outside? End-->

                                                    <!-- Escort child outside? -->

                                                    <!-- <h3>Escort child outside</h3> -->
                                                    <div class="row" *ngFor="let child of escort">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <!-- <div class="form-group">
                                <label for="">Escort child outside?</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail?.escort_child_outside== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Escort child outside</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock"
                                                            value="{{ child?.escort_child_outside == '1'? 'Yes': 'No'}}" /> -->
                                                                    <div type="text" readonly
                                                                        *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock">
                                                                        {{ child?.escort_child_outside == '1'? 'Yes':
                                                                        'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <!-- <input type="text" readonly class="form-control"
                                                                value="-" /> -->
                                                                        <div readonly>-</div>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ child?.escort_child_outside_special_Instructions?child?.escort_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    child?.escort_child_outside_special_Instructions?
                                                                    child?.escort_child_outside_special_Instructions:
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Escort child outside time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of escort let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{
                                                                    formatDateTime(child?.escort_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Escort child outside? End-->
                                                    <!-- activity name -->
                                                    <!-- <h3>Enter activities name here</h3> -->
                                                    <div class="row" *ngFor="let child of activity">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <!-- <div class="form-group">
                                <label for="">Escort child outside?</label>
                                <input type="text" readonly class="form-control" value="{{ viewDetail?.escort_child_outside== 1? 'Yes': '-' ?? '-'
                                }}" />
                            </div> -->
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Activity</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock"
                                                            value="{{ child?.other_activity == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock">
                                                                        {{ child?.other_activity == '1'? 'Yes': 'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <!-- <input type="text" readonly class="form-control" -->
                                                                        <!-- value="-" /> -->
                                                                        <div>-</div>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Activity Name</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ child?.activities_child_outside_special_Instructions?child?.activities_child_outside_special_Instructions: '-'
                                }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    child?.activities_child_outside_special_Instructions?child?.activities_child_outside_special_Instructions:
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for=""> Outside Activity time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of activity let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(child?.other_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- EscoRemark -->
                                        <p-accordionTab header="Remark">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3>Remarks</h3> -->
                                                    <!-- <h3>Remark</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                                col-md-12">
                                                            <div class="form-group">
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.remarks ?? '-'
                                    }}" /> -->
                                                                <div type="text" readonly>{{dataonzeroposition?.remarks
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                    <!-- Remark End-->
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template pTemplate="footer" let-list let-i="rowIndex">
                    <div class="row ">
                        <div class="col-md">
                            <table class="table-stamp w-100 text-start">
                                <tr class="row">
                                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{
                                        viewDetail?.created_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Created
                                            Date&Time:</b>{{ viewDetail.created_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{
                                        viewDetail?.updated_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated
                                            Date&Time:</b>{{ viewDetail.updated_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-auto"> <p-button label="Ok"
                                (click)="softReload(); displayMaximizable2=false;"
                                styleClass="p-button-text"></p-button> </div>
                    </div>


                </ng-template>
            </p-dialog>
            <!----------------------------------------------------------- three year to above--------------------------------------------------------->
            <!-- ***************************************************************************************************************** -->
            <p-dialog header="Three Years to Above Baby Details" [(visible)]="displayMaximizable3" [modal]="true"
                [style]="{ width: '90vw' }" [draggable]="false" [resizable]="false" [closable]="false">
                <!-- Start -->
                <ng-container>
                    <div class="row viewSummary ">
                        <div class="col-lg-12">
                            <div class="my-profile-box">
                                <form>
                                    <!-- Baby detail -->
                                    <!-- accordian on listing -->
                                    <p-accordion>
                                        <p-accordionTab header="Baby Details">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3>Baby Details</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Name :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.name ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Gender :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.gender ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Date of Birth :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.date_of_birth ?? '-' | titlecase }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Days :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.days ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Months :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.months ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <label>Years :</label>
                                                                <div type="text" readonly>
                                                                    {{viewDetail?.years ?? '-' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Food/Medicine Allergy:</label>
                                                                    <!-- <input type="text" readonly class="form-control"
                                                            *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock"
                                                            value="{{ dataonzeroposition?.food_medicine_allergy == '1'? 'Yes': 'No'}}" /> -->

                                                                    <div type="text" readonly
                                                                        *ngIf="dataonzeroposition?.food_medicine_allergy !== null && dataonzeroposition?.food_medicine_allergy !== '' && dataonzeroposition?.food_medicine_allergy !== undefined; else elseBlock">
                                                                        {{dataonzeroposition?.food_medicine_allergy ==
                                                                        '1'?
                                                                        'Yes': 'No'
                                                                        }}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <input type="text" readonly class="form-control"
                                                                            value="-" />
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Food Component:</label>
                                                                <!-- <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy_specify_prod ?? '-'
                        }}" /> -->
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.food_medicine_allergy_specify_prod
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- DAILY CARE -->

                                        <div class="row">
                                            <p-accordionTab header="Daily Cares">
                                                <div class="row peach-bg">
                                                    <div class="row viewSummary my-4">
                                                        <!-- <label for=""></label> -->
                                                        <div type="text">Feeding:Prepared by
                                                            {{
                                                            (dataonzeroposition?.feeding_prepared_mother == 1 ? 'Mother'
                                                            : '') +
                                                            (dataonzeroposition?.feeding_prepared_mother == 1 &&
                                                            dataonzeroposition?.feeding_prepared_nanny == 1 ? ' and ' :
                                                            '') +
                                                            (dataonzeroposition?.feeding_prepared_nanny == 1 ? 'Nurse' :
                                                            '') +
                                                            (dataonzeroposition?.feeding_prepared_other == 1 &&
                                                            (dataonzeroposition?.feeding_prepared_mother == 1 ||
                                                            dataonzeroposition?.feeding_prepared_nanny == 1) ? ', ' :
                                                            '') +
                                                            (dataonzeroposition?.feeding_prepared_other == 1 ? 'Other' :
                                                            '')
                                                            }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-accordionTab>
                                            <p-accordionTab header="Food Component">
                                                <div class="row peach-bg">
                                                    <div class="my-4">
                                                        <!-- <h3>Food Components</h3> -->
                                                        <!-- Snack -->
                                                        <div class="row">
                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Snack</label>
                                                                    <!-- <div type="text" readonly>{{ dataonzeroposition?.solid_other_snack== 1? 'Yes': '-' ?? '-'
                                                        }}</div> -->
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Time</label>

                                                                    <div type="text" readonly>
                                                                        {{formatDateTime(dataonzeroposition?.solid_other_snack_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-4 col-lg-12
                        col-md-12" *ngIf="dataonzeroposition?.solid_other_snack_time !== '00:00:00'">
                                <div class="form-group">
                                    <label for="">Time</label>
                                    <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.solid_other_snack_time ?? '-'
                            }}" />
                                </div>
                            </div> -->

                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Food Component</label>
                                                                    <div type="text" readonly>{{
                                                                        dataonzeroposition?.soild_other_snack_food_components
                                                                        ?? '-'
                                                                        }}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Regular Meal -->
                                                        <div class="row" *ngFor="let food of regular">
                                                            <!-- Breakfast -->
                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Breakfast</label>
                                                                    <!-- <div type="text" readonly>
                                                                    {{food?.regular_meal_type ? 'Yes' : '-'}}
                                                                </div> -->

                                                                </div>
                                                            </div>

                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Time</label>
                                                                    <div type="text" readonly>
                                                                        {{formatDateTime(food?.regular_meal_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-4 col-lg-12
                        col-md-12"  *ngIf="dataonzeroposition?.regular_meal_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_time ?? '-'
                        }}" />
                            </div>
                        </div> -->

                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Food Component</label>
                                                                    <div type="text" readonly>{{
                                                                        food?.regular_meal_food_components ?
                                                                        food?.regular_meal_food_components: '-'
                                                                        }}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <!-- Lunch -->
                                                        <div class="row" *ngFor="let food of lunch">
                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Lunch</label>
                                                                    <!-- <div type="text" readonly>
                                                                    {{food?.regular_meal_lunch ? 'Yes' : '-'}}
                                                                </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Time</label>
                                                                    <div type="text" readonly>
                                                                        {{formatDateTime(food?.regular_meal_lunch_time)}}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-4 col-lg-12
                            col-md-12" *ngIf="dataonzeroposition?.regular_meal_lunch_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_lunch_time ?? '-'
                                }}" />
                            </div>
                        </div> -->
                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Food Component</label>
                                                                    <div type="text" readonly>{{
                                                                        food?.regular_meal_lunch_food_components ?
                                                                        food?.regular_meal_lunch_food_components: '-'
                                                                        }}</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- Dinner -->
                                                        <div class="row" *ngFor="let food of dinner">
                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Dinner</label>
                                                                    <!-- <div type="text" readonly>
                                                                    {{food?.regular_meal_dinner ? 'Yes' : '-'}}
                                                                </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Time</label>
                                                                    <div type="text" readonly>
                                                                        {{formatDateTime(food?.regular_meal_dinner_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-xl-4 col-lg-12
                        col-md-12" *ngIf="dataonzeroposition?.regular_meal_dinner_time !== '00:00:00'">
                            <div class="form-group">
                                <label for="">Time</label>
                                <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.regular_meal_dinner_time ?? '-'
                                    }}" />
                            </div>
                        </div> -->

                                                            <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Food Component</label>
                                                                    <div type="text" readonly>{{
                                                                        food?.regular_meal_dinner_food_components ?
                                                                        food?.regular_meal_dinner_food_components: '-'
                                                                        }}</div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </p-accordionTab>
                                        </div>

                                        <!-- DAILY CARE END -->
                                        <!-- <p-accordionTab header="Diaper Change">
                                            <div class="col-xl-4 col-lg-12
                                            col-md-12">
                                                <div class="form-group">
                                                    <label>Frequency</label>
                                                    <div type="text" readonly>{{ dataonzeroposition?.diaper_change_frequency ?? '-'
                                            }}</div>
                                                </div>
                                            </div>
                                            <div class="row" *ngFor="let change of diaper_Frequency">
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Diaper Check</label>
                                                        <div type="text" readonly>
                                                            {{ change?.isDiaperChanged ? 'Yes' : 'No' }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Change Time </label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(change?.diaper_label)}}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row" *ngFor="let change of diaper">
                                                <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Diaper Change</label>
                                                        <div type="text" readonly>
                                                            {{ change?.change ? 'Yes' : 'No' }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Change Time </label>
                                                        <div type="text" readonly>
                                                            {{formatDateTime(change?.time)}}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                    <div class="form-group">
                                                        <label>Remark</label>
                                                        <div type="text" readonly>{{ change?.diaper_remark ?? '-'
                            }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab> -->

                                        <!-- Food/Medicine Allergy -->
                                        <p-accordionTab header="Food/Medicine Allergy"
                                            [class.allergyhighlight]="dataonzeroposition?.food_medicine_allergy == '1'">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Food/Medicine Allergy</h3> -->
                                                <div class="row my-4">
                                                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <!-- <div class="form-group">
                            <label for="">Food/Medicine Allergy</label>
                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.food_medicine_allergy== 1? 'Yes': '-' ?? '-'
                            }}" />
                        </div> -->
                                                        <ng-container>
                                                            <div class="form-group">
                                                                <label for="">Food/Medicine Allergy</label>
                                                                <div type="text" readonly *ngIf="dataonzeroposition?.food_medicine_allergy !==
                                                            null && dataonzeroposition?.food_medicine_allergy
                                                            !== '' && dataonzeroposition?.food_medicine_allergy
                                                            !== undefined; else elseBlock">

                                                                    {{ dataonzeroposition?.food_medicine_allergy == '1'?
                                                                    'Yes': 'No'}}
                                                                </div>
                                                                <ng-template #elseBlock>
                                                                    <div type="text" readonly>
                                                                        {{"-"}}
                                                                    </div>
                                                                </ng-template>
                                                            </div>
                                                        </ng-container>
                                                    </div>

                                                    <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Food Component</label>
                                                            <div type="text" readonly>
                                                                {{dataonzeroposition?.food_medicine_allergy_specify_prod??'-'
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Food/Medicine Allergy End-->

                                        <!-- Bathing -->
                                        <p-accordionTab header="Bathing">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Bathing</h3> -->
                                                <!-- Tub -->
                                                <div class="my-3">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Tub</label>
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_tub == 1? 'Yes': '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.bathing_tub_frequency?.frequency??'-'
                                                                    }}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Time of Tub Bath</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of tub  let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.bathing_tub_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Shower -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Shower</label>
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.bathing_shower == 1? 'Yes': '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Frequency</label>
                                                                <div type="text" readonly>
                                                                    {{dataonzeroposition?.bathing_shower_frequency?.frequency??'
                                                                    -' }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="row">
                                                        <label for="">Time of Shower Bath</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let time of shower let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(time?.bathing_shower_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Bathing End -->

                                        <!-- potty training -->
                                        <p-accordionTab header="Potty Training">
                                            <div class="row peach-bg">
                                                <div class="my-3">
                                                    <!-- <h3>Potty Training</h3> -->
                                                    <div class="row" *ngFor="let train of training">
                                                        <div class="col-xl-4 col-lg-12
                    col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Potty Training</label>
                                                                <div type="text" readonly>{{ train?.potty_training== 1?
                                                                    'Yes': '-' ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Special Instructions</label>
                                                                <div type="text" readonly>{{ train?.training_child ??
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <label for="">Time of Potty Training</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let train of training let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(train?.time_training_child)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- potty training End-->

                                        <!-- Skin Cares -->
                                        <p-accordionTab header="Skin Cares">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                    <!-- <h3>Skin Cares</h3> -->
                                                    <div class="row" *ngFor="let care of skin">
                                                        <div class="col-xl-4 col-lg-12
                                                    col-md-12">
                                                            <div class="form-group viewSummary">
                                                                <label for="">Skin care product for baby:</label>
                                                                <div type="text"> {{
                                                                    (care?.skin_care_lotion == 1 ? 'Lotion' : '') +
                                                                    (care?.skin_care_lotion == 1 &&
                                                                    care?.skin_care_baby_oil == 1 ? ' and ' : '') +
                                                                    (care?.skin_care_baby_oil == 1 ? 'Baby Oil' : '') +
                                                                    (care?.skin_care_others == 1 &&
                                                                    (care?.skin_care_lotion == 1 ||
                                                                    care?.skin_care_baby_oil == 1) ? ', ' : '') +
                                                                    (care?.skin_care_others == 1 ? 'Others' : '')
                                                                    }}</div>

                                                            </div>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Specify Product</label>
                                                                <div type="text" readonly>{{
                                                                    care?.skin_care_specify_produ ?care?.
                                                                    skin_care_specify_produ:'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Skin Cares End-->

                                        <!-- Wipes -->
                                        <p-accordionTab header="Wipes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <div class="my-3">
                                                    <!-- <h3>Wipes</h3> -->
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <!-- <div class="form-group">
                            <label>Wipes</label>
                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.skin_care_Wipes== 1? 'Yes': '-' ?? '-'
                            }}" />
                        </div> -->
                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Wipes</label>
                                                                    <div type="text" readonly>

                                                                        {{ dataonzeroposition?.skin_care_Wipes == '1'?
                                                                        'Yes': 'No'}}
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>

                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label>Special Instruction</label>
                                                                <div type="text" readonly>{{
                                                                    dataonzeroposition?.skin_care_special_instructions
                                                                    ?? '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Wipes End-->

                                        <!-- Changing Clothes -->
                                        <!-- <h3>Changing Clothes</h3>
                <div class="row">
                    <div class="col-xl-4 col-lg-12
                        col-md-12">
                        <div class="form-group">
                            <label>Frequency</label>
                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.changing_colthes_frequency ?? '-'
                            }}" />
                        </div>
                    </div> -->
                                        <p-accordionTab header="Changing Clothes">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Changing Clothes</h3> -->
                                                <div class="my-4">


                                                    <div class="col-xl-4 col-lg-12 col-md-12">
                                                        <div class="form-group">
                                                            <label>Special Instructions</label>
                                                            <div type="text" readonly>
                                                                {{
                                                                dataonzeroposition?.changing_colthes_spiecal_instructions
                                                                ?? '-'}}
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Changing Time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let change of clothes let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(change?.Changing_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Changing Clothes End-->


                                        <!-- Sleeping Independently -->
                                        <p-accordionTab header="Sleeping Independently">


                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->
                                                <!-- <h3>Sleeping</h3> -->
                                                <div class="my-3">
                                                    <div class="row">
                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">

                                                            <ng-container>
                                                                <div class="form-group">
                                                                    <label for="">Sleeping Independently</label>
                                                                    <div type="text" readonly
                                                                        *ngIf="dataonzeroposition?.sleeping_independently !== null && dataonzeroposition?.sleeping_independently !== ''  && dataonzeroposition?.sleeping_independently !== undefined; else elseBlock">
                                                                        {{ dataonzeroposition?.sleeping_independently ==
                                                                        '1'? 'Yes': 'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-" }}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label>With Mother</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row">
                                                            <label for="">With Mother Sleeping Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleepingmother let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_mother_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <!-- <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                        <div class="form-group">
                                                            <label>With Nurse</label>

                                                        </div>
                                                    </div> -->


                                                        <div class="row">
                                                            <label for="">With Nurse Sleeping Time</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let sleeping of sleeping_nurse let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox">
                                                                        {{
                                                                        formatDateTime(sleeping?.sleeping_nanny_time)}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Sleeping Independently End -->

                                        <!-- Temperature Monitoring -->
                                        <p-accordionTab header="Vitamins/Medications">
                                            <div class="row peach-bg">
                                                <!-- <h3 class="mb-0">Assesment Information Summary</h3> -->

                                                <!-- Temperature Monitoring End-->

                                                <!-- Vitamins/Medications -->
                                                <!-- <h3>Vitamins/Medications</h3> -->
                                                <div class="my-3">
                                                    <div class="row" *ngFor="let vitamin of vitamins">
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label>Medications</label>
                                                                <div type="text" readonly>{{ vitamin?.vitamin ?
                                                                    vitamin?.vitamin : '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                            <div class="form-group">
                                                                <label>Frequency</label>
                                                                <div type="text" readonly>{{ vitamin?.frequency ?
                                                                    vitamin?.frequency :'-'
                                                                    }}</div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <label for="">Time of Medications</label>
                                                            <div class="col-md-3 form-group"
                                                                *ngFor="let times of vitamin.medicine_time let index = index">
                                                                <div class="input-group2 ">
                                                                    <span class="input-group-text "> {{ index+1 }}.
                                                                    </span>
                                                                    <div class="formbox" readonly>
                                                                        {{ formatDateTime(times?.time) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <!-- Vitamins/Medications End-->

                                                    <!-- Vaccinations -->
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Vaccinations End-->

                                        <!-- Playing with child outside? -->
                                        <p-accordionTab header="Activities">
                                            <div class="row peach-bg">
                                                <div class="my-4">
                                                    <!-- <h3>Activities</h3> -->
                                                    <div class="row" *ngFor="let child of playing">

                                                        <!-- <div class="form-group">
                            <label for="">Playing with child outside?</label>
                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.play_child_outside== 1? 'Yes': '-' ?? '-'
                            }}" />
                        </div> -->
                                                        <ng-container>
                                                            <div class="col-xl-4 col-lg-12
                        col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Playing with child outside</label>
                                                                    <div type="text" readonly
                                                                        *ngIf="child?.play_child_outside !== null && child?.play_child_outside !== ''  && child?.play_child_outside !== undefined; else elseBlock">
                                                                        {{ child?.play_child_outside == '1'? 'Yes':
                                                                        'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </ng-container>


                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <div type="text" readonly>{{
                                                                    child?.play_child_outside_special_Instructions
                                                                    ?child?.play_child_outside_special_Instructions :
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">play child outside time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of playing let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(child?.play_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Playing with child outside? End-->

                                                    <!-- Escort child outside? -->
                                                    <div class="row" *ngFor="let child of escort">

                                                        <ng-container>
                                                            <div class="col-xl-4 col-lg-12
                                                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Escort child outside</label>
                                                                    <div type="text" readonly
                                                                        *ngIf="child?.escort_child_outside !== null && child?.escort_child_outside !== ''  && child?.escort_child_outside !== undefined; else elseBlock">

                                                                        {{ child?.escort_child_outside == '1'? 'Yes':
                                                                        'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </ng-container>


                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <div type="text" readonly>{{
                                                                    child?.escort_child_outside_special_Instructions
                                                                    ?child?.escort_child_outside_special_Instructions :
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">Escort child outside time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of escort let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{
                                                                    formatDateTime(child?.escort_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- Escort child outside? End-->

                                                    <!-- Escort child to school? -->
                                                    <div class="row" *ngFor="let child of school">

                                                        <!-- <div class="form-group">
                            <label for="">Escort child to school?</label>
                            <input type="text" readonly class="form-control" value="{{ dataonzeroposition?.escort_child_school== 1? 'Yes': '-' ?? '-'
                            }}" />
                        </div> -->
                                                        <ng-container>
                                                            <div class="col-xl-4 col-lg-12
                                                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Escort child to school</label>
                                                                    <div type="text" readonly
                                                                        *ngIf="child?.escort_child_school !== null && child?.escort_child_school !== ''  && child?.escort_child_school !== undefined; else elseBlock">

                                                                        {{ child?.escort_child_school == '1'? 'Yes':
                                                                        'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </ng-container>


                                                        <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <div type="text" readonly>{{
                                                                    child?.escort_child_school_special_instructions
                                                                    ?child?.escort_child_school_special_instructions :
                                                                    '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <label for="">School time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of school let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{
                                                                    formatDateTime(child?.school_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="row" *ngFor="let child of activity">



                                                        <ng-container>
                                                            <div class="col-xl-4 col-lg-12
                                                            col-md-12">
                                                                <div class="form-group">
                                                                    <label for="">Other activities</label>
                                                                    <div type="text" readonly
                                                                        *ngIf="child?.other_activity !== null && child?.other_activity !== ''  && child?.other_activity !== undefined; else elseBlock">

                                                                        {{ child?.other_activity == '1'? 'Yes': 'No'}}
                                                                    </div>
                                                                    <ng-template #elseBlock>
                                                                        <div type="text" readonly>
                                                                            {{"-"}}
                                                                        </div>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </ng-container>

                                                        <div class="col-xl-4 col-lg-12
                                                col-md-12">
                                                            <div class="form-group">
                                                                <label for="">Special Instructions</label>
                                                                <div type="text" readonly>{{
                                                                    child?.activities_child_outside_special_Instructions
                                                                    ?child?.activities_child_outside_special_Instructions
                                                                    : '-'
                                                                    }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-xl-4 col-lg-12
                                           col-md-12">
                                                    <div class="form-group">
                                                        <label for="">Special Instructions</label>
                                                        <div type="text" readonly class="form-control" value="{{ child?.activities_child_outside_special_Instructions ? child?.activities_child_outside_special_Instructions: '-'
                                        }}" />
                                                    </div>
                                                </div> -->
                                                    <div class="row">
                                                        <label for=""> Outside Activity time</label>
                                                        <div class="col-md-3 form-group"
                                                            *ngFor="let child of activity let index = index">
                                                            <div class="input-group2 ">
                                                                <span class="input-group-text "> {{ index+1 }}. </span>
                                                                <div class="formbox">
                                                                    {{ formatDateTime(child?.other_child_outside_time)}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                        <!-- Escort child to school? End-->

                                        <!-- Remark -->
                                        <p-accordionTab header="Remark">
                                            <div class="row peach-bg">
                                                <!-- <h3>Remarks</h3> -->
                                                <!-- <h3>Remark</h3> -->
                                                <div class="row my-3">
                                                    <div class="col-xl-4 col-lg-12
                            col-md-12">
                                                        <div class="form-group">
                                                            <label>Remark</label>
                                                            <div type="text" readonly> {{ dataonzeroposition?.remarks ??
                                                                '-'
                                                                }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </p-accordionTab>
                                    </p-accordion>
                                    <!-- Remark End-->
                                </form>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template pTemplate="footer" let-list let-i="rowIndex">
                    <div class="row ">
                        <div class="col-md">
                            <table class="table-stamp w-100 text-start">
                                <tr class="row">
                                    <td class="col-sm-6 col-lg-3 "><b>Created By:</b> {{
                                        viewDetail?.created_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Created
                                            Date&Time:</b>{{ viewDetail.created_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated By:</b> {{
                                        viewDetail?.updated_by_user_name}}</td>
                                    <td class="col-sm-6 col-lg-3 "><b>Updated
                                            Date&Time:</b>{{ viewDetail.updated_at.substring(0, 16).replace('T', ' ') }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="col-md-auto"> <p-button (click)="softReload(); displayMaximizable3=false;"
                                label="Ok" styleClass="p-button-text"></p-button> </div>
                    </div>


                </ng-template>




            </p-dialog>
        </div>
    </div>
</div>