<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subtitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <form [formGroup]="fourTooneYearMonitor" (ngSubmit)="growthMonitoringForm(formSubmitted)" #formSubmitted="ngForm" (keydown.enter)="$event.preventDefault()">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name<span class="required">*</span> </label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                                [readonly]="true" optionLabel="name" [filter]="true" filterBy="name"
                                formControlName="baby_details_id" placeholder="Select Baby's Name"
                                [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                      fourTooneYearMonitor.get('baby_details_id')?.invalid)
                      ||
                      fourTooneYearMonitor.get('baby_details_id')?.invalid
                      &&
                      fourTooneYearMonitor.get('baby_details_id').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Name is required</div>
                        </div>
                    </div>
                </div>
                <!-- Select Gender Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender </label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown optionLabel="name" optionValue="value" readonly
                      [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                      [resetFilterOnHide]="true" [readonly]="true">
                    </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <!-- Select Gender End-->
                <!-- Select DOB Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar readonly [(ngModel)]="selectedDate" [maxDate]="selectedDate"
                      [ngModelOptions]="{standalone: true}" readonly [showIcon]="true" [readonlyInput]="true"
                      placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />

                        </div>
                    </div>
                </div>
                <!-- Select DOB End-->
                <!-- Days, Months, Years Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
                <!-- Days, Months, Years End-->
            </div>
        </div>
        <!-- BABY DETAILS SECTION END-->
        <ng-container *ngIf="flag">
            <!-- <div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Height Weight</label>
					
					
					<div formArrayName="health_json">
						<ng-container *ngFor="let control of gethealthFormArray().controls; index as i; last as last">
						  <div class="row" [formGroupName]="i">
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Date</label>
								<div class="card flex justify-content-center">
								  <p-calendar formControlName="time" [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [minDate]="today" [maxDate]="today"></p-calendar>
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Height</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="height" class="form-control" maxlength="5" placeholder="Enter Height" />
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Weight</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="weight" class="form-control" maxlength="5" placeholder="Enter Weight" (keypress)="keyPress($event)" />
								</div>
							  </div>
							</div>
					  
							<div class="col-12 d-flex mb-3 justify-content-end">
							  <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right" class="mr-2 secondary-btn"></p-button>
							  <div class="remove">
								<p-button type="button" *ngIf="i != 0" class="ml-2 secondary-btn" icon="bx bx-minus" (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
							  </div>
							</div>
						  </div>
						</ng-container>
					  </div>
					  
				</div>
			</div> -->
            <!-- <div class="add-listings-box py-3">
                <div class="row">
                    <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
                    <div *ngIf="babyImgJSon">
                        <div *ngFor="let control of babyImgJSon">
                            <a href="{{ImageUrl + control?.name}}" target="_blank">View</a>
                        </div>
                    </div>


                    <div formArrayName="baby_images">
                        <label class="fw-500 mb-1">Upload Photo </label>

                        <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">

                            <div class="row" [formGroupName]="i">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group mb-2">
                                        <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
                                            class="form-control" aria-label="Upload"
                                            aria-describedby="inputGroupFileAddon">


                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12 d-flex mb-2 align-items-end">
                                    <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
                                        iconPos="right" class="mr-2 secondary-btn"></p-button>
                                    <div class="remove">
                                        <p-button type="button" *ngIf="i!= 0" class="ml-2 secondary-btn"
                                            icon="bx bx-minus" (click)="addBabyImg('delete', i)"
                                            iconPos="right"></p-button>
                                    </div>
                                </div>

                            </div>

                        </ng-container>

                    </div>

                    <div class="d-flex align-items-start">
                        <button class="p-button" type="button" (click)="openCamera()">
                            <i class="fas fa-camera"></i>
                        </button>
                        <div class=" imgPreview" *ngFor="let img of capturedByCamera">

                            <a (click)="deletecapture(img)">✖</a>
                            <img [src]="img" alt="">

                        </div>


                    </div>
                </div>
            </div> -->
            <!-- MOTOR AND COORDINATION SECTION -->
            <div class="add-listings-box pb-3" formGroupName="motor_coordination">
                <h3 class="text-center">4 MONTHS - 1 YEAR OLD</h3>
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <!-- *MOTOR AND COORDINATION SECTION -->
                    <label class="subHeading mb-3">Motor and Coordination</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Uses hands to support while sitting -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Uses hands to support while sitting
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="use-hand-yes" value="Yes" formControlName="use_hand"
                                        (change)="handleInput($event, 'use_hand')">
                                    <label for="use-hand-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="use-hand-no" value="No" formControlName="use_hand"
                                        (change)="handleInput($event, 'use_hand')">
                                    <label for="use-hand-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="use_hand_description">
                        </div>
                    </div>
                </div>

                <!-- Rolls from back to tummy and tummy to back -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Rolls from back to tummy and tummy to back
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="roll-back-yes" value="Yes" formControlName="roll_back"
                                        (change)="handleInput($event, 'roll_back')">
                                    <label for="roll-back-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="roll-back-no" value="No" formControlName="roll_back"
                                        (change)="handleInput($event, 'roll_back')">
                                    <label for="roll-back-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="roll_back_description">
                        </div>
                    </div>
                </div>

                <!-- While standing with support, accepts entire weight with legs  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">While standing with support, accepts entire weight with legs
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="standing-with-support-yes" value="Yes"
                                        formControlName="standing_with_support"
                                        (change)="handleInput($event, 'standing_with')">
                                    <label for="standing-with-support-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="standing-with-support-no" value="No"
                                        formControlName="standing_with_support"
                                        (change)="handleInput($event, 'standing_with')">
                                    <label for="standing-with-support-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="standing_with_support_description">
                        </div>
                    </div>
                </div>

                <!-- Reaches for nearby toys while on tummy  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Reaches for nearby toys while on tummy
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="reaches-toys-yes" value="Yes" formControlName="reaches_toys"
                                        (change)="handleInput($event, 'reaches_toys')">
                                    <label for="reaches-toys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="reaches-toys-no" value="NO" formControlName="reaches_toys"
                                        (change)="handleInput($event, 'reaches_toys')">
                                    <label for="reaches-toys-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="reaches_toys_description">
                        </div>
                    </div>
                </div>

                <!-- While lying on back, reaches both hands to play with feet  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">While lying on back, reaches both hands to play with feet</label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="lying-on-back-yes" value="Yes"
                                        formControlName="lying_on_back" (change)="handleInput($event, 'lying_on')">
                                    <label for="lying-on-back-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="lying-on-back-no" value="No" formControlName="lying_on_back"
                                        (change)="handleInput($event, 'lying_on')">
                                    <label for="lying-on-back-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="lying_on_back_description">
                        </div>
                    </div>
                </div>

                <!-- Transfer toys from one hand to the other  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Transfer toys from one hand to the other
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="transfer-toys-yes" value="Yes"
                                        formControlName="transfer_toys" (change)="handleInput($event, 'transfer_toys')">
                                    <label for="transfer-toys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="transfer-toys-no" value="No" formControlName="transfer_toys"
                                        (change)="handleInput($event, 'transfer_toys')">
                                    <label for="transfer-toys-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="transfer_toys_description">
                        </div>
                    </div>
                </div>

                <!-- Pulls to stand and cruise along furniture  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Pulls to stand and cruise along furniture
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="pulls-to-stand-yes" value="Yes"
                                        formControlName="pulls_to_stand" (change)="handleInput($event, 'pulls_to')">
                                    <label for="pulls-to-stand-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="pulls-to-stand-no" value="No"
                                        formControlName="pulls_to_stand" (change)="handleInput($event, 'pulls_to')">
                                    <label for="pulls-to-stand-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="pulls_to_stand_description">
                        </div>
                    </div>
                </div>

                <!-- Stands alone and takes several independent steps  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Stands alone and takes several independent steps
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="stands-alone-yes" value="Yes" formControlName="stands_alone"
                                        (change)="handleInput($event, 'stands_alone')">
                                    <label for="stands-alone-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="stands-alone-no" value="NO" formControlName="stands_alone"
                                        (change)="handleInput($event, 'stands_alone')">
                                    <label for="stands-alone-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="stands_alone_description">
                        </div>
                    </div>
                </div>

                <!-- Moves in and out of various positions to explore environment  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Moves in and out of various positions to explore environment
                            </label>
                            <div>
                                <div class="d-flex">
                                    <p class="me-3">
                                        <input type="radio" id="move-in-out-yes" value="Yes"
                                            formControlName="move_in_out" (change)="handleInput($event, 'move_in')">
                                        <label for="move-in-out-yes">Yes</label>
                                    </p>
                                    <p class="d-flex">
                                        <input type="radio" id="move-in-out-no" value="NO" formControlName="move_in_out"
                                            (change)="handleInput($event, 'move_in')">
                                        <label for="move-in-out-no">No</label>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="move_in_out_description">
                        </div>
                    </div>
                </div>

                <!-- Able to lift head forward when being pulled to ditting, from lying on back  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to lift head forward when being pulled to Sitting, from lying on
                                back
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="lift-head-yes" value="Yes" formControlName="lift_head"
                                        (change)="handleInput($event, 'lift_head')">
                                    <label for="lift-head-yes">Yes</label>
                                </p>

                                <p class="d-flex">
                                    <input type="radio" id="lift-head-no" value="No" formControlName="lift_head"
                                        (change)="handleInput($event, 'lift_head')">
                                    <label for="lift-head-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="lift_head_description">
                        </div>
                    </div>
                </div>

                <!-- Able to play on tummy for short burst of time  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to play on tummy for short burst of time
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="play-on-tummy-yes" value="Yes"
                                        formControlName="play_on_tummy" (change)="handleInput($event, 'play_on_tummy')">
                                    <label for="play-on-tummy-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="play-on-tummy-no" value="No" formControlName="play_on_tummy"
                                        (change)="handleInput($event, 'play_on_tummy')">
                                    <label for="play-on-tummy-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="play_on_tummy_description">
                        </div>
                    </div>
                </div>

                <!-- Able to pick up head and prop on elbows during tummy time  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to pick up head and prop on elbows during tummy time
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="pickup-head-yes" value="Yes" formControlName="pickup_head"
                                        (change)="handleInput($event, 'pickup_head')">
                                    <label for="pickup-head-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="pickup-head-no" value="No" formControlName="pickup_head"
                                        (change)="handleInput($event, 'pickup_head')">
                                    <label for="pickup-head-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="pickup_head_description">
                        </div>
                    </div>
                </div>

                <!-- Able to turn head to visually follow moving toys and people  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Able to turn head to visually follow moving toys and people
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="turn-head-yes" value="Yes" formControlName="turn_head"
                                        (change)="handleInput($event, 'turn_head')">
                                    <label for="turn-head-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="turn-head-no" value="No" formControlName="turn_head"
                                        (change)="handleInput($event, 'turn_head')">
                                    <label for="turn-head-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="turn_head_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys and seeks out various ways to move and play  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys and seeks out various ways to move and play
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-and-seeks-yes" value="Yes"
                                        formControlName="enjoy_and_seeks" (change)="handleInput($event, 'enjoy_and')">
                                    <label for="enjoy-and-seeks-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-and-seeks-no" value="No"
                                        formControlName="enjoy_and_seeks" (change)="handleInput($event, 'enjoy_and')">
                                    <label for="enjoy-and-seeks-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_and_seeks_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- MOTOR AND COORDINATION SECTION END-->


            <!-- SENSORY SECTION -->
            <div class="add-listings-box py-3" formGroupName="sensory">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3">Sensory</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Uses both hands to explore toys -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Uses both hands to explore toys
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="explore-toys-yes" value="Yes" formControlName="explore_toys"
                                        (change)="handleInput($event, 'explore_toys')">
                                    <label for="explore-toys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="explore-toys-no" value="No" formControlName="explore_toys"
                                        (change)="handleInput($event, 'explore_toys')">
                                    <label for="explore-toys-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="explore_toys_description">
                        </div>
                    </div>
                </div>

                <!-- Happy when not hungry or tired -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Happy when not hungry or tired
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="happy-not-angry-yes" value="Yes"
                                        formControlName="happy_not_angry"
                                        (change)="handleInput($event, 'happy_not_angry')">
                                    <label for="happy-not-angry-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="happy-not-angry-no" value="Yo"
                                        formControlName="happy_not_angry"
                                        (change)="handleInput($event, 'happy_not_angry')">
                                    <label for="happy-not-angry-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="happy_not_angry_description">
                        </div>
                    </div>
                </div>

                <!-- Brings hands and objects to mouth  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Brings hands and objects to mouth
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="brings-hand-object-support-yes" value="Yes"
                                        formControlName="brings_hand_object"
                                        (change)="handleInput($event, 'brings_hand')"
                                        (change)="handleInput($event, 'brings_hand')">
                                    <label for="brings-hand-object-support-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="brings-hand-object-support-no" value="No"
                                        formControlName="brings_hand_object"
                                        (change)="handleInput($event, 'brings_hand')"
                                        (change)="handleInput($event, 'brings_hand')">
                                    <label for="brings-hand-object-support-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="brings_hand_object_description">
                        </div>
                    </div>
                </div>

                <!-- Calms with rocking, touching, and gentle sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Calms with rocking, touching, and gentle sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="rocking-touching-yes" value="Yes"
                                        formControlName="rocking_touching"
                                        (change)="handleInput($event, 'rocking_touching')">
                                    <label for="rocking-touching-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="rocking-touching-no" value="No"
                                        formControlName="rocking_touching"
                                        (change)="handleInput($event, 'rocking_touching')">
                                    <label for="rocking-touching-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="rocking_touching_description">
                        </div>
                    </div>
                </div>

                <!-- Not upset by everyday sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Not upset by everyday sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="not-upset-yes" value="Yes" formControlName="not_upset"
                                        (change)="handleInput($event, 'not_upset')">
                                    <label for="not-upset-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="not-upset-no" value="No" formControlName="not_upset"
                                        (change)="handleInput($event, 'not_upset')">
                                    <label for="not-upset-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="not_upset_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys a variety of movement  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys a variety of movement
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-movement-yes" value="Yes"
                                        formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement')">
                                    <label for="enjoy-movement-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-movement-no" value="No"
                                        formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement')">
                                    <label for="enjoy-movement-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_movement_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys listening to songs  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys listening to songs
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="listening-songs-yes" value="Yes"
                                        formControlName="listening_songs"
                                        (change)="handleInput($event, 'listening_songs')">
                                    <label for="listening-songs-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="listening-songs-no" value="No"
                                        formControlName="listening_songs"
                                        (change)="handleInput($event, 'listening_songs')">
                                    <label for="listening-songs-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="listening_songs_description">
                        </div>
                    </div>
                </div>

                <!-- Explores toys with hands, fingers and mouth  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Explores toys with hands, fingers and mouth
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="explore-toys-with-hands-yes" value="Yes"
                                        formControlName="explore_toys_with_hands"
                                        (change)="handleInput($event, 'explore_toys1')">
                                    <label for="explore-toys-with-hands-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="explore-toys-with-hands-no" value="No"
                                        formControlName="explore_toys_with_hands"
                                        (change)="handleInput($event, 'explore-toys1')">
                                    <label for="explore-toys-with-hands-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="explore_toys_with_hands_description">
                        </div>
                    </div>
                </div>

                <!-- Crawls to or away from objects baby sees in the distance  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Crawls to or away from objects baby sees in the distance
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="crawls-yes" value="Yes" formControlName="crawls"
                                        (change)="handleInput($event, 'crawls')">
                                    <label for="crawls-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="crawls-no" value="No" formControlName="crawls"
                                        (change)="handleInput($event, 'crawls')">
                                    <label for="crawls-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="crawls_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys a variety of movements  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys a variety of movements
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-movements-yes" value="Yes"
                                        formControlName="enjoy_movements"
                                        (change)="handleInput($event, 'enjoy_movements')">
                                    <label for="enjoy-movements-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-movements-no" value="No"
                                        formControlName="enjoy_movements"
                                        (change)="handleInput($event, 'enjoy_movements')">
                                    <label for="enjoy-movements-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_movements_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- SENSORY SECTION END-->

            <!-- COMMUNICATION SECTION -->
            <div class="add-listings-box py-3" formGroupName="communication">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3 fw-5">Communication</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Knows his/ her name when called -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Knows his/ her name when called
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="knows-name-yes" value="Yes" formControlName="knows_name"
                                        (change)="handleInput($event, 'knows_name')">
                                    <label for="knows-name-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="knows-name-no" value="No" formControlName="knows_name"
                                        (change)="handleInput($event, 'knows_name')">
                                    <label for="knows-name-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="knows_name_description">
                        </div>
                    </div>
                </div>

                <!-- Reacts to sudden noises and sounds -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Reacts to sudden noises and sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="reacts-yes" value="Yes" formControlName="reacts"
                                        (change)="handleInput($event, 'reacts')">
                                    <label for="reacts-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="reacts-no" value="No" formControlName="reacts"
                                        (change)="handleInput($event, 'reacts')">
                                    <label for="reacts-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="reacts_description">
                        </div>
                    </div>
                </div>

                <!-- Listens and responds when spoken to  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Listens and responds when spoken to
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="listen_and_respond-yes" value="Yes"
                                        formControlName="listen_and_respond"
                                        (change)="handleInput($event, 'listen_and_respond')">
                                    <label for="listen_and_respond-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="listen_and_respond-no" value="No"
                                        formControlName="listen_and_respond"
                                        (change)="handleInput($event, 'listen_and_respond')">
                                    <label for="listen_and_respond-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="listen_and_respond_description">
                        </div>
                    </div>
                </div>

                <!-- Notices toys that makes sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Notices toys that makes sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="notice-toys-yes" value="Yes" formControlName="notice_toys"
                                        (change)="handleInput($event, 'notice_toys')">
                                    <label for="notice-toys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="notice-toys-no" value="No" formControlName="notice_toys"
                                        (change)="handleInput($event, 'notice_toys')">
                                    <label for="notice-toys-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="notice_toys_description">
                        </div>
                    </div>
                </div>

                <!-- Meaningfully uses "mama" or "dada"  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Meaningfully uses "mama" or "dada"
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="meaning-uses-yes" value="Yes" formControlName="meaning_uses"
                                        (change)="handleInput($event, 'meaning_uses')">
                                    <label for="meaning-uses-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="meaning-uses-no" value="No" formControlName="meaning_uses"
                                        (change)="handleInput($event, 'meaning_uses')">
                                    <label for="meaning-uses-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="meaning_uses_description">
                        </div>
                    </div>
                </div>

                <!-- Responds to simple directions like "Come here"  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Responds to simple directions like "Come here"
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="res-simple-direction-yes" value="Yes"
                                        formControlName="res_simple_direction"
                                        (change)="handleInput($event, 'res_simple')">
                                    <label for="res-simple-direction-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="res-simple-direction-no" value="No"
                                        formControlName="res_simple_direction"
                                        (change)="handleInput($event, 'res_simple')">
                                    <label for="res-simple-direction-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="res_simple_direction_description">
                        </div>
                    </div>
                </div>

                <!-- Say one or two words  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Say one or two words
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="say-words-yes" value="Yes" formControlName="say_words"
                                        (change)="handleInput($event, 'say_words')">
                                    <label for="say-words-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="say-words-no" value="No" formControlName="say_words"
                                        (change)="handleInput($event, 'say_words')">
                                    <label for="say-words-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="say_words_description">
                        </div>
                    </div>
                </div>

                <!-- Imitates speech sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Imitates speech sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="imitates-sounds-yes" value="Yes"
                                        formControlName="imitates_sounds"
                                        (change)="handleInput($event, 'imitates_sounds')">
                                    <label for="imitates-sounds-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="imitates-sounds-no" value="No"
                                        formControlName="imitates_sounds"
                                        (change)="handleInput($event, 'imitates_sounds')">
                                    <label for="imitates-sounds-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="imitates_sounds_description">
                        </div>
                    </div>
                </div>

                <!-- Babbling has sounds and rhythms of speech  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Babbling has sounds and rhythms of speech
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="sounds-rhythms-yes" value="Yes"
                                        formControlName="sounds_rhythms"
                                        (change)="handleInput($event, 'sounds_rhythms')">
                                    <label for="sounds-rhythms-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="sounds-rhythms-no" value="No"
                                        formControlName="sounds_rhythms"
                                        (change)="handleInput($event, 'sounds_rhythms')">
                                    <label for="sounds-rhythms-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="sounds_rhythms_description">
                        </div>
                    </div>
                </div>

                <!-- Pays attention to where you are looking and pointing  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Pays attention to where you are looking and pointing
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="pays-attention-yes" value="Yes"
                                        formControlName="pays_attention"
                                        (change)="handleInput($event, 'pays_attention')">
                                    <label for="pays-attention-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="pays-attention-no" value="No"
                                        formControlName="pays_attention"
                                        (change)="handleInput($event, 'pays_attention')">
                                    <label for="pays-attention-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="pays_attention_description">
                        </div>
                    </div>
                </div>

                <!-- Responds to "No"  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Responds to "No"
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="respond-yes" value="Yes" formControlName="respond"
                                        (change)="handleInput($event, 'respond')">
                                    <label for="respond-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="respond-no" value="No" formControlName="respond"
                                        (change)="handleInput($event, 'respond')">
                                    <label for="respond-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="respond_description">
                        </div>
                    </div>
                </div>

                <!-- Begins to use hand movements to communicate wants and needs e.g. reaches to be picked up  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to use hand movements to communicate wants and needs e.g. reaches
                                to
                                be picked up
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="hand-movement-yes" value="Yes"
                                        formControlName="hand_movement" (change)="handleInput($event, 'hand_movement')">
                                    <label for="hand-movement-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="hand-movement-no" value="No" formControlName="hand_movement"
                                        (change)="handleInput($event, 'hand_movement')">
                                    <label for="hand-movement-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="hand_movement_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- COMMUNICATION SECTION END-->

            <!-- FEEDING SECTION -->
            <div class="add-listings-box py-3" formGroupName="feeding">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3 fw-5">Feeding</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Shows interest in food -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Shows interest in food
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="show-interst-yes" value="Yes" formControlName="show_interst"
                                        (change)="handleInput($event, 'show_interst')">
                                    <label for="show-interst-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="show-interst-no" value="No" formControlName="show_interst"
                                        (change)="handleInput($event, 'show_interst')">
                                    <label for="show-interst-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="show_interst_description">
                        </div>
                    </div>
                </div>

                <!-- Opens mouth as spoon approaches -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Opens mouth as spoon approaches
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="open-mouth-yes" value="Yes" formControlName="open_mouth"
                                        (change)="handleInput($event, 'open_mouth')">
                                    <label for="open-mouth-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="open-mouth-no" value="No" formControlName="open_mouth"
                                        (change)="handleInput($event, 'open_mouth')">
                                    <label for="open-mouth-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="open_mouth_description">
                        </div>
                    </div>
                </div>

                <!-- Moves pureed food from front of mouth to back  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Moves pureed food from front of mouth to back
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="pureed-food-yes" value="Yes" formControlName="pureed_food"
                                        (change)="handleInput($event, 'pureed_food')">
                                    <label for="pureed-food-yes">Yes</label>
                                </p>
                                <p>
                                    <input type="radio" id="pureed-food-no" value="No" formControlName="pureed_food"
                                        (change)="handleInput($event, 'pureed_food')">
                                    <label for="pureed-food-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="pureed_food_description">
                        </div>
                    </div>
                </div>

                <!-- Begins to eat cereals and pureed foods - smooth, pureed food (single ingredient only)  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to eat cereals and pureed foods - smooth, pureed food (single
                                ingredient only)
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="begins-to-eat-yes" value="Yes"
                                        formControlName="begins_to_eat" (change)="handleInput($event, 'begins_to_eat')">
                                    <label for="begins-to-eat-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="begins-to-eat-no" value="No" formControlName="begins_to_eat"
                                        (change)="handleInput($event, 'begins_to_eat')">
                                    <label for="begins-to-eat-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="begins_to_eat_description">
                        </div>
                    </div>
                </div>

                <!-- Finger feeds self  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Finger feeds self
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="finger-feed-yes" value="Yes" formControlName="finger_feed"
                                        (change)="handleInput($event, 'finger_feed')">
                                    <label for="finger-feed-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="finger-feed-no" value="No" formControlName="finger_feed"
                                        (change)="handleInput($event, 'finger_feed')">
                                    <label for="finger-feed-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="finger_feed_description">
                        </div>
                    </div>
                </div>

                <!-- Eating an increasing variety of food  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Eating an increasing variety of food
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="increasing-food-yes" value="Yes"
                                        formControlName="increasing_food"
                                        (change)="handleInput($event, 'increasing_food')">
                                    <label for="increasing-food-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="increasing-food-no" value="No"
                                        formControlName="increasing_food"
                                        (change)="handleInput($event, 'increasing_food')">
                                    <label for="increasing-food-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="increasing_food_description">
                        </div>
                    </div>
                </div>

                <!-- Begins to use an open cup  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Begins to use an open cup
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="open-cup-yes" value="Yes" formControlName="open_cup"
                                        (change)="handleInput($event, 'open_cup')">
                                    <label for="open-cup-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="open-cup-no" value="No" formControlName="open_cup"
                                        (change)="handleInput($event, 'open_cup')">
                                    <label for="open-cup-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="open_cup_description">
                        </div>
                    </div>
                </div>

                <!-- Ready to try soft cooked vegetables, soft fruits, and finger food  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Ready to try soft cooked vegetables, soft fruits, and finger food
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="try-cooked-vegs-yes" value="Yes"
                                        formControlName="try_cooked_vegs" (change)="handleInput($event, 'try_cooked')">
                                    <label for="try-cooked-vegs-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="try-cooked-vegs-no" value="No"
                                        formControlName="try_cooked_vegs" (change)="handleInput($event, 'try_cooked')">
                                    <label for="try-cooked-vegs-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="try_cooked_vegs_description">
                        </div>
                    </div>
                </div>

                <!-- Might be ready to start self-feeding with utensils  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Might be ready to start self-feeding with utensils
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="self-feeding-yes" value="Yes" formControlName="self_feeding"
                                        (change)="handleInput($event, 'self_feeding')">
                                    <label for="self-feeding-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="self-feeding-no" value="No" formControlName="self_feeding"
                                        (change)="handleInput($event, 'self_feeding')">
                                    <label for="self-feeding-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="self_feeding_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys a greater variety of smells and tastes  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys a greater variety of smells and tastes
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-smell-taste-yes" value="Yes"
                                        formControlName="enjoy_smell_taste"
                                        (change)="handleInput($event, 'enjoy_smell')">
                                    <label for="enjoy-smell-taste-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-smell-taste-no" value="No"
                                        formControlName="enjoy_smell_taste"
                                        (change)="handleInput($event, 'enjoy_smell')">
                                    <label for="enjoy-smell-taste-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_smell_taste_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- FEEDING SECTION END-->

            <!-- PLAY AND SOCIAL SKILLS SECTION -->
            <div class="add-listings-box py-3" formGroupName="play_and_social_skills">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3 fw-5">Play and Social Skills</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Enjoys playful interactions with others, e.g. peek a boo -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys playful interactions with others, e.g. peek a boo
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="playful-interactions-yes" value="Yes"
                                        formControlName="playful_interaction"
                                        (change)="handleInput($event, 'playful_interaction')">
                                    <label for="playful-interactions-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="playful-interactions-no" value="No"
                                        formControlName="playful_interaction"
                                        (change)="handleInput($event, 'playful_interaction')">
                                    <label for="playful-interactions-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="playful_interaction_description">
                        </div>
                    </div>
                </div>

                <!-- Vocalizing in response to playful interactions -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Vocalizing in response to playful interactions
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="vocalizing-yes" value="Yes" formControlName="vocalizing"
                                        (change)="handleInput($event, 'vocalizing')">
                                    <label for="vocalizing-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="vocalizing-no" value="No" formControlName="vocalizing"
                                        (change)="handleInput($event, 'vocalizing')">
                                    <label for="vocalizing-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="vocalizing_description">
                        </div>
                    </div>
                </div>

                <!-- Turns head towards sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Turns head towards sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="turns-head-yes" value="Yes" formControlName="turns_head"
                                        (change)="handleInput($event, 'turns_head')">
                                    <label for="turns-head-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="turns-head-no" value="No" formControlName="turns_head"
                                        (change)="handleInput($event, 'turns_head')">
                                    <label for="turns-head-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="turns_head_description">
                        </div>
                    </div>
                </div>

                <!-- Maintains eye contact with familiar people during playful interaction  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Maintains eye contact with familiar people during playful interaction
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="eye-contact-yes" value="Yes" formControlName="eye_contact"
                                        (change)="handleInput($event, 'eye_contact_familiar')">
                                    <label for="eye-contact-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="eye-contact-no" value="No" formControlName="eye_contact"
                                        (change)="handleInput($event, 'eye_contact_familiar')">
                                    <label for="eye-contact-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="eye_contact_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys playing with toys of varied textures  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys playing with toys of varied textures
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-paying-yes" value="Yes"
                                        formControlName="enjoy_playing" (change)="handleInput($event, 'enjoy_playing')">
                                    <label for="enjoy-paying-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-paying-no" value="No" formControlName="enjoy_playing"
                                        (change)="handleInput($event, 'enjoy_playing')">
                                    <label for="enjoy-paying-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_playing_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys musical toys  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys musical toys
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-toys-yes" value="Yes" formControlName="enjoy_toys"
                                        (change)="handleInput($event, 'enjoy_toys')">
                                    <label for="enjoy-toys-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-toys-no" value="No" formControlName="enjoy_toys"
                                        (change)="handleInput($event, 'enjoy_toys')">
                                    <label for="enjoy-toys-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_toys_description">
                        </div>
                    </div>
                </div>

                <!-- Raises hands to be picked up  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Raises hands to be picked up
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="raise-hands-yes" value="Yes" formControlName="raise_hands"
                                        (change)="handleInput($event, 'raise_hands')">
                                    <label for="raise-hands-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="raise-hands-no" value="No" formControlName="raise_hands"
                                        (change)="handleInput($event, 'raise_hands')">
                                    <label for="raise-hands-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="raise_hands_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys various types of movements, such as being gently swung  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys various types of movements, such as being gently swung
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-swung-yes" value="Yes"
                                        formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement_gently')">
                                    <label for="enjoy-swung-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-swung-no" value="No" formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement_gently')">
                                    <label for="enjoy-swung-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_movement_descriptionn1">
                        </div>
                    </div>
                </div>

                <!-- Maintains eye contact with people during playful interactions  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Maintains eye contact with people during playful interactions
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="eye-contact-interaction-yes" value="Yes"
                                        formControlName="eye_contact_interaction"
                                        (change)="handleInput($event, 'eye_contact')">
                                    <label for="eye-contact-interaction-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="eye-contact-interaction-no" value="No"
                                        formControlName="eye_contact_interaction"
                                        (change)="handleInput($event, 'eye_contact')">
                                    <label for="eye-contact-interaction-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="eye_contact_interaction_description">
                        </div>
                    </div>
                </div>

                <!-- Points to object of interest by 1 year of age  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Points to object of interest by 1 year of age
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="points-to-object-yes" value="Yes"
                                        formControlName="point_to_object"
                                        (change)="handleInput($event, 'point_to_object')">
                                    <label for="points-to-object-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="points-to-object-no" value="No"
                                        formControlName="point_to_object"
                                        (change)="handleInput($event, 'point_to_object')">
                                    <label for="points-to-object-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="point_to_object_description">
                        </div>
                    </div>
                </div>

                <!-- Frequently explores the environment when placed on floor  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Frequently explores the environment when placed on floor
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="explore-env-yes" value="Yes" formControlName="explore_env"
                                        (change)="handleInput($event, 'explore_env')">
                                    <label for="explore-env-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="explore-env-no" value="No" formControlName="explore_env"
                                        (change)="handleInput($event, 'explore_env')">
                                    <label for="explore-env-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="explore_env_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys moving to get desirable toy  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys moving to get desirable toy
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="desirable-toy-yes" value="Yes"
                                        formControlName="desirable_toy" (change)="handleInput($event, 'desirable_toy')">
                                    <label for="desirable-toy-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="desirable-toy-no" value="No" formControlName="desirable_toy"
                                        (change)="handleInput($event, 'desirable_toy')">
                                    <label for="desirable-toy-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="desirable_toy_description">
                        </div>
                    </div>
                </div>
            </div>
            <!-- PLAY AND SOCIAL SKILLS SECTION END-->


            <!-- SELF-EXPRESSION SECTION -->
            <div class="add-listings-box py-3" formGroupName="self_expression">
                <div class="d-flex col-lg-6 col-md-12 justify-content-between">
                    <label class="subHeading mb-3 fw-5">Self Expression</label>
                    <label class="subHeading2 mb-3">Remarks</label>
                </div>

                <!-- Comforted by cuddling  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Comforted by cuddling
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="cuddling-yes" value="Yes" formControlName="cuddling"
                                        (change)="handleInput($event, 'cuddling')">
                                    <label for="cuddling-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="cuddling-no" value="No" formControlName="cuddling"
                                        (change)="handleInput($event, 'cuddling')">
                                    <label for="cuddling-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="cuddling_description">
                        </div>
                    </div>
                </div>

                <!-- Happy when not hungry or tired -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Happy when not hungry or tired
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="not-angry-yes" value="Yes" formControlName="not_angry"
                                        (change)="handleInput($event, 'not_angry')">
                                    <label for="not-angry-yes">Yes</label>
                                </p>
                                <p>
                                    <input type="radio" id="not-angry-no" value="No" formControlName="not_angry"
                                        (change)="handleInput($event, 'not_angry')">
                                    <label for="not-angry-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="not_angry_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys varied playful movement experiences such as rocking, touching, and calm sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys varied playful movement experiences such as rocking, touching, and
                                calm sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-yes" value="Yes" formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement2')">
                                    <label for="enjoy-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-no" value="No" formControlName="enjoy_movement"
                                        (change)="handleInput($event, 'enjoy_movement2')">
                                    <label for="enjoy-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_movement_description2">
                        </div>
                    </div>
                </div>

                <!-- Calms with rocking, touching, and gentle sounds  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Calms with rocking, touching, and gentle sounds
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="calms-yes" value="Yes" formControlName="calms"
                                        (change)="handleInput($event, 'calms')">
                                    <label for="calms-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="calms-no" value="No" formControlName="calms"
                                        (change)="handleInput($event, 'calms')">
                                    <label for="calms-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="calms_description">
                        </div>
                    </div>
                </div>

                <!-- Has grown accustomed to everyday sounds and is usually not startled by it  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Has grown accustomed to everyday sounds and is usually not startled by it
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="grown-accustomed-yes" value="Yes"
                                        formControlName="grown_accustomed"
                                        (change)="handleInput($event, 'grown_accustomed')">
                                    <label for="grown-accustomed-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="grown-accustomed-no" value="No"
                                        formControlName="grown_accustomed"
                                        (change)="handleInput($event, 'grown_accustomed')">
                                    <label for="grown-accustomed-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="grown_accustomed_description">
                        </div>
                    </div>
                </div>

                <!-- Enjoys a wide variety of touch, noisem and smell  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Enjoys a wide variety of touch, noisem and smell
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="enjoy-touch-yes" value="Yes" formControlName="enjoy_touch"
                                        (change)="handleInput($event, 'enjoy_touch')">
                                    <label for="enjoy-touch-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="enjoy-touch-no" value="No" formControlName="enjoy_touch"
                                        (change)="handleInput($event, 'enjoy_touch')">
                                    <label for="enjoy-touch-no">No</label>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="enjoy_touch_description">
                        </div>
                    </div>
                </div>

                <!-- Cries and notices when hurt  -->
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-12">
                        <div class="form-group mb-2">
                            <label class="p-0">Cries and notices when hurt
                            </label>
                            <div class="d-flex">
                                <p class="me-3">
                                    <input type="radio" id="cries-yes" value="Yes" formControlName="cries"
                                        (change)="handleInput($event, 'cries')">
                                    <label for="cries-yes">Yes</label>
                                </p>
                                <p class="d-flex">
                                    <input type="radio" id="cries-no" value="No" formControlName="cries"
                                        (change)="handleInput($event, 'cries')">
                                    <label for="cries-no">No</label>
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-12 mt-auto">
                        <div class="form-group mb-0">
                            <input class="form-control" type="text" placeholder="Remark"
                                formControlName="cries_description">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <!-- SELF-EXPRESSION SECTION END-->
        <div class="add-listings-btn" style="text-align: right">
            <button type="submit" [disabled]="!flag">Submit</button>
        </div>
    </form>


    <p-dialog id="capturewin" header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
        [closable]="false" [resizable]="false">
        <div>
            <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
            <video #video autoplay="true" playsinline="true"></video>
            <canvas #canvas style="display:none;"></canvas>
        </div>
        <div>
            <a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
            </a>
        </div>
    </p-dialog>


    <!-- <p-dialog header="captured Photo" [(visible)]="imagePreview"
[modal]="true"
[draggable]="false" [closable]="false" [resizable]="false">
<div>
    <button type="button" class="close-button" (click)="closePreview()" aria-label="Close"></button>

    <img [src]="imgSrcForPreview" alt="">
</div>
<div style="cursor: pointer;">
    <a (click)="deletecapture(imgSrcForPreview)">Delete</a>
</div>

</p-dialog> -->


    <app-copyrights></app-copyrights>
</div>