<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->
<p-toast></p-toast>
<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">
    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="dropdown profile-nav-item d-flex flex-column align-items-end">
        <a class="dropdown-toggle admin-btn" style="text-decoration: none;" role="button" data-bs-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <div class="menu-profile">
                <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                <span class="name">{{getname}}</span>
            </div>
        </a>
        <div class="dropdown-menu d-flex flex-column align-items-center">
            <div class="dropdown-header align">
                <div class="figure mb-3">
                    <img src="assets/img/admin logo.png" class="rounded-circle" alt="image">
                </div>
                <div class="info text-center">
                    <span class="name" style="white-space: break-spaces;">{{getname}}</span>
                    <p class="mb-3 email">{{getRole}}</p>
                </div>
            </div>
            <!-- <div class="dropdown-body">
				<ul class="profile-nav p-0 pt-3">
					<li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"><i class='bx bx-user'></i> <span>Profile Dashboard</span></a></li>
				</ul>
			</div> -->
            <div class="dropdown-footer">
                <ul class="profile-nav">
                    <li class="nav-item"><a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i> <span
                                class="logout-text">Logout</span></a></li>
                </ul>
            </div>

        </div>
    </div>
    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb">
        <h1>{{ Content.title }}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item">
                {{ Content.subtitle }}
            </li>
            <li class="item">{{ Content.title }}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <form [formGroup]="growthMonitoringCapture" (ngSubmit)="growthMonitoringForm(formSubmitted)" #formSubmitted="ngForm" (keydown.enter)="$event.preventDefault()">
        <!-- BABY DETAILS SECTION -->
        <div class="add-listings-box">
            <h3>Baby Details</h3>
            <div class="row">
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Name<span class="required">*</span> </label>
                        <div class="card flex justify-content-center">
                            <p-dropdown [options]="babyDetails" (onChange)="getBabyDetails($event)" optionValue="id"
                                [readonly]="true" optionLabel="name" [filter]="true" filterBy="name"
                                formControlName="baby_details_id" placeholder="Select Baby's Name"
                                [resetFilterOnHide]="true" [class.is-invalid]="(formSubmitted.submitted &&
                      growthMonitoringCapture.get('baby_details_id')?.invalid)
                      ||
                      growthMonitoringCapture.get('baby_details_id')?.invalid
                      &&
                      growthMonitoringCapture.get('baby_details_id').touched">
                            </p-dropdown>
                            <div class="invalid-feedback">Name is required</div>
                        </div>
                    </div>
                </div>
                <!-- Select Gender Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Gender </label>
                        <div class="card flex justify-content-center">
                            <!-- <p-dropdown optionLabel="name" optionValue="value" readonly
                      [(ngModel)]="babyGender" [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                      [resetFilterOnHide]="true" [readonly]="true">
                    </p-dropdown> -->
                            <input type="text" class="form-control" [(ngModel)]="babyGender"
                                [ngModelOptions]="{standalone: true}" placeholder="Select Baby's Gender"
                                [readonly]="true">
                        </div>
                    </div>
                </div>
                <!-- Select Gender End-->
                <!-- Select DOB Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Date of birth</label>
                        <div class="card flex justify-content-center">
                            <!-- <p-calendar readonly [(ngModel)]="selectedDate" [maxDate]="selectedDate"
                      [ngModelOptions]="{standalone: true}" readonly [showIcon]="true" [readonlyInput]="true"
                      placeholder="DD/MM/YYYY"></p-calendar> -->
                            <input type="text" name="name" class="form-control" placeholder="DD/MM/YYYY"
                                [value]="selectedDate" readonly />

                        </div>
                    </div>
                </div>
                <!-- Select DOB End-->
                <!-- Days, Months, Years Start-->
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Days</label>
                        <input type="text" name="name" class="form-control" placeholder="Days" [value]="days"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Months</label>
                        <input type="text" name="name" class="form-control" placeholder="Months" [value]="month"
                            readonly />
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-12">
                    <div class="form-group">
                        <label class="px-0">Years</label>
                        <input type="text" name="name" class="form-control" placeholder="Years" [value]="years"
                            readonly />
                    </div>
                </div>
                <!-- Days, Months, Years End-->
            </div>
        </div>
        <!-- BABY DETAILS SECTION END-->
        <ng-container *ngIf="flag">
            <div class="add-listings-box py-3">
				<div class="row">
					<label class="subHeading mb-3">Height Weight</label>
					
					
					<div >
						<!-- <div class="row">
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button" [disabled]="heightConverted" (click)="convertHeightToCm()">Convert Height to CM</button>
							  </div>
							</div>
						  </div>
						  <div class="col-lg-4 col-sm-6 col-12" *ngIf="isedit">
							<div class="form-group">
							  <div class="secondary-btn">
								<button type="button"[disabled]="weightConverted" (click)="convertKgToPounds()">Convert Weight to Pounds</button>
							  </div>
							</div>
						  </div>
						</div> -->
					  
						  <div class="row" >
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Date</label>
								<div class="card flex justify-content-center">
								  <p-calendar formControlName="date_of_assessment" [showIcon]="true" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" [minDate]="today" [maxDate]="today"></p-calendar>
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Height</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="height" class="form-control" maxlength="5" placeholder="Enter Height" />
								</div>
							  </div>
							</div>
					  
							<div class="col-lg-4 col-sm-6 col-12">
							  <div class="form-group">
								<label class="px-0">Weight</label>
								<div class="pl-0 checkbox-list">
								  <input type="text" formControlName="weight" class="form-control" maxlength="5" placeholder="Enter Weight"  />
								</div>
							  </div>
							</div>
<!-- 					  
							<div class="col-12 d-flex mb-3 justify-content-end">
							  <p-button type="button" icon="bx bx-plus" (click)="addVitaminObject('add', i)" iconPos="right" class="mr-2 secondary-btn"></p-button>
							  <div class="remove">
								<p-button type="button" *ngIf="i != 0" class="ml-2 secondary-btn" icon="bx bx-minus" (click)="addVitaminObject('delete', i)" iconPos="right"></p-button>
							  </div>
							</div> -->
						  </div>
					  </div>
					  
				</div>
			</div>
            <!-- <div class="add-listings-box py-3">
                <div class="row">
                    <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
                    <div formArrayName="baby_images">
                        <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">
                            <div class="row" [formGroupName]="i">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label class="px-0">Upload Photo </label>
                                        <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
                                            class="inputfile form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div class="col-lg-4 col-sm-6 col-12 d-flex mb-4 align-items-end">
                                    <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
                                        iconPos="right" class="mr-2"></p-button>
                                    <div class="remove">
                                        <p-button type="button" *ngIf="i!= 0" class="ml-2" icon="bx bx-minus"
                                            (click)="addBabyImg('delete', i)" iconPos="right"></p-button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div> -->

            <div class="add-listings-box py-3">
                <div class="row">
                    <label class="subHeading mb-3">Baby Images of Growth Monitoring </label>
                    <div *ngIf="babyImgJSon">
                        <div *ngFor="let control of babyImgJSon">
                            <a href="{{ImageUrl + control?.name}}" target="_blank">View</a>
                        </div>
                    </div>


                    <div formArrayName="baby_images">
                        <label class="fw-500 mb-1">Upload Photo </label>

                        <ng-container *ngFor="let control of getImageArray().controls; index as i; last as last">

                            <div class="row" [formGroupName]="i">
                                <div class="col-lg-4 col-sm-6 col-12">
                                    <div class="form-group mb-2">
                                        <input (change)="uploadPhoto($event)" type="file" accept=".png, .jpg, .jpeg"
                                            class="form-control" aria-label="Upload"
                                            aria-describedby="inputGroupFileAddon">


                                    </div>
                                </div>
                                <!-- <div class="col-lg-4 col-sm-6 col-12 d-flex mb-2 align-items-end">
                                    <p-button type="button" icon="bx bx-plus" (click)="addBabyImg('add', i)"
                                        iconPos="right" class="mr-2 secondary-btn"></p-button>
                                    <div class="remove">
                                        <p-button type="button" *ngIf="i!= 0" class="ml-2 secondary-btn"
                                            icon="bx bx-minus" (click)="addBabyImg('delete', i)"
                                            iconPos="right"></p-button>
                                    </div>
                                </div> -->

                            </div>

                        </ng-container>

                    </div>

                    <div class="d-flex align-items-start">
                        <button class="p-button" type="button" (click)="openCamera()">
                            <i class="fas fa-camera"></i>
                        </button>
                        <div class=" imgPreview" *ngFor="let img of capturedByCamera">

                            <!-- <a (click)="previewImage(img)">Click to preview</a> -->
                            <a (click)="deletecapture(img)">✖</a>
                            <img [src]="img" alt="">

                        </div>


                    </div>
                </div>
            </div>
        </ng-container>
        <!-- display baby image and height and weight -->
        <!-- <h3>Baby Images of Growth Monitoring</h3>
         <div class="row">
            <div>
              <ng-container>
                <div class="row">
                  <div class="col-lg-4 col-sm-6 col-12" *ngFor="let control of babyImg; index as i;">
                    <div class="card mb-3 fc-card">
                      <ng-container *ngIf="control?.image?.length > 0">
                        <ng-container *ngFor="let img of control.image">
                          <img class="card-img-top" src="{{ImageUrl + img?.name}}" alt="Description of the image">
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="control?.image?.length === 0">
                        <div class="card-body">
                          <p class="card-text">No image available</p>
                        </div>
                      </ng-container>
                      <div class="d-flex">
                          <p class="gc-card">{{control.date_of_assessment | date: 'dd-MM-yyyy' ?? '-'}}</p>
                          <p class="gc-card">Height: {{control.height ?? '-'}}</p>
                          <p class="gc-card">Weight: {{control.weight ?? '-'}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div> -->
        <div class="add-listings-btn" style="text-align: right">
            <button type="submit" [disabled]="!flag">Submit</button>
        </div>
    </form>


    <p-dialog id="capturewin" header="Capture Photo" [(visible)]="openCameraforUpload" [modal]="true" [draggable]="false"
        [closable]="false" [resizable]="false">
        <div>
            <button type="button" class="close-button" (click)="shutDownCamera()" aria-label="Close"></button>
            <video #video autoplay="true" playsinline="true"></video>
            <canvas #canvas style="display:none;"></canvas>
        </div>
        <div>
            <a class="captureButton" (click)="capture()"><i class="fas fa-camera"></i>
            </a>
        </div>
    </p-dialog>


    <!-- <p-dialog header="captured Photo" [(visible)]="imagePreview"
[modal]="true"
[draggable]="false" [closable]="false" [resizable]="false">
<div>
    <button type="button" class="close-button" (click)="closePreview()" aria-label="Close"></button>

    <img [src]="imgSrcForPreview" alt="">
</div>
<div style="cursor: pointer;">
    <a (click)="deletecapture(imgSrcForPreview)">Delete</a>
</div>

</p-dialog> -->


    <app-copyrights></app-copyrights>
</div>