import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
@Injectable({
  providedIn: 'root'
})
export class MaikaListService {
  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
  /* -------------------------------------------------------------------------- */
  /*               // growth monitoring get list function                    */
  /* -------------------------------------------------------------------------- */
  getGrowthMonitorList() {
    return this.http.get(this.apiBaseUrl + 'development-milestones')
  }

  /* -------------------------------------------------------------------------- */
  /*               // daily notes get list function                    */
  /* -------------------------------------------------------------------------- */
  getDailyNotesList() {
    return this.http.get(this.apiBaseUrl + 'daily-notes')
  }

  /* -------------------------------------------------------------------------- */
  /*               // initial assesment get list function                    */
  /* -------------------------------------------------------------------------- */
  getInitialAssList() {
    return this.http.get(this.apiBaseUrl + 'initial-assessment')
  }

  getFeedingAss() {
    return this.http.get(this.apiBaseUrl + '/feeding-assesments')
  }

  /* -------------------------------------------------------------------------- */
  /*               // nanny task get list function                    */
  /* -------------------------------------------------------------------------- */
  getNannyTaskList() {
    return this.http.get(this.apiBaseUrl + 'nanny-task')
  }

  /* -------------------------------------------------------------------------- */
  /*               // environment and child safety get list function                    */
  /* -------------------------------------------------------------------------- */
  getChildSafetyList() {
    return this.http.get(this.apiBaseUrl + 'environment-child-safety')
  }
  /* -------------------------------------------------------------------------- */
  /*               // extra actvivtty get by id                   */
  /* -------------------------------------------------------------------------- */
  getExtraActivityListById(id: any) {
    return this.http.get(this.apiBaseUrl + `environment-child-safety/baby/${id}`).toPromise();
  }
  /*               // nanny zero to three month plan get list function                    */
  /* -------------------------------------------------------------------------- */
  getZeroToThreeMonthList() {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails/1')
  }

  /* -------------------------------------------------------------------------- */
  /*               // nanny four to two year plan get list function                    */
  /* -------------------------------------------------------------------------- */
  getFourMonthToTwoYearList() {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails/2')
  }

  /* -------------------------------------------------------------------------- */
  /*               // nanny One year to three year plan get list function                    */
  /* -------------------------------------------------------------------------- */
  getOneYearToThreeList() {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails/3')
  }

  /* -------------------------------------------------------------------------- */
  /*               // nanny Three year to above plan get list function                    */
  /* -------------------------------------------------------------------------- */
  getThreeYearToAboveList() {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails/4')
  }

  /* -------------------------------------------------------------------------- */
  /*               // nanny Three year to above plan get list function                    */
  /* -------------------------------------------------------------------------- */
  GetNannyDailyMonitoringList() {
    return this.http.get(this.apiBaseUrl + 'daily-monitoring')
  }
  /* -------------------------------------------------------------------------- */
  /*               // Growth monitoring Four month to one year                    */
  /* -------------------------------------------------------------------------- */
  getMonitorFourToOneYearList() {
    return this.http.get(this.apiBaseUrl + 'development-milestones/2')
  }
  /* -------------------------------------------------------------------------- */
  /*               // Growth monitoring Three year and above                    */
  /* -------------------------------------------------------------------------- */
  getMonitorThreeYearAndAboveList() {
    return this.http.get(this.apiBaseUrl + 'development-milestones/4')
  }

  /* -------------------------------------------------------------------------- */
  /*               // Growth monitoring zero  to three months                    */
  /* -------------------------------------------------------------------------- */
  getMonitorZeroToThreeMonthsList() {
    return this.http.get(this.apiBaseUrl + 'development-milestones/1')
  }
  /* -------------------------------------------------------------------------- */
  /*               // Growth monitoring Four month to one year                    */
  /* -------------------------------------------------------------------------- */
  getMonitorOneToThreeYearList() {
    return this.http.get(this.apiBaseUrl + 'development-milestones/3')
  }
  /* -------------------------------------------------------------------------- */
  /*               // Nunny plan get all data list function                    */
  /* -------------------------------------------------------------------------- */
  getAllNurseTaskSData() {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails')
  }
  getNurseTaskDataById(id: any) {
    return this.http.get(this.apiBaseUrl + 'nanny-task-details/babyform/' + `${id}`).toPromise();
  }
  getNurseTaskByDate(data: any) {
    return this.http.post(this.apiBaseUrl + 'nanny-task-details', data).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // patient summary api                  */
  /* -------------------------------------------------------------------------- */
  grtNurseSummaryData(id: any) {
    return this.http.get(this.apiBaseUrl + 'nanny-plandetails/baby/' + `${id}`).toPromise();
  }
  /* -------------------------------------------------------------------------- */
  /*               // List of registration                   */
  /* -------------------------------------------------------------------------- */
  getRegistrationData(params?: any) {
    return this.http.get(this.apiBaseUrl + 'users', { params });
  }

  /* -------------------------------------------------------------------------- */
  /*               // List of registration daily care plan by id                  */
  /* -------------------------------------------------------------------------- */
  getDailyCarePlanById(id: any) {
    return this.http.get(this.apiBaseUrl + 'nanny-plan-details/baby/' + `${id}`).toPromise();
  }
  exportPdf(data: any, column: any, name?: any) {
    // console.log(data);

    const doc = new jsPDF({
      orientation: 'landscape',
    });
    doc['autoTable'](column, data);
    if (name) {
      doc.save(name);
    } else doc.save('report.pdf');
  }
  getMonitorList(id: any) {
    return this.http.get(this.apiBaseUrl + `development-milestones/baby/${id}`).toPromise();
  }

  getNurseDataById(id: any) {
    return this.http.get(this.apiBaseUrl + `initial-assessment/nurse/${id}`).toPromise();
  }

  getParentDataById(id: any) {
    return this.http.get(this.apiBaseUrl + `initial-assessment/parent/${id}`).toPromise();
  }
  deletecareplan(id: any) {
    console.log("api called", id);
    return this.http.delete(this.apiBaseUrl + `delete-nanny-plandetails/${id}`);
  }
  deletepatient(id: any) {
    console.log("api called", id);
    return this.http.delete(this.apiBaseUrl + `delete-patient/${id}`);
  }
  deleteUser(Id:any){
    return this.http.delete(this.apiBaseUrl + `users/${Id}`);

  }
  getHastag(){
     return this.http.get(this.apiBaseUrl + `hashtag-moments`).toPromise();
  }
  postbabyimgUsingHastag(data:any){
    return this.http.post(this.apiBaseUrl + 'hashtag-moments-details', data).toPromise();

  }

  getBabyImagesUsingHastag(babayId :any ){
    return this.http.get(this.apiBaseUrl + `hashtag-moments-details/baby/${babayId}`).toPromise();

  }

  getHealthDataByDate(data: any) {
    return this.http.post(this.apiBaseUrl + 'health-monitoring-data', data).toPromise();
  }
}


