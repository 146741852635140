<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Events Details Area -->
<section class="events-details-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="events-details-image">
            <img src="assets/img/events/events-details.jpg" alt="image">

            <div id="timer" class="flex-wrap d-flex justify-content-center">
                <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}} <span>Days</span></div>
                <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}} <span>Hours</span></div>
                <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}} <span>Minutes</span></div>
                <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}} <span>Seconds</span></div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="events-details-header">
                    <ul>
                        <li><i class='bx bx-calendar'></i>Dec 1, 2020 - Dec 31, 2020</li>
                        <li><i class='bx bx-map'></i>Rome, Italy</li>
                        <li><i class='bx bx-time'></i>12:00 AM - 12:00 PM</li>
                    </ul>
                </div>

                <div class="events-details-location">
                    <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d190028.2570988032!2d12.395915345059903!3d41.91024148618828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61b6532013ad%3A0x28f1c82e908503c4!2sColosseum!5e0!3m2!1sen!2sbd!4v1597645466641!5m2!1sen!2sbd"></iframe>
                    </div>
                </div>

                <div class="events-details-desc">
                    <h3>About The Event</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Where the event?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <h3>Who this event is for?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="events-details-info">
                    <ul class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Cost</span>
                                $149
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Total Slot</span>
                                1500
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Booked Slot</span>
                                350
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span>Pay With</span>
                                <div class="payment-method">
                                    <img src="assets/img/payment/img1.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img2.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img3.png" class="shadow" alt="image">
                                    <img src="assets/img/payment/img4.png" class="shadow" alt="image">
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="btn-box">
                        <a routerLink="/single-events" class="default-btn"><i class="flaticon-user"></i>Book Now<span></span></a>
                        <p>You must <a href="#" data-bs-toggle="modal" data-bs-target="#loginRegisterModal">login</a> before register event.</p>
                    </div>

                    <div class="events-share">
                        <div class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>

                            <ul class="social-link">
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Events Details Area -->

<app-footer-style-one></app-footer-style-one>