<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Area -->
<section class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="pricing-tabs">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="monthly-tab" data-bs-toggle="tab" data-bs-target="#monthly" type="button" role="tab" aria-controls="monthly" aria-selected="true">Monthly</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="annually-tab" data-bs-toggle="tab" data-bs-target="#annually" type="button" role="tab" aria-controls="annually" aria-selected="false">Annually</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="monthly" role="tabpanel">
                    <div class="row" *ngFor="let MonthlyPricing of monthlyPricing;">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of MonthlyPricing.singlePricingBox">
                            <div class="single-pricing-box">
                                <h3>{{Content.title}}</h3>
                                <div class="pricing-features">
                                    <ul>
                                        <li *ngFor="let Features of Content.features"><i class='{{Features.icon}}'></i> {{Features.text}}</li>
                                    </ul>
                                </div>
                                <div class="price">
                                    {{Content.price}}
                                </div>
                                <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="annually" role="tabpanel">
                    <div class="row" *ngFor="let YearlyPricing of yearlyPricing;">
                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let Content of YearlyPricing.singlePricingBox">
                            <div class="single-pricing-box">
                                <h3>{{Content.title}}</h3>
                                <div class="pricing-features">
                                    <ul>
                                        <li *ngFor="let Features of Content.features"><i class='{{Features.icon}}'></i> {{Features.text}}</li>
                                    </ul>
                                </div>
                                <div class="price">
                                    {{Content.price}}
                                </div>
                                <a routerLink="/{{Content.buttonLink}}" class="default-btn">{{Content.buttonText}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Area -->

<app-footer-style-one></app-footer-style-one>