<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Gallery Area -->
<section class="gallery-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let GalleryItem of singleGalleryItem;">
                <div class="single-gallery-item">
                    <a class="popup-btn" href="{{GalleryItem.img}}">
                        <img [src]="GalleryItem.img" alt="image">
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Gallery Area -->

<app-footer-style-one></app-footer-style-one>