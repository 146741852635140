import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GrowthMonitorService {

  apiBaseUrl: string = environment.api_base_url;
  constructor(private http: HttpClient) { }
   /* -------------------------------------------------------------------------- */
  /*               // Post growth monitoring form data                         */
  /* -------------------------------------------------------------------------- */

  postGrowthMonitorForm(data?:any){
    return this.http.post(this.apiBaseUrl + 'development-milestones', data).toPromise();
  }
    /* -------------------------------------------------------------------------- */
  /*               // Get growth monitoring form data                         */
  /* -------------------------------------------------------------------------- */
  getGrowthMonitoring() {
    return this.http.get(this.apiBaseUrl + 'growth-monitoring');
  }
  editGrowthMonitorForm(data?:any, editId?:any){
    console.log("dsd",data)
    return this.http.put(this.apiBaseUrl + 'development-milestones/'+`${editId}`, data).toPromise();
  }
  uploadheightweight(data?:any){
        return this.http.post(this.apiBaseUrl + 'web-baby-physical-assessment', data).toPromise();
  }
}
