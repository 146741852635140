<!-- Start Listings Area -->
<section class="listings-area ptb-100 bg-f9f9f9">
    <div class="container">
        <div class="section-title" *ngFor="let Title of sectionTitle;">
            <h2>{{Title.title}}</h2>
            <p>{{Title.paragraph}}</p>
        </div>

        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-6" *ngFor="let Content of singleListingsBox;">
                <div class="single-listings-box">
                    <div class="listings-image">
                        <div class="listings-image-slides">
                            <owl-carousel-o [options]="customOptions">
                                <ng-template carouselSlide *ngFor="let Image of Content.mainImg;">
                                    <div class="single-image">
                                        <img [src]="Image.img" alt="image">
                                        <a routerLink="/{{Content.detailsLink}}" class="link-btn"></a>
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                        <a routerLink="/{{Content.bookmarkLink}}" class="bookmark-save"><i class="flaticon-heart"></i></a>
                        <a routerLink="/{{Content.categoryLink}}" class="category"><i class="flaticon-cooking"></i></a>
                    </div>
                    <div class="listings-content">
                        <div class="author">
                            <div class="d-flex align-items-center">
                                <img [src]="Content.authorImg" alt="image">
                                <span>{{Content.authorName}}</span>
                            </div>
                        </div>
                        <ul class="listings-meta">
                            <li><a routerLink="/{{Content.categoryLink}}"><i class="flaticon-furniture-and-household"></i> Restaurant</a></li>
                            <li><i class="flaticon-pin"></i> {{Content.location}}</li>
                        </ul>
                        <h3><a routerLink="/{{Content.detailsLink}}">{{Content.title}}</a></h3>
                        <span class="status {{Content.extraClass}}"><i class="flaticon-save"></i> {{Content.openORclose}}</span>
                        <div class="d-flex align-items-center justify-content-between">
                            <div class="rating">
                                <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                <span class="count">({{Content.ratingCount}})</span>
                            </div>
                            <div class="price">
                                {{Content.price}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="more-listings-box" *ngFor="let Button of bottomButton;">
                    <a routerLink="/{{Button.link}}" class="default-btn">{{Button.text}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Area -->