import { Component, OnInit, ViewChild } from '@angular/core';
import { MaikaListService } from 'src/app/services/maika-list.service';
import { MasterService } from 'src/app/services/master.service';
import { Router } from '@angular/router';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogModule } from 'primeng/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { Dropdown } from 'primeng/dropdown';
@Component({
  selector: 'app-initial-ass-list',
  templateUrl: './initial-ass-list.component.html',
  styleUrls: ['./initial-ass-list.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class InitialAssListComponent implements OnInit {
  breadcrumb = [
    {
      title: 'Baby Child Registration',
      subTitle: 'Dashboard'
    }
  ]

  @ViewChild('dt1') dataTable: Table;
  @ViewChild('dropdown') dropdown: Dropdown;

  filterVal: string;
  day: number;
  month: number;
  year: number;
  nurseLogin: boolean = false
  nurce_care_link: string = '';
  groth_monitoring_link: string = '';
  headNurse: boolean = false
  superAdmin: boolean = false
  loading: any;
  date: any = new Date('2000-01-04T10:04:04.000Z');
  data: any;
  contract: any = [];
  sibling: any = [];
  relatives: any = [];
  service: any
  nurse: any
  displayMaximizable: boolean = false;
  viewDetail: any = [];
  initialAssData: any = []
  permissionData: any = [];
  permissions: any = [];
  admin: boolean = false
  tooltipText: string = 'Baby Child Registration';
  filterVals: any;
  filterValues: any[];
  dropdownValue
  options: any = [
    { label: 'Active', value: '1' },
    { label: 'Inactive', value: '0' },
  ];
  constructor(private initial_ass_service: MaikaListService, private router: Router, private spinner: NgxSpinnerService, private masterService: MasterService, private messageService: MessageService, private confirmationService: ConfirmationService,) {
    this.getAllUserPermission();
  }

  getDailyCarePlan(id: any) {
    // const babyId = localStorage.getItem('babyId');
    // this.initial_ass_service.getDailyCarePlanById(babyId).then((res: any) => {
    //   let dailyCare = res.data;
    //   // this.datalength = res?.data?.length;
    // })
    // localStorage.setItem("showDTS", id)
    localStorage.setItem('babyId', id);

    // this.router.navigateByUrl('/portal/patient-view');
    // <app-daily-care-plan></app-daily-care-plan>
    this.router.navigateByUrl('/portal/daily-care-plan')

  }
  // fn to get the data from backend

  getDailyTaskSummary(name: any, id: any) {
    localStorage.setItem('baby_name', name);
    localStorage.setItem('babyId', id);

    this.router.navigateByUrl('/portal/daily-task-summary')
  }
  memoriesMoments(name: any, id: any) {
    localStorage.setItem('baby_name', name);
    localStorage.setItem('babyId', id);

    this.router.navigateByUrl('/portal/capture-memories');
    
  }
  getAllData() {
    const person = localStorage.getItem('role');
    const personId = localStorage.getItem('user_id');

    this.spinner.show()
    if (person == 'Nurse') {
      this.initial_ass_service.getNurseDataById(personId).then((res: any) => {
        this.spinner.hide()
        this.initialAssData = res.data;
        this.nurseLogin = true
        this.initialAssData.map((ele: any) => {
        })
      }, (error: any) => {
        this.spinner.hide();
      })
    }
    if (person == 'Patient') {

      this.initial_ass_service.getParentDataById(personId).then((res: any) => {
        this.spinner.hide()

        // 
        this.initialAssData = res.data;
        this.initialAssData.map((ele: any) => {

        })

      }, (error: any) => {
        this.spinner.hide();
      })
    }

    if (person != 'Patient' && person != 'Nurse') {
      if (person == 'Supervisor/Head Nurse') {
        this.headNurse = true
      }
      else {
        if (person == 'Admin / Marketing') {
          this.admin = true
        }
        if (person == 'Super Admin') {
          this.superAdmin = true
        }
      }
      this.initial_ass_service.getInitialAssList().subscribe((res: any) => {
        this.spinner.hide()

        // 
        this.initialAssData = res.data;
        this.initialAssData.map((ele: any) => {
        })

      }, (error: any) => {
        this.spinner.hide();
      })

    }

  }
  calculateAge(dob) {
    if (!dob) {
      return null;
    }
    let date = dob.split("/");
    const today = new Date();

    const birthDate = new Date(date[2], date[1] - 1, date[0]);

    let newdate = new Date(date[1], 5, 2022)

    // return  birthDate.toString();
    // return  `${date[0]}-${date[1]}-${date[2]}`

    const timeDiffMilliseconds = today.getTime() - birthDate.getTime();
    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));

    const years = Math.floor(daysDiff / 365);
    const remainingDaysOfYear = daysDiff % 365;
    const months = Math.floor(remainingDaysOfYear / 30);
    const remainingDaysOfMonth = remainingDaysOfYear % 30;

    let ageString = '';

    if (years > 0) {
      ageString += `${years} year${years > 1 ? 's' : ''} `;
      return ageString.trim();

    }

    if (months > 0) {
      ageString += `${months} month${months > 1 ? 's' : ''} `;
      return ageString.trim();

    }

    if (remainingDaysOfMonth > 0) {
      ageString += `${remainingDaysOfMonth} day${remainingDaysOfMonth > 1 ? 's' : ''} `;
      return ageString.trim();

    }

    if (ageString.trim() === '') {
      ageString = 'Less than a month';
    }

    return ageString.trim();
  }

  ngOnInit(): void {
    this.getAllData();
    localStorage.removeItem('babyId');
    localStorage.removeItem('editedOrderId')
    // localStorage.removeItem('showDTS')
    localStorage.removeItem('create');
    this.data = {
      upload_photo: this.getImageUrl(this.data?.upload_photo ?? '-')
    };

  }
  getImageUrl(filename: string): string {
    const backendUrl = 'https://appbackend.maika.ae/';
    const path = 'images/initial-assessment/';
    // return backendUrl + path + filename;
    const imageUrl = backendUrl + path + filename;
    return imageUrl;

  }
  getdata(id: any) {
    localStorage.setItem('editedOrderId', id);
  }
  growthMonitoringView(id: any, name, dob: any){
    localStorage.setItem('babyId', id);

    localStorage.setItem('baby_name', name);
    localStorage.setItem('dob', dob)
  }
  view(id: any, name, dob: any) {
    // 
    localStorage.setItem('babyId', id);

    localStorage.setItem('baby_name', name);
    localStorage.setItem('dob', dob)
    const role = localStorage.getItem('role');
    if (role == "Nurse") {
      this.router.navigateByUrl('/portal/nurse-task-summary');
    }
    else {
      this.router.navigateByUrl('/portal/patient-view');
    }
  }

  growth(name: any, id: any){
      localStorage.setItem('baby_name', name);
      localStorage.setItem('babyId', id);
  
    this.router.navigateByUrl('/portal/growth-monitoring-capture');
  }

  genratenurseLink(babyid, id) {
    let dob, nurce_care_link;
    let date, month, year;
    this.initialAssData.map((res: any) => {
      if (res.id == id) {
        // date = res.days;
        // month = res.months;
        // year = res.years;
        dob = res.date_of_birth.split('/');
      }
    });


    let today = new Date();
    let dateOfBirth = new Date(dob[2], dob[1] - 1, dob[0]);
    const timeDiffMilliseconds = today.getTime() - dateOfBirth.getTime();
    const daysDiff = Math.floor(timeDiffMilliseconds / (1000 * 3600 * 24));
    if (daysDiff <= 119) {
      nurce_care_link = '/portal/zero-to-three-months';
    } 
    else if (daysDiff <= 365 && daysDiff > 119) {
      nurce_care_link = '/portal/four-months-to-one-year-plan';
    }

    // 1 year to 3 year
    else if (daysDiff <= 1095 && daysDiff > 365) {
      nurce_care_link = '/portal/one-year-to-three-year-plan';
    }

    // up to 3 year 
    else if (daysDiff > 1095) {
      nurce_care_link = '/portal/three-years-above';
    }
    localStorage.setItem('create', babyid);
    this.router.navigateByUrl(nurce_care_link);

  }

  genrateGrothLink(babyid, id) {

    let date, month, year;

    localStorage.setItem('babyId', babyid);

    this.initialAssData.map((res: any) => {
      if (res.id == id) {
        date = res.days;
        month = res.months;
        year = res.years;

      }
    });
    if (month <= 2 && year == 0) {
      this.groth_monitoring_link = '/portal/zero-to-three-month-growth-monitoring';
    }
    else if (month == 3 && year == 0 && date < 1) {
   
      this.groth_monitoring_link = '/portal/zero-to-three-month-growth-monitoring';
    }

    // up to 3 months  to 1 year
    else if (month >= 3 && month < 12 && year == 0) {
      
      
      this.groth_monitoring_link = '/portal/four-to-one-year-growth-monitoring';
    }
    else if (year == 1 && month < 1 && date < 1) {
    
      this.groth_monitoring_link = '/portal/four-to-one-year-growth-monitoring';
    }

    // 1 year to 3 year
    else if (year <= 2 && year > 0) {
      this.groth_monitoring_link = '/portal/one-year-to-three-year-growth-monitoring';
    }
    else if (year == 3 && month < 1 && date < 1) {
      this.groth_monitoring_link = '/portal/one-year-to-three-year-growth-monitoring';
    }

    // up to 3 year 
    else if (year >= 3) {
      this.groth_monitoring_link = '/portal/three-year-and-above-year-growth-monitoring';
    }

    localStorage.setItem('create', babyid);
    this.router.navigateByUrl(this.groth_monitoring_link);

  }
  // permission baby child registration 
  getAllUserPermission() {
    let id = localStorage.getItem('user_id');
    this.masterService.getUserPremissionById(id).then((res: any) => {

      this.permissionData = res.data;

      for (let i = 0; i < this.permissionData.length; i++) {
        
        this.permissions.push(this.permissionData[i].permission_id);
      }
    })
  }

  checkPermission(permission: number) {
   
    return this.permissions.includes(permission);
  }
  // open view dialog
  details: any = [];
  showMaximizableDialog(id: any) {
    this.details = [];
    this.viewDetail = [];

    this.initialAssData.map((res: any) => {
      if (res.id == id) {

        this.viewDetail.push(res);
        // this.relatives.push(JSON.parse(res?.relatives_name_json));
        this.nurse = (JSON.parse(res?.associated_nurse_id));

        this.sibling = JSON.parse(res?.sibling_details_json);

        this.relatives = JSON.parse(res?.relatives_name_json);

        this.contract = res?.contract_json;
        // this.service=(JSON.parse(res?.associated_nurse_id))


      }
      else {
      }
    });

    this.displayMaximizable = true;
  }
  //edit on click function call
  editOnCLick(initial_assessment_id
    : any) {
    localStorage.setItem('editedOrderId', initial_assessment_id
    );
   
    this.router.navigateByUrl('/portal/initial-assessment-form');
  }

  // genrateGrothLinkForNurse(babyid, id) {
  //        let growthId
  //   this.initial_ass_service.getMonitorList(babyid)
  //     .then((res: any) => {
  //       this.spinner.hide();
  //       let allData = res?.data;
  //      
  //       try {
  //         let latestMilestone = null;
  //         let latestTimestamp = 0;
  //         allData[0].development_milestones.forEach(milestone => {
  //           const timestamp = new Date(milestone.created_at).getTime();
  //           if (timestamp > latestTimestamp) {
  //             latestTimestamp = timestamp;
  //             latestMilestone = milestone;
  //           }
  //         });
  //         growthId =latestMilestone.id
  //         localStorage.setItem('editedOrderId', growthId);
  //         this.genrateGrothLink(babyid, id)

  //       } catch (error) {
  //       }

  //     },
  //       (error: any) => {
  //         this.spinner.hide()
  //         // Handle any errors that occurred during the API call
  //       });


  // }
  reset(dt) {
    // this.dropdownValue=' '
    dt?.reset();
    this.dropdown?.clear(this.filterVals);
    this.dataTable?.reset();
    this.filterVal = '';
  }
  // deleteplan(id: any) {

  //   // Ask for confirmation before proceeding
  //   if (confirm("Are you sure you want to delete this plan?")) {

  //     this.spinner.show();

  //     // Directly call the deletecareplan method without confirmation
  //     // this.confirmationService.confirm({
  //     //   message: 'Do you want to delete this record?',
  //     //   header: 'Delete Confirmation',
  //     //   icon: 'pi pi-info-circle',
  //     //   accept: () => {
  //     this.initial_ass_service
  //       .deletepatient(id)
  //       .subscribe(
  //         (data: any) => {
  //           this.spinner.hide();
  //           this.messageService.add({
  //             severity: 'success',
  //             detail: data?.message,
  //           });
  //         },
  //         (error: any) => {
  //           this.spinner.hide();
  //         }
  //       );
  //     // }
  //   }
  //   this.softReload();
  // }
  // deleteplan(id: any) {
  //   this.confirmationService.confirm({
  //     target: event.target as EventTarget,
  //     message: 'Do you want to delete this record?',
  //     header: 'Delete Confirmation',
  //     icon: 'pi pi-info-circle',
  //     acceptButtonStyleClass: "p-button-danger p-button-text",
  //     rejectButtonStyleClass: "p-button-text p-button-text",
  //     acceptIcon: "none",
  //     rejectIcon: "none",
  //     accept: () => {
  //       this.initial_ass_service.deletepatient(id).subscribe(
  //         () => {
  //           this.messageService.add({ severity: 'success', summary: 'Success' });
  //         },
  //         error => {
  //         }
  //       );
  //       this.softReload();
  //     },
  //     reject: () => {
  //       this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
  //     }
  //   });
  // }

  deleteplan(id: any) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text p-button-text",
      acceptIcon: "none",
      rejectIcon: "none",
      accept: () => {
        this.initial_ass_service.deletepatient(id).subscribe(
          () => {
            this.messageService.add({ severity: 'success', summary: 'Success' });
            this.softReload(); // Reload after successful deletion
          },
          error => {
          }
        );
      },
      reject: () => {
        this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
      }
    });
  }
  softReload() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  applyFilter(event:any) {
   
    this.dataTable.reset();
    this.filterVals=event

    if (this.initialAssData && this.initialAssData.length > 0) {
      // Filter the data based on the filter value
      const filteredData = this.initialAssData.filter(item => {
        return item.is_active == this.filterVals;
      });
      // Set the filtered data to the datatable
      this.dataTable.filteredValue = filteredData;
    }
    this.filterValues = [];
  }
}
